<script>
export default {
  props: {
    position: {
      type: String,
      default: 'is-left',
    },
    size: {
      type: String,
      default: 'is-small',
    },
    planLock: {
      type: String,
      default: null,
    },
  },
  computed: {
    canSee() {
      if (this.planLock === 'EXPERT') {
        return this.$store.getters['auth/hasExpertPlan'];
      }

      if (this.planLock === 'PRO') {
        return this.$store.getters['auth/hasProPlan'];
      }

      return this.$store.getters['auth/isNotPercentPlan'];
    },
  },
};
</script>

<template>
  <b-tooltip v-if="canSee" :position="position" multilined>
    <template #content>
      Cette fonctionnalité fait partie de votre
      <span v-t="`packs.${$store.getters['auth/store'].plan}`" />
    </template>
    <b-icon icon="gem" :size="size" />
  </b-tooltip>
</template>
