import Vue from 'vue';
import { NotificationProgrammatic } from 'buefy';
import router from '@app/config/router';
import store from '@app/config/store';

router.beforeEach((to, from, next) => {
  store.dispatch('auth/ready')
    .then(() => {
      const guard = to.meta.checkAuth;
      const isLogged = Vue.auth.check();
      const passedCustomCheck = (guard) => {
        const user = Vue.auth.user();

        if (guard && guard.is_admin && user && user.is_admin) {
          return true;
        }

        return false;
      };

      // console.log(guard, isLogged);

      if (guard == null) {
        next();
      } else if (guard === true && !isLogged) {
        next({ name: 'login', query: { redirect: to.fullPath } });
      } else if (guard === false && isLogged) {
        next({ name: 'home' });
      } else if (typeof guard !== 'boolean' && !Vue.auth.check(guard) && !passedCustomCheck(guard)) {
        NotificationProgrammatic.open({
          message: 'Vous n\'avez pas accès à cette page !',
          type: 'is-warning',
        });
        next({ name: isLogged ? 'home' : 'login' });
      } else {
        next();
      }
    });
});
