import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

if (process.env.NODE_ENV === 'staging') {
  Sentry.init({
    dsn: 'https://e48df716411a4816bda9295f8d4ac18a@o387844.ingest.sentry.io/5514824',
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://38966cccfce34e71a0c354d1aab7053c@o387844.ingest.sentry.io/5534443',
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
