import { api } from '@shared/config/axios';

export default {
  getList(params) {
    return api.$get('/community/groups', { params });
  },
  add(data) {
    return api.$post('/community/groups', data);
  },
  reorder(data) {
    return api.$put('/community/groups', data);
  },
  delete(uuid) {
    return api.$delete(`/community/groups/${uuid}`);
  },
  get(uuid) {
    return api.$get(`/community/groups/${uuid}`);
  },
  update(uuid, data) {
    return api.$put(`/community/groups/${uuid}`, data);
  },
  getPosts(uuidOrURL, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL, { params });
    }

    return api.$get(`/community/groups/${uuidOrURL}/posts`, { params });
  },
  addPost(uuid, data) {
    return api.$post(`/community/groups/${uuid}/posts`, data);
  },
  deletePost(uuid) {
    return api.$delete(`/community/posts/${uuid}`);
  },
  getPost(uuid) {
    return api.$get(`/community/posts/${uuid}`);
  },
  updatePost(uuid, data) {
    return api.$put(`/community/posts/${uuid}`, data);
  },
  reportPost(uuid, data) {
    return api.$post(`/community/posts/${uuid}/abuses`, data);
  },
  subscribePost(uuid) {
    return api.$post(`/community/posts/${uuid}/subscribe`);
  },
  unsubscribePost(uuid) {
    return api.$delete(`/community/posts/${uuid}/unsubscribe`);
  },

  getComments(uuidOrURL, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL, { params });
    }

    return api.$get(`/community/posts/${uuidOrURL}/comments`, { params });
  },
  addComment(uuid, data) {
    return api.$post(`/community/posts/${uuid}/comments`, data);
  },
  deleteComment(uuid) {
    return api.$delete(`/community/comments/${uuid}`);
  },
  getComment(uuid) {
    return api.$get(`/community/comments/${uuid}`);
  },
  updateComment(uuid, data) {
    return api.$put(`/community/comments/${uuid}`, data);
  },
  reportComment(uuid, data) {
    return api.$post(`/community/comments/${uuid}/abuses`, data);
  },
  subscribeComment(uuid) {
    return api.$post(`/community/comments/${uuid}/subscribe`);
  },
  unsubscribeComment(uuid) {
    return api.$delete(`/community/comments/${uuid}/unsubscribe`);
  },

  getMembers(uuidOrURL, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL, { params });
    }

    return api.$get(`/community/groups/${uuidOrURL}/members`, { params });
  },
  getAvailableMembers(uuidOrURL, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL, { params });
    }

    return api.$get(`/community/groups/${uuidOrURL}/members/available`, {
      params,
    });
  },
  addMember(uuid, data) {
    return api.$post(`/community/groups/${uuid}/members`, data);
  },
  deleteMember(uuid) {
    return api.$delete(`/community/members/${uuid}`);
  },
  deleteMembers(uuid) {
    return api.$delete(`/community/groups/${uuid}/members`);
  },
  importMembers(uuid, data) {
    return api.$post(`/community/groups/${uuid}/members/all`, data);
  },
  // getMember(uuid) {
  //   return api.$get(`/community/members/${uuid}`);
  // },
  updateMember(uuid, data) {
    return api.$put(`/community/members/${uuid}`, data);
  },
};
