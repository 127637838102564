import { api } from '@shared/config/axios';

export default {
  getList() {
    return api.$get('/community/collections');
  },
  add(data) {
    return api.$post('/community/collections', data);
  },
  reorder(data) {
    return api.$put('/community/collections', data);
  },
  delete(uuid) {
    return api.$delete(`/community/collections/${uuid}`);
  },
  update(uuid, data) {
    return api.$put(`/community/collections/${uuid}`, data);
  },
};
