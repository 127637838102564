import APIAffiliation from '@app/services/API/Affiliation';

export default {
  created() {
    this.$router.onReady(() => {
      const code = this.$route.query.parrain;
      code && APIAffiliation.incrementVisit({ code })
        .catch(() => {});
    });
  },
};
