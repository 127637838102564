import isString from 'lodash/isString';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { helpers } from 'vuelidate/lib/validators';
import { sprintf } from '@shared/services/Utils';
import ErrorMessages from '@shared/messages/errors.json';

Vue.use(Vuelidate);
Vue.mixin({
  methods: {
    dataIsValid(isLoading, $vuelidateModel = null) {
      if (isLoading === true) {
        return false;
      }

      const $v = $vuelidateModel || this.$v;

      if (!$v) {
        return true;
      }

      $v.$touch();
      return !$v.$error && !$v.$pending;
    },
  },
});

export const phone = helpers.regex('phone', /^(0|\+)\d{9,13}$/);
export const domain = helpers.regex('domain', /^[a-z0-9-_.]+$/i);
export const alphaNumDash = helpers.regex('alphaNumDash', /^[a-z0-9-_]+$/i);
export const timecode = helpers.regex('timecode', /^[0-9]{2,3}:[0-5][0-9]$/);
export const pattern = (param) => helpers.regex('pattern', param);
export const isValue = (param) => (val) => (!helpers.req(val) || val === param);
export const inArray = (list) => (val) => (!helpers.req(val) || list.includes(val));
export const isUnique = (promiseCallback) => (val) => (!helpers.req(val) || promiseCallback(val));
export const notInTempEmails = (list) => (val) => (
  !helpers.req(val)
  || !list.some((one) => val.endsWith(`@${one}`))
);

export const getErrorMessages = (field, rules) => {
  if (!field || !field.$error) {
    return [];
  }

  return rules.filter((rule) => !field[rule.name || rule])
    .map((rule) => {
      if (isString(rule)) {
        return ErrorMessages[rule];
      }

      if (rule.message) {
        return rule.message;
      }

      return sprintf(ErrorMessages[rule.name || rule], rule.args || []);
    });
};

Vue.prototype.$getErrorMessages = getErrorMessages;
Vue.prototype.$getErrorProps = function(field, rules = []) {
  if (!field || !field.$error) {
    return {};
  }

  const props = { type: { 'is-danger': true } };
  props.message = getErrorMessages(field, rules);

  return props;
};
