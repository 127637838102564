<script>
import { mapState } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';

export default {
  props: {
    collection: {
      type: Object,
      default: null,
    },
  },
  form: {
    name: {
      label: 'Nom de la collection',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        required: true,
        maxlength: 100,
        hasCounter: false,
      },
    },
    description: {
      label: 'Description de la collection',
      type: 'editor',
      column: 'is-12',
      inputAttrs: {
        placeholder: 'Décrivez ici l\'objectif et les caractéristiques de cette collection',
      },
      errors: [
        { name: 'maxLength', args: [5000] },
      ],
    },
  },
  data() {
    return {
      newCollection: this.collection ? { ...this.collection } : {
        name: '',
        description: '',
      },
    };
  },
  computed: {
    ...mapState('community', ['collectionIsLoading']),
  },
  validations: {
    newCollection: {
      description: {
        maxLength: maxLength(5000),
      },
    },
  },
  methods: {
    handle() {
      let promise;

      if (this.collection) {
        promise = this.$store.dispatch('community/updateCollection', {
          uuid: this.collection.uuid,
          data: { ...this.newCollection },
        });
      } else {
        promise = this.$store.dispatch('community/addCollection', {
          ...this.newCollection,
        });
      }

      promise.then(() => {
        this.$emit('done');
        this.$showMessage.success();
      });
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(collectionIsLoading) && handle()"
  >
    <template #title>
      <template v-if="collection">
        Modifier la collection
      </template>
      <template v-else>
        Créer une collection
      </template>
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in $options.form"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newCollection"
            :formValidation="$v.newCollection"
          />
        </div>
      </div>
      <div class="buttons is-right">
        <b-button type="is-text" @click="$emit('cancel')">
          Annuler
        </b-button>
        <b-button
          type="is-primary is-custom"
          native-type="submit"
          :loading="collectionIsLoading"
        >
          <template v-if="collection">
            Modifier
          </template>
          <template v-else>
            Ajouter
          </template>
        </b-button>
      </div>
    </template>
  </AppCardModal>
</template>
