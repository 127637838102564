import { api } from '@shared/config/axios';

export default {
  getUsers() {
    return api.$get('/users');
  },
  updateUser(uuid, data) {
    return api.$put(`/users/${uuid}`, data);
  },
  switchUser(uuid, data) {
    return api.$put(`/users/${uuid}/switch`, data);
  },
  deleteUser(uuid, data = {}) {
    data._method = 'DELETE';
    return api.$post(`/users/${uuid}`, data);
  },
  getInvites() {
    return api.$get('/users/invitations');
  },
  invite(data) {
    return api.$post('/users/invitations', data);
  },
  resendInvite(inviteId) {
    return api.$post(`/users/invitations/${inviteId}/resend`);
  },
  deleteInvite(inviteId) {
    return api.$delete(`/users/invitations/${inviteId}`);
  },
  updateInvite(inviteId, data) {
    return api.$put(`/users/invitations/${inviteId}`, data);
  },
  update(data) {
    return api.$put('/users/me', data);
  },
  delete(data) {
    data._method = 'DELETE';
    return api.$post('/users/me', data);
  },
  updatePassword(data) {
    return api.$put('/users/me/password', data);
  },
  updateLastLogin() {
    return api.$put('/users/me/last_login');
  },
  askNewPassword(email) {
    return api.$put('/passwords/reset', { email });
  },
  resetPassword(data) {
    return api.$put('/passwords/reset', data);
  },
  sendVerificationEmail(email) {
    return api.$post('/emails/resend', { email });
  },
  verifyEmail({ email, t }) {
    return api.$get(`/emails/verification?email=${email}&token=${t}`);
  },
  getNotifications(page = 1) {
    return api.$get(`/users/me/notifications?page=${page}`);
  },
  updateNotification(notifId, data) {
    return api.$put(`/users/me/notifications/${notifId}`, data);
  },
  clearNotifications() {
    return api.$put('/users/me/notifications/read');
  },
  generateAPIKey() {
    return api.$post('/users/me/keys');
  },
  deleteAPIKey() {
    return api.$delete('/users/me/keys');
  },
  getSupport() {
    return api.$get('/users/me/support');
  },
  getGodchildren() {
    return api.$get('/users/me/godchildren');
  },
  getGodchildrenCommissions({
    page, begin, end, per_page,
  } = {}) {
    return api.$get('/users/me/godchildren/commissions', {
      params: {
        page, begin, end, per_page,
      },
    });
  },
  getGodchildrenCommissionsStats({ begin, end } = {}) {
    return api.$get('/users/me/godchildren/commissions/stats', { params: { begin, end } });
  },
  getDataFromLink(link) {
    return api.$get(link);
  },
};
