<script>
import RenderlessCountDown from '@shared/components/RenderlessCountDown';

export default {
  components: { RenderlessCountDown },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    timeLeft() {
      const discountEndDate = this.$moment.utc(this.authUser.created_at)
        .local()
        .add(7, 'days')
        .format('DD/MM/YY [à] HH[h]mm');

      return discountEndDate;
    },
  },
};
</script>

<template>
  <div
    class="
      box has-background-warning is-size-7
      is-flex is-align-items-center is-justify-content-space-between
    ">
    <span class="mr-3">
      Obtenez une <strong class="mx-1">réduction de -{{ $constants.DISCOUNT_LEVELUP }}%</strong>
      en souscrivant au <strong class="mx-1">pack PRO ou EXPERT</strong>
      avec le code <strong>JEMELANCE</strong> avant le {{ timeLeft }}.
      <br>
      <RenderlessCountDown
        :dueDate="authUser.created_at"
        :granularity="['days', 'hours', 'minutes']"
      >
      <template #default="{ isPassed, days, hours, minutes, seconds }">
        <span v-if="!isPassed" class="is-size-7">
          La PROMO se termine dans
          <strong>{{ days }} {{ hours }} {{ minutes }} {{ seconds }}</strong>
          </span>
        </template>
      </RenderlessCountDown>
    </span>
    <b-button
      class="is-justify-content-flex-end"
      tag="router-link"
      type="is-white"
      icon-right="gem"
      :to="{name: 'plan'}"
    >
      Mettre à jour
    </b-button>
  </div>
</template>
