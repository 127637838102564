<script>
import trainingsMixin from '@app/mixins/trainings';
import APIStore from '@app/services/API/Store';

export default {
  mixins: [trainingsMixin],
  messages: {
    title: {
      SUBDOMAIN: 'Définissez l\'URL de votre espace',
      CUSTOMIZE: 'Personnalisez votre espace',
      FIRST_TRAINING: 'Créez votre première formation',
      COMPANY: 'Ajoutez vos informations d\'entreprise',
      STRIPE: 'Configurez votre système de paiement',
    },
    cta: {
      SUBDOMAIN: 'Personnaliser',
      CUSTOMIZE: 'Personnaliser',
      FIRST_TRAINING: 'Ajouter',
      COMPANY: 'Ajouter',
      STRIPE: 'Configurer',
    },
    text: {
      SUBDOMAIN: `
        Renforcez votre marque avec un sous-domaine personnalisé
      `,
      CUSTOMIZE: `
        Modifiez l'apparence et la présentation de votre espace pour créer VOTRE identité
      `,
      FIRST_TRAINING: `
        Créez une première formation pour commencer votre aventure avec Teachizy
      `,
      COMPANY: `
        Ajoutez des renseignements d'ordre juridique à l'attention de vos clients
      `,
      STRIPE: `
        Recevez l'argent de vos ventes en activant et en configurant un compte Stripe
      `,
    },
  },
  data: () => ({ initialLoading: false, milestones: null, openedIndexes: [] }),
  computed: {
    milestonesCompleted() {
      return this.milestones
        .filter((one) => one.completed)
        .length;
    },
  },
  created() {
    this.initialLoading = true;
    APIStore.getOnboarding(this.$store.getters['auth/store'].uuid)
      .then((data) => (this.milestones = data.data))
      .finally(() => (this.initialLoading = false));
  },
  methods: {
    callAction(action) {
      switch (action) {
        case 'SUBDOMAIN':
          this.$router.push({ name: 'settings' });
          break;
        case 'COMPANY':
          this.$router.push({ name: 'settings_company' });
          break;
        case 'STRIPE':
          this.$router.push({ name: 'settings_payments' });
          break;
        case 'CUSTOMIZE':
          this.$router.push({ name: 'customization' });
          break;
        case 'FIRST_TRAINING':
          this.addTraining();
          break;
        default:
      }
    },
    toggleAllIndexes() {
      return this.openedIndexes.length === this.milestones.length
        ? this.openedIndexes = []
        : this.openedIndexes = this.milestones.map((_, index) => index);
    },
    toggleIndex(value) {
      if (this.openedIndexes.includes(value)) {
        this.openedIndexes = this.openedIndexes.filter((one) => one !== value);
      } else {
        this.openedIndexes.push(value);
      }
    },
  },
};
</script>

<template>
  <section class="uobcomp">
    <b-skeleton v-if="initialLoading" height="500" />
    <template v-else-if="milestones">
      <p v-if="milestonesCompleted === 0" class="mb-3 has-text-weight-bold has-text-centered">
        Configurez votre espace en {{ milestones.length }} étapes simples
      </p>
      <p v-else-if="milestonesCompleted < milestones.length" class="mb-3 has-text-weight-bold has-text-centered">
        Vous avez effectué {{ milestonesCompleted }} / {{ milestones.length }} étapes
      </p>
      <p v-else class="mb-3 has-text-success has-text-weight-bold has-text-centered">
        Bravo ! Vous avez validé toutes les étapes
      </p>

      <b-progress v-if="milestonesCompleted < milestones.length" type="is-primary" :value="milestonesCompleted"
        :max="milestones.length" />

      <div class="box p-0">
        <div @click="toggleAllIndexes"
          class="header is-flex is-justify-content-space-between is-align-items-center m-0 px-4 py-3 bbottom-grey">
          <h2 class="is-size-6 m-0 has-text-weight-semibold">
            Pour commencer
          </h2>

          <b-tooltip position="is-left" label="Tout cacher / afficher">
            <b-icon class="is-size-4" :icon="openedIndexes.length > 0 ? 'toggle-on' : 'toggle-off'" />
          </b-tooltip>
        </div>
        <article v-for="(one, index) in milestones" :key="one.key" class="step" @click="toggleIndex(index)">
          <div class="content m-0">
            <div class="step-title is-flex is-justify-content-space-between my-2 mx-3">
              <div class="is-flex is-align-items-center">
                <figure class="media-left m-0">
                  <b-icon :class="one.completed ? 'has-text-primary' : 'has-text-grey'" pack="far" icon="check-circle"
                    size="is-medium" />
                </figure>

                <h3 class="is-size-6 m-0 ml-2 cursor-pointer">
                  {{ index + 1 }}) {{ $options.messages.title[one.key] }}
                </h3>
              </div>

              <b-button v-if="openedIndexes.includes(index)" class="desktop-step-action " type="is-flat"
                :class="{ 'is-primary': !one.completed }" @click.stop="callAction(one.key)">
                {{ $options.messages.cta[one.key] }}
              </b-button>
            </div>

            <div v-show="openedIndexes.includes(index)" class="step-description">
              <p>{{ $options.messages.text[one.key] }}</p>

              <b-button class="mobile-step-action" type="is-flat" :class="{ 'is-primary': !one.completed }"
                @click.stop="callAction(one.key)">
                {{ $options.messages.cta[one.key] }}
              </b-button>
            </div>

          </div>
        </article>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.header {
  cursor: pointer;
}

.step {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 229, 226, 0.5);
  cursor: pointer;
}

.step-title {
  height: 40px;
}

.content {
  width: 100%;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.step-description {
  padding: 16px;
  animation: appear 100ms linear normal;
}

.desktop-step-action {
  animation: appear 100ms linear normal;

  display: block;

  @include mobile {
    display: none;
  }
}

.mobile-step-action {
  display: none;

  @include mobile {
    display: block;
  }
}

.uobcomp {
  @include mobile {
    // padding-right: pxToRem(20) !important;

    .media {
      flex-wrap: wrap;
    }

    .media-left {
      margin-left: pxToRem(-8px);
    }

    .media-right {
      width: 100%;
      margin: pxToRem(20px) 0 0;
    }
  }
}
</style>
