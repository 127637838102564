<script>
export default {
  form: {
    name: {
      label: 'Titre',
      type: 'text',
      column: 'is-12 pb-0',
      inputAttrs: {
        required: true,
        maxlength: 128,
      },
    },
    summary: {
      label: 'Description courte',
      type: 'textarea',
      column: 'is-12 pt-0',
      inputAttrs: {
        maxlength: 200,
      },
    },
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      training: {
        name: this.name,
        summary: this.summary,
      },
    };
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('trainings/add', { ...this.training, price: 0 })
        .then((data) => {
          this.$router.push({ name: 'training', params: { uuid: data.uuid } });
          this.$emit('close');
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <form class="modal-card" @submit.prevent="dataIsValid(isLoading) && handle()">
    <header class="modal-card-head">
      <h2 class="modal-card-title">
        Ajouter une formation
      </h2>
      <button
        type="button"
        class="modal-close is-large"
        aria-label="Fermer"
        @click="$emit('close')"
      />
    </header>
    <div class="modal-card-body">
      <div class="columns is-multiline">
        <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
          <BaseField v-model="training[k]" :field="field" :autofocus="k == 'name'" />
        </div>
      </div>
    </div>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <div class="buttons">
        <b-button type="is-text" @click="$emit('close')">
          Fermer
        </b-button>
        <b-button type="is-primary" native-type="submit" :loading="isLoading">
          Ajouter
        </b-button>
      </div>
    </footer>
  </form>
</template>
