<script>
import { ModalProgrammatic as Modal } from 'buefy';
import StoreCommunityForm from '@app/components/StoreCommunityForm.vue';

const hasToEnabledCommunity = (parent) => (
  parent.$store.getters['auth/isNotRecurringPlan']
  || !parent.$store.getters['auth/store'].community_options.enabled
);

export default {
  props: {
    onNavigate: {
      type: Function,
      default: () => (() => {}),
    },
  },
  hideFor(roles, parent) {
    return !roles.includes(parent.authUser.role);
  },
  onCommunityClick(event, props, parent) {
    props.onNavigate();

    if (hasToEnabledCommunity(parent)) {
      event.preventDefault();
      const modal = Modal.open({
        parent,
        hasModalCard: true,
        canCancel: false,
        events: {
          done: () => {
            modal.close();

            if (
              parent.$store.getters['auth/store'].community_options.enabled
            ) {
              parent.$router.push({ name: 'community_onboarding' });
            } else {
              parent.$router.push({ name: 'home' });
            }
          },
          cancel: () => modal.close(),
        },
        component: StoreCommunityForm,
      });
    }
  },
  getCommunityItemEvent(parent) {
    return hasToEnabledCommunity(parent) ? '' : 'click';
  },
};
</script>

<template functional>
  <div>
    <b-menu class="is-small">
      <b-menu-list>
        <b-menu-item
          icon="home"
          tag="router-link"
          :to="{name: 'home'}"
          exact-active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Accueil
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="!parent.$store.getters['auth/enterpriseCapabilities'].hide_copywriter"
          icon="pen-nib"
          tag="router-link"
          :to="{name: 'ai_assistant'}"
          active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Copywriter IA
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="!parent.$store.getters['auth/enterpriseCapabilities'].hide_community"
          icon="users"
          tag="router-link"
          :to="{name: 'community'}"
          exact-active-class="is-active"
          :event="$options.getCommunityItemEvent(parent)"
          @click.native="$options.onCommunityClick($event, props, parent)"
        >
          <template #label>
            Communauté
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="$options.hideFor(['TEACHER'], parent)"
          icon="chart-line"
          tag="router-link"
          :to="{name: 'statistics'}"
          exact-active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Statistiques
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="$options.hideFor(['TEACHER'], parent)"
          icon="user-graduate"
          tag="router-link"
          :to="{name: 'customers'}"
          active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Apprenants
          </template>
        </b-menu-item>
        <b-menu-item
          icon="chalkboard-teacher"
          tag="router-link"
          :to="{name: 'trainings'}"
          active-class=""
          label="Formations"
          :expanded="parent.$route.name && parent.$route.name.startsWith('training')"
          @click.native="props.onNavigate()"
        >
          <b-menu-item
            tag="router-link" :to="{name: 'trainings'}"
            :active="parent.$route.name.startsWith('training_') || parent.$route.name.endsWith('training')"
            exact-active-class="is-active" label="Gérer les formations"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            v-if="$options.hideFor(['TEACHER', 'ANALYST'], parent)"
            tag="router-link" :to="{name: 'trainings_certificates'}"
            exact-active-class="is-active" label="Certificat de réussite"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            v-if="$options.hideFor(['TEACHER', 'ANALYST'], parent)"
            tag="router-link" :to="{name: 'trainings_settings'}"
            exact-active-class="is-active" label="Paramètres"
            @click.native="props.onNavigate()"
          />
        </b-menu-item>
        <b-menu-item
          v-if="
            !parent.$store.getters['auth/enterpriseCapabilities'].hide_sales
            && $options.hideFor(['TEACHER'], parent)
          "
          icon="layer-group"
          tag="router-link"
          :to="{name: 'bundles'}"
          :active="parent.$route.name.startsWith('bundle_') || parent.$route.name.endsWith('bundle')"
          exact-active-class="is-active"
          :expanded="parent.$route.name && parent.$route.name.startsWith('bundles')"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Packs
            <span class="tag is-primary is-light is-size-9 ml-5">
              Nouveau
            </span>
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="
            !parent.$store.getters['auth/enterpriseCapabilities'].hide_sales
            && $options.hideFor(['TEACHER'], parent)
          "
          icon="wallet"
          tag="router-link"
          :to="{name: 'sales'}"
          active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Ventes
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="
            !parent.$store.getters['auth/enterpriseCapabilities'].hide_discounts
            && $options.hideFor(['TEACHER'], parent)
          "
          icon="percentage"
          tag="router-link"
          :to="{name: 'discounts'}"
          exact-active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Codes promo
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="$options.hideFor(['TEACHER', 'ANALYST'], parent)"
          icon="bullhorn"
          tag="router-link"
          :to="{name: 'marketing'}"
          exact-active-class=""
          label="Outils Marketing"
          :expanded="parent.$route.name && parent.$route.name.startsWith('marketing')"
          @click.native="props.onNavigate()"
        >
          <b-menu-item
            tag="router-link" :to="{name: 'marketing'}"
            exact-active-class="is-active" label="Suivi / Tracking"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'marketing_automations'}"
            exact-active-class="is-active" label="Autorépondeurs"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'marketing_automation_tools'}"
            exact-active-class="is-active" label="Automatisations (Zapier...)"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'marketing_emails'}"
            active-class="is-active" label="Emails"
            @click.native="props.onNavigate()"
          />
        </b-menu-item>
        <b-menu-item
          v-if="$options.hideFor(['TEACHER', 'ANALYST'], parent)"
          icon="palette"
          tag="router-link"
          :to="{name: 'customization'}"
          active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Personnalisation
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="
            !parent.$store.getters['auth/enterpriseCapabilities'].hide_affiliation
            && $options.hideFor(['TEACHER', 'ANALYST'], parent)
          "
          icon="money-bill"
          tag="router-link"
          :to="{name: 'affiliates'}"
          active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Affiliation
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="$options.hideFor(['TEACHER', 'ANALYST'], parent)"
          icon="code"
          tag="router-link"
          :to="{name: 'embeds'}"
          exact-active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Intégrations externes
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="
            !parent.$store.getters['auth/enterpriseCapabilities'].hide_plan
            && $options.hideFor(['TEACHER', 'ANALYST'], parent)
          "
          icon="gem"
          tag="router-link"
          :to="{
            name: 'plan',
            query: parent.$store.getters['auth/isNotPercentPlan'] ? {onglet: 'vous' } : {}
          }"
          active-class="is-active"
          @click.native="props.onNavigate()"
        >
          <template #label>
            Abonnement
          </template>
        </b-menu-item>
        <b-menu-item
          v-if="$options.hideFor(['TEACHER', 'ANALYST'], parent)"
          icon="cog"
          tag="router-link"
          :to="{name: 'settings'}"
          exact-active-class=""
          label="Paramètres"
          :expanded="parent.$route.name && parent.$route.name.startsWith('settings')"
          @click.native="props.onNavigate()"
        >
          <b-menu-item
            tag="router-link" :to="{name: 'settings'}"
            exact-active-class="is-active" label="Général"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'settings_team'}"
            exact-active-class="is-active" label="Équipe"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'settings_payments'}"
            active-class="is-active" label="Paiements"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'settings_company'}"
            active-class="is-active" label="Entreprise"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'settings_seo'}"
            exact-active-class="is-active" label="Référencement SEO"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            v-if="parent.$store.getters['auth/hasExpertPlan']"
            tag="router-link" :to="{name: 'settings_sso'}"
            exact-active-class="is-active" label="Configuration SSO"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'settings_communication'}"
            active-class="is-active" label="Liens légaux et RGPD"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'settings_intergrations'}"
            active-class="is-active" label="APIs développeur"
            @click.native="props.onNavigate()"
          />
<!--          <b-menu-item-->
<!--            tag="router-link" :to="{name: 'settings_billing'}"-->
<!--            active-class="is-active" label="Facturation"-->
<!--            @click.native="props.onNavigate()"-->
<!--          />-->
        </b-menu-item>
      </b-menu-list>
      <b-menu-list v-if="parent.authUser && parent.authUser.is_admin">
        <b-menu-item
          icon="user-shield"
          tag="router-link"
          :to="{name: 'admin'}"
          exact-active-class=""
          label="Admin"
          :expanded="parent.$route.name && parent.$route.name.startsWith('admin')"
          @click.native="props.onNavigate()"
        >
          <b-menu-item
            tag="router-link" :to="{name: 'admin'}"
            exact-active-class="is-active" label="Indicateurs"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'admin_tops'}"
            exact-active-class="is-active" label="Tops"
            @click.native="props.onNavigate()"
          />
          <b-menu-item
            tag="router-link" :to="{name: 'admin_operations'}"
            exact-active-class="is-active" label="Opérations diverses"
            @click.native="props.onNavigate()"
          />
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>
