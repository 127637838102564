import Vue from 'vue';

const dataLayer = window.dataLayer = window.dataLayer || [];
const Analytics = {
  ready: false,
  code: null,
  load(code, { router }) {
    if (this.ready) {
      return Promise.resolve();
    }

    window.gtag = this.gtag;

    this.code = code;
    this.gtag('js', new Date());
    this.gtag('config', code, { send_page_view: false });
    this.gtag('set', {
      cookie_flags: 'SameSite=None;Secure',
    });

    router.afterEach((to) => {
      const skip = to.matched.some((one) => {
        const { analytics } = one.components.default;
        return !!(analytics && analytics.skip);
      });

      !skip && this.$gtag.pageView();
    });

    return new Promise((resolve) => {
      const d = document;
      const s = d.createElement('script');
      s.onload = () => {
        this.ready = true;
        resolve();
      };
      s.onerror = () => {};
      s.async = true;
      s.src = `https://www.googletagmanager.com/gtag/js?id=${code}`;
      d.body.appendChild(s);
    });
  },
  gtag() {
    if (!this.code || process.env.NODE_ENV !== 'production') {
      console.log('GTAG', arguments); // eslint-disable-line
      return;
    }

    dataLayer.push(arguments); // eslint-disable-line
  },
  $gtag: {
    config(...args) {
      Analytics.gtag('config', ...args);
    },
    pageView({ title: page_title, location: page_location, path: page_path } = {}) {
      Analytics.gtag('event', 'page_view', {
        page_title,
        page_location,
        page_path,
        send_to: Analytics.code,
      });
    },
    purchase({
      affiliation, value, currency = 'EUR', tax, items,
    }) {
      Analytics.gtag('event', 'purchase', {
        affiliation, value, currency, tax, items,
      });
    },
    addToCart({ items }) {
      Analytics.gtag('event', 'add_to_cart', { items });
    },
    beginCheckout({ items }) {
      Analytics.gtag('event', 'begin_checkout', { items });
    },
    checkoutProgress({ coupon, items }) {
      Analytics.gtag('event', 'checkout_progress', { coupon, items });
    },
    event({
      category: event_category, action, label: event_label, value,
    } = {}) {
      Analytics.gtag('event', action, {
        event_category,
        event_label,
        value,
      });
    },
    nativeEvent(name, data = {}) {
      Analytics.gtag('event', name, data);
    },
  },
};

Vue.prototype.$gtag = Analytics.$gtag;

export default Analytics;
