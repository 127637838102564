<script>
import utilsMixin from '@shared/mixins/utils';
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [utilsMixin, storeForms],
  data() {
    return {
      store: {
        community_options: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    onSuccess() {
      this.$emit('done');
    },
    onSave() {
      if (!this.store.community_options.enabled) {
        this.showAsyncConfirm({
          message: `
            En désactivant la communauté, plus personne
            n'y aura accès.
          `,
          onCancel: () => (this.store.community_options.enabled = true),
        }, ({ done }) => (
          this.handle(this.onSuccess)
            .then(() => done())
        ), {
          showSuccess: false,
        });
        return;
      }

      this.handle(this.onSuccess);
    },
  },
};
</script>

<template>
  <AppCardModal @close="$emit('cancel')" hasCloseBtn>
    <template #title>
      Créez du lien grâce à la communauté !
    </template>
    <template #body>
      <div class="content">
        <p>
          Activez votre communauté pour ouvrir des espaces
          <strong>d'interactions avec vos apprenants et entre vos apprenants</strong>.
        </p>
        <p>
          Offrez des prestations encore plus complètes en permettant
          à vos apprenants d'accèder à ces espaces d'échanges privilégiés :
          accompagnement, entre-aide, challenges....
        </p>
        <p class="has-text-weight-bold">
          La communauté est votre terrrain de jeu !
        </p>
      </div>
      <GoProWrapper planLock="PRO" @cta-click="$emit('close')">
        <form>
          <b-switch
            v-model="store.community_options.enabled"
            @input="dataIsValid(isLoading) && onSave()"
          >
            Activer ma communauté
          </b-switch>

          <b-loading v-model="isLoading" :is-full-page="false" />
        </form>
      </GoProWrapper>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Fermer
      </b-button>
    </template>
  </AppCardModal>
</template>
