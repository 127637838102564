import { api } from '@shared/config/axios';

export default {
  getBundles(params) {
    return api.$get('/bundles', { params });
  },
  getBundlesCount() {
    return api.$get('/bundles/count').then((data) => data.count);
  },
  getBundle(uuid) {
    return api.$get(`/bundles/${uuid}`);
  },
  createBundle(data) {
    return api.$post('/bundles', data);
  },
  updateBundle(uuid, data) {
    return api.$put(`/bundles/${uuid}`, data);
  },
  addTraining(bundle, training) {
    return api.$post(`/bundles/${bundle.uuid}/trainings/${training.uuid}`);
  },
  removeTraining(bundle, training) {
    return api.$delete(`/bundles/${bundle.uuid}/trainings/${training.uuid}`);
  },
  reorderBundles(bundles) {
    return api.$post('/bundles/order', { bundle_uuids: bundles.map((bundle) => bundle.uuid) });
  },
  reorder(bundle, items) {
    return api.$post(`/bundles/${bundle.uuid}/items/order`, { item_uuids: items.map((item) => item.uuid) });
  },
  deleteBundle(bundle) {
    return api.$delete(`/bundles/${bundle.uuid}`);
  },
  addPaymentPlan(uuid, data) {
    return api.$post(`/bundles/${uuid}/payment_schedules`, data);
  },
  deletePaymentPlan(uuid, data) {
    return api.$delete(`/bundles/${uuid}/payment_schedules/${data.id}`);
  },
};
