import Vue from 'vue';
import VueAuth from '@websanova/vue-auth';
import auth from '@websanova/vue-auth/drivers/auth/bearer';
import http from '@websanova/vue-auth/drivers/http/axios.1.x';
import authRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x';
import { api } from '@shared/config/axios';
import router from './router';

Vue.axios = api;
Vue.router = router;
Vue.use(VueAuth, {
  auth,
  http,
  router: authRouter,
  authRedirect: false,
  tokenDefaultKey: 'tzauth',
  rolesKey: 'role',
  loginData: {
    url: '/login',
    method: 'POST',
    redirect: false,
    fetchUser: false,
    staySignedIn: true,
  },
  logoutData: {
    redirect: false,
  },
  registerData: {
    url: '/register',
    method: 'POST',
    redirect: false,
    autoLogin: false,
    staySignedIn: true,
  },
  fetchData: {
    url: '/users/me',
    method: 'GET',
    enabled: false,
  },
  refreshData: {
    enabled: false,
  },
});
