<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    srcWebp: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'image/png',
    },
    imgClass: {
      type: [String, Array, Object],
      default: '',
    },
    imgStyle: {
      type: [String, Array, Object],
      default: '',
    },
    imgAttrs: {
      type: Object,
      default: () => ({}),
    },
    imgOn: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<template functional>
  <picture
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-bind="data.attrs"
    v-on="data.nativeOn"
  >
    <source :srcSet="props.srcWebp" type="image/webp">
    <source :srcSet="props.src" :type="props.type">
    <img
      :class="props.imgClass"
      :style="props.imgStyle"
      :src="props.src"
      v-bind="props.imgAttrs"
      v-on="props.imgOn"
    >
  </picture>
</template>
