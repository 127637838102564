<script>
import get from 'lodash/get';
import set from 'lodash/set';

export default {
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    fieldDefinition: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    formValidation: {
      type: Object,
      default: null,
    },
  },
  computed: {
    model: {
      get() {
        return get(this.formData, this.fieldName);
      },
      set(value) {
        const data = set({ ...this.formData }, this.fieldName, value);
        this.$emit('update:formData', data);
      },
    },
    validation() {
      if (!this.formValidation) {
        return null;
      }

      return get(this.formValidation, this.fieldName);
    },
  },
};
</script>

<template>
  <BaseField
    v-model="model"
    :v="validation"
    :field="fieldDefinition"
  />
</template>
