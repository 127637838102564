<script>
import CommunityWrapper from '@shared/components/CommunityWrapper.vue';
import AppHeader from '@app/components/AppHeader.vue';
import AlertList from '@app/components/AlertList.vue';

export default {
  name: 'CommunityLayout',
  head: {
    htmlAttrs: { class: 'has-background-light' },
  },
  components: {
    AppHeader,
    AlertList,
    CommunityWrapper,
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    this.$store.commit('community/setState', {
      context: 'USER',
    });
  },
};
</script>

<template>
  <div v-if="authUser" class="ccomp pb-32">
    <AppHeader>
      <template #leftBtn>
        <b-button
          tag="router-link"
          type="is-text"
          icon-left="arrow-left"
          title="Retour au tableau de bord"
          :to="{name: 'home'}"
        />
      </template>
    </AppHeader>

    <main class="p-5 py-3-mobile px-0-mobile">
      <AlertList />
      <CommunityWrapper>
        <slot />
      </CommunityWrapper>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.ccomp {
  padding-top: $navbar-height;
}
</style>
