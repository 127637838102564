import APIStore from '@app/services/API/Store';

export const state = () => ({
  discounts: null,
});

export const mutations = {
  set(state, discounts) {
    state.discounts = discounts;
  },
  add(state, discount) {
    if (!state.discounts) {
      return;
    }

    state.discounts.data.unshift(discount);
  },
  remove(state, discount) {
    if (!state.discounts) {
      return;
    }

    state.discounts.data = state.discounts.data.filter((v) => v.id !== discount.id);
  },
};

export const actions = {
  fetch({ commit }, storeUUID) {
    return APIStore.getDiscounts(storeUUID)
      .then((data) => commit('set', data));
  },
  add({ commit }, { storeUUID, discount }) {
    return APIStore.addDiscount(storeUUID, discount)
      .then((data) => commit('add', data.data));
  },
  remove({ commit }, { storeUUID, discount }) {
    return APIStore.deleteDiscount(storeUUID, discount.id)
      .then(() => commit('remove', discount));
  },
};
