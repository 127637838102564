import { api } from '@shared/config/axios';

export default {
  getAffiliates({ url, storeUUID }, params) {
    if (url) {
      return api.$get(url);
    }

    return api.$get(`/stores/${storeUUID}/customers/affiliates`, {
      params,
    });
  },
  addAffiliate(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/customers/affiliates`, data);
  },
  updateAffiliate(storeUUID, affiliateUUID, data) {
    return api.$put(
      `/stores/${storeUUID}/customers/affiliates/${affiliateUUID}`,
      data,
    );
  },
  getAffiliate(storeUUID, affiliateUUID) {
    return api.$get(`/stores/${storeUUID}/customers/affiliates/${affiliateUUID}`);
  },
  removeAffiliate(storeUUID, affiliateUUID) {
    return api.$delete(`/stores/${storeUUID}/customers/affiliates/${affiliateUUID}`);
  },
  getAffiliatesStats(storeUUID) {
    return api.$get(`/stores/${storeUUID}/customers/affiliates/stats`);
  },
  getAffiliatesSales({ url, storeUUID }, params) {
    if (url) {
      return api.$get(url);
    }

    return api.$get(`/stores/${storeUUID}/customers/affiliates/sales`, {
      params,
    });
  },
  managePayout(storeUUID, affiliateUUID, data) {
    return api.$put(
      `/stores/${storeUUID}/customers/affiliates/${affiliateUUID}/payout`,
      data,
    );
  },
};
