import Vue from 'vue';

const routerHistory = Vue.observable({ referrer: null });

export default ({ router }) => {
  router.beforeEach((to, from, next) => {
    if (from.name == null && from.path === '/') {
      routerHistory.referrer = null;
      next();
      return;
    }

    routerHistory.referrer = from;
    next();
  });

  Vue.mixin({
    computed: {
      $routeReferrer() {
        return routerHistory.referrer;
      },
    },
  });
};
