<script>
import UserOnBoarding from '@app/components/UserOnBoarding.vue';
import ProPlanAdvantages from '@app/components/ProPlanAdvantages.vue';

export default {
  name: 'Home',
  components: { UserOnBoarding, ProPlanAdvantages },
  head: {
    title: 'Accueil',
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
  },
};
</script>

<template>
  <div class="comp">
    <h1 class="title is-size-4-mobile has-text-centered">
      Bienvenue <strong class="tdecoration-underline">{{ user.firstname }}</strong>
    </h1>
    <div class="content has-text-centered">
      <p>Créons ensemble pas à pas votre espace personnalisé de formation !</p>
    </div>

    <section class="onboarding-section container is-728">
      <UserOnBoarding />
    </section>

    <section class="plan-section container">
      <p v-if="isNotPercentPlan" class="title is-size-4-mobile is-size-4 has-text-centered">
        En tant que membre <strong class="tdecoration-underline" v-t="`plans.${authStore.plan}`" />, vous pouvez :
      </p>
      <p v-else class="title is-size-4-mobile is-size-4 has-text-centered">
        Avec Teachizy, vous pouvez :
      </p>

      <ProPlanAdvantages class="mt-5" />
    </section>
  </div>
</template>

<style scoped>
.plan-section {
  margin-top: 70px;
}
</style>
