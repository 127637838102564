import Vue from 'vue';
import VueRouter from 'vue-router';
import community from '@shared/config/routes/community';
import Home from '@app/pages/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    meta: { checkAuth: true, isResponsive: true },
    name: 'home',
    component: Home,
  },
  {
    path: '/connexion',
    meta: { layout: 'Blank', isResponsive: true, noOnboarding: true },
    name: 'login',
    component: () => import('@app/pages/Login.vue'),
  },
  {
    path: '/inscription',
    meta: { layout: 'Blank', isResponsive: true, noOnboarding: true },
    name: 'register',
    component: () => import('@app/pages/Register.vue'),
  },
  {
    path: '/inscription/invitation',
    meta: { layout: 'Blank', isResponsive: true, noOnboarding: true },
    name: 'register_from_invite',
    component: () => import('@app/pages/RegisterFromInvite.vue'),
  },
  {
    path: '/activation',
    meta: { layout: 'Blank', isResponsive: true, noOnboarding: true },
    name: 'activation',
    component: () => import('@app/pages/Activation.vue'),
  },
  {
    path: '/changement-mot-de-passe',
    meta: { layout: 'Blank', isResponsive: true },
    name: 'resetpassword',
    component: () => import('@app/pages/ResetPassword.vue'),
  },
  {
    path: '/statistiques',
    meta: { checkAuth: ['OWNER', 'ADMIN', 'ANALYST'], isResponsive: true },
    name: 'statistics',
    component: () => import('@app/pages/Statistics.vue'),
  },
  {
    path: '/apprenants',
    meta: { checkAuth: ['OWNER', 'ADMIN', 'ANALYST'], isResponsive: true },
    name: 'customers',
    component: () => import('@app/pages/Customers.vue'),
  },
  {
    path: '/apprenants/:uuid',
    meta: { checkAuth: ['OWNER', 'ADMIN', 'ANALYST'], isResponsive: true },
    name: 'customer',
    component: () => import('@app/pages/Customer.vue'),
  },
  {
    path: '/ventes',
    meta: { checkAuth: ['OWNER', 'ADMIN', 'ANALYST'], isResponsive: true },
    name: 'sales',
    component: () => import('@app/pages/Sales.vue'),
  },
  {
    path: '/assistant-ia',
    meta: { checkAuth: ['OWNER', 'ADMIN'] },
    component: () => import('@app/pages/AIAssistant.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'ai_assistant',
        component: () => import('@app/pages/ai-assistant/Index.vue'),
      },
      {
        path: 'historique',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'ai_assistant_history',
        component: () => import('@app/pages/ai-assistant/History.vue'),
      },
      {
        path: 'tableau-de-bord',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'ai_assistant_dashboard',
        component: () => import('@app/pages/ai-assistant/Dashboard.vue'),
      },
    ],
  },
  {
    path: '/reductions',
    meta: { checkAuth: ['OWNER', 'ADMIN', 'ANALYST'], isResponsive: true },
    name: 'discounts',
    component: () => import('@app/pages/Discounts.vue'),
  },
  community,
  {
    path: '/marketing',
    meta: { checkAuth: ['OWNER', 'ADMIN'] },
    component: () => import('@app/pages/Marketing.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'marketing',
        component: () => import('@app/pages/marketing/Index.vue'),
      },
      {
        path: 'connexions-externes',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'marketing_automations',
        component: () => import('@app/pages/marketing/Automations.vue'),
      },
      {
        path: 'outils-automatisation',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'marketing_automation_tools',
        component: () => import('@app/pages/marketing/AutomationTools.vue'),
      },
      {
        path: 'emails',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'marketing_emails',
        component: () => import('@app/pages/marketing/Emails.vue'),
      },
    ],
  },
  {
    path: '/personnalisation',
    meta: { checkAuth: ['OWNER', 'ADMIN'] },
    component: () => import('@app/pages/Customization.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'customization',
        component: () => import('@app/pages/customization/Index.vue'),
      },
      {
        path: 'pages',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'customization_pages',
        component: () => import('@app/pages/customization/Pages.vue'),
      },
      {
        path: 'interne',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'customization_internal',
        component: () => import('@app/pages/customization/Internal.vue'),
      },
    ],
  },
  {
    path: '/widgets',
    meta: { checkAuth: ['OWNER', 'ADMIN'] },
    name: 'embeds',
    component: () => import('@app/pages/Embeds.vue'),
  },
  {
    path: '/affilies',
    meta: { checkAuth: ['OWNER', 'ADMIN'] },
    name: 'affilates',
    component: () => import('@app/pages/Affiliates.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'affiliates',
        component: () => import('@app/pages/affiliates/Index.vue'),
        children: [
          {
            path: ':uuid/profil',
            meta: {
              checkAuth: ['OWNER', 'ADMIN'],
              isResponsive: true,
              noScroll: true,
            },
            name: 'affiliate',
            component: () => import('@app/pages/affiliates/uuid/Profile.vue'),
          },
          {
            path: ':uuid/modifier',
            meta: {
              checkAuth: ['OWNER', 'ADMIN'],
              isResponsive: true,
              noScroll: true,
            },
            name: 'affiliate_edit',
            component: () => import('@app/pages/affiliates/uuid/Edit.vue'),
          },
        ],
      },
      {
        path: 'ventes',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'affiliates_sales',
        component: () => import('@app/pages/affiliates/Sales.vue'),
      },
      {
        path: 'parametres',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'affiliates_settings',
        component: () => import('@app/pages/affiliates/Settings.vue'),
      },
    ],
  },
  {
    path: '/affiliation',
    meta: { checkAuth: ['OWNER'] },
    component: () => import('@app/pages/Affiliation.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: ['OWNER'], isResponsive: true },
        name: 'affiliation',
        component: () => import('@app/pages/affiliation/Index.vue'),
      },
      {
        path: 'affilies',
        meta: { checkAuth: ['OWNER'], isResponsive: true },
        name: 'affiliation_affiliates',
        component: () => import('@app/pages/affiliation/Affiliates.vue'),
      },
      {
        path: 'revenus',
        meta: { checkAuth: ['OWNER'], isResponsive: true },
        name: 'affiliation_revenus',
        component: () => import('@app/pages/affiliation/Revenus.vue'),
      },
    ],
  },
  {
    path: '/formations',
    meta: { checkAuth: true },
    component: () => import('@app/pages/Trainings.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: true, isResponsive: true },
        name: 'trainings',
        component: () => import('@app/pages/trainings/Index.vue'),
      },
      {
        path: 'certificats',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'trainings_certificates',
        component: () => import('@app/pages/trainings/Certificates.vue'),
      },
      {
        path: 'parametres',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'trainings_settings',
        component: () => import('@app/pages/trainings/Settings.vue'),
      },
    ],
  },
  {
    path: '/packs',
    meta: { checkAuth: true },
    component: () => import('@app/pages/Bundles.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: true, isResponsive: true },
        name: 'bundles',
        component: () => import('@app/pages/bundles/Index.vue'),
      },
      // {
      //   path: 'certificats',
      //   meta: { checkAuth: ['OWNER', 'ADMIN'] },
      //   name: 'trainings_certificates',
      //   component: () => import('@app/pages/trainings/Certificates.vue'),
      // },
      // {
      //   path: 'parametres',
      //   meta: { checkAuth: ['OWNER', 'ADMIN'] },
      //   name: 'trainings_settings',
      //   component: () => import('@app/pages/trainings/Settings.vue'),
      // },
    ],
  },
  {
    path: '/packs/:uuid',
    meta: { checkAuth: true },
    component: () => import('@app/pages/Bundle.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: true },
        name: 'bundle',
        component: () => import('@app/pages/bundle/Index.vue'),
      },
      // {
      //   path: '',
      //   meta: { checkAuth: true },
      //   name: 'training',
      //   component: () => import('@app/pages/bundles/Index.vue'),
      //   children: [
      //     {
      //       path: 'editor/:id',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_editor',
      //       component: () => import('@app/pages/training/uuid/Editor.vue'),
      //     },
      //     {
      //       path: 'quiz/:id',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_quiz',
      //       component: () => import('@app/pages/training/uuid/Quiz.vue'),
      //     },
      //     {
      //       path: 'quiz/:id/answers',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_quiz_answers',
      //       component: () => import('@app/pages/training/uuid/QuizAnswers.vue'),
      //     },
      //     {
      //       path: 'tasks/:id',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_tasks',
      //       component: () => import('@app/pages/training/uuid/Tasks.vue'),
      //     },
      //     {
      //       path: 'assignment/:id',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_assignment',
      //       component: () => import('@app/pages/training/uuid/Assignment.vue'),
      //     },
      //     {
      //       path: 'assignment/:id/submissions',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_assignment_submissions',
      //       component: () => import('@app/pages/training/uuid/AssignmentSubmissions.vue'),
      //     },
      //     {
      //       path: 'assignment/:id/submissions/:submissionId',
      //       meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //       name: 'training_uuid_assignment_submission',
      //       component: () => import('@app/pages/training/uuid/AssignmentSubmission.vue'),
      //     },
      //   ],
      // },
      {
        path: 'parametres',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'bundle_settings',
        component: () => import('@app/pages/bundle/Settings.vue'),
      },
      {
        path: 'prix',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'bundle_price',
        component: () => import('@app/pages/bundle/Price.vue'),
      },
      {
        path: 'description',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'bundle_description',
        component: () => import('@app/pages/bundle/Description.vue'),
      },
      // {
      //   path: 'apprenants',
      //   meta: { checkAuth: true, isResponsive: true },
      //   name: 'training_customers',
      //   component: () => import('@app/pages/training/Customers.vue'),
      // },
      // {
      //   path: 'commentaires',
      //   meta: { checkAuth: true },
      //   name: 'training_comments',
      //   component: () => import('@app/pages/training/Comments.vue'),
      // },
      // {
      //   path: 'statistiques',
      //   meta: { checkAuth: true },
      //   name: 'training_reporting',
      //   component: () => import('@app/pages/training/Reporting.vue'),
      // },

      // // TODO: remove after some time and after docs update
      // {
      //   path: 'seo',
      //   meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //   name: 'training_seo',
      //   redirect: { name: 'training_settings' },
      // },
      // {
      //   path: 'interne',
      //   meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
      //   name: 'training_internal',
      //   redirect: { name: 'training_settings' },
      // },
    ],
  },
  {
    path: '/formations/:uuid',
    meta: { checkAuth: true },
    component: () => import('@app/pages/Training.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: true },
        name: 'training',
        component: () => import('@app/pages/training/Index.vue'),
        children: [
          {
            path: 'editor/:id',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_editor',
            component: () => import('@app/pages/training/uuid/Editor.vue'),
          },
          {
            path: 'quiz/:id',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_quiz',
            component: () => import('@app/pages/training/uuid/Quiz.vue'),
          },
          {
            path: 'quiz/:id/answers',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_quiz_answers',
            component: () => import('@app/pages/training/uuid/QuizAnswers.vue'),
          },
          {
            path: 'tasks/:id',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_tasks',
            component: () => import('@app/pages/training/uuid/Tasks.vue'),
          },
          {
            path: 'assignment/:id',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_assignment',
            component: () => import('@app/pages/training/uuid/Assignment.vue'),
          },
          {
            path: 'assignment/:id/submissions',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_assignment_submissions',
            component: () => import('@app/pages/training/uuid/AssignmentSubmissions.vue'),
          },
          {
            path: 'assignment/:id/submissions/:submissionId',
            meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
            name: 'training_uuid_assignment_submission',
            component: () => import('@app/pages/training/uuid/AssignmentSubmission.vue'),
          },
        ],
      },
      {
        path: 'parametres',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'training_settings',
        component: () => import('@app/pages/training/Settings.vue'),
      },
      {
        path: 'prix',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'training_price',
        component: () => import('@app/pages/training/Price.vue'),
      },
      {
        path: 'description',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'training_description',
        component: () => import('@app/pages/training/Description.vue'),
      },
      {
        path: 'apprenants',
        meta: { checkAuth: true, isResponsive: true },
        name: 'training_customers',
        component: () => import('@app/pages/training/Customers.vue'),
      },
      {
        path: 'commentaires',
        meta: { checkAuth: true },
        name: 'training_comments',
        component: () => import('@app/pages/training/Comments.vue'),
      },
      {
        path: 'statistiques',
        meta: { checkAuth: true },
        name: 'training_reporting',
        component: () => import('@app/pages/training/Reporting.vue'),
      },

      // TODO: remove after some time and after docs update
      {
        path: 'seo',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'training_seo',
        redirect: { name: 'training_settings' },
      },
      {
        path: 'interne',
        meta: { checkAuth: ['OWNER', 'ADMIN', 'TEACHER'] },
        name: 'training_internal',
        redirect: { name: 'training_settings' },
      },
    ],
  },
  {
    path: '/abonnement',
    meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
    name: 'plan',
    component: () => import('@app/pages/Plan.vue'),
  },
  {
    path: '/parametres',
    meta: { checkAuth: ['OWNER', 'ADMIN'] },
    component: () => import('@app/pages/Settings.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'settings',
        component: () => import('@app/pages/settings/Index.vue'),
      },
      {
        path: 'equipe',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'settings_team',
        component: () => import('@app/pages/settings/Team.vue'),
      },
      {
        path: 'seo',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'settings_seo',
        component: () => import('@app/pages/settings/SEO.vue'),
      },
      {
        path: 'sso',
        meta: { checkAuth: ['OWNER', 'ADMIN'] },
        name: 'settings_sso',
        component: () => import('@app/pages/settings/SSO.vue'),
      },
      {
        path: 'entreprise',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'settings_company',
        component: () => import('@app/pages/settings/Company.vue'),
      },
      {
        path: 'paiements',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'settings_payments',
        component: () => import('@app/pages/settings/Payments.vue'),
      },
      {
        path: 'communications',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'settings_communication',
        component: () => import('@app/pages/settings/Communication.vue'),
      },
      {
        path: 'integrations',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'settings_intergrations',
        component: () => import('@app/pages/settings/Intergrations.vue'),
      },
      {
        path: 'facturation',
        meta: { checkAuth: ['OWNER', 'ADMIN'], isResponsive: true },
        name: 'settings_billing',
        component: () => import('@app/pages/settings/Billing.vue'),
      },
    ],
  },
  {
    path: '/mon-compte',
    meta: { checkAuth: true, isResponsive: true },
    component: () => import('@app/pages/Account.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: true, isResponsive: true },
        name: 'account',
        component: () => import('@app/pages/account/Index.vue'),
      },
      {
        path: 'securite',
        meta: { checkAuth: true, isResponsive: true },
        name: 'account_security',
        component: () => import('@app/pages/account/Security.vue'),
      },
    ],
  },
  {
    path: '/admin',
    meta: { checkAuth: { is_admin: true } },
    component: () => import('@app/pages/Admin.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: { is_admin: true }, isResponsive: true },
        name: 'admin',
        component: () => import('@app/pages/admin/Index.vue'),
      },
      {
        path: 'tops',
        meta: { checkAuth: { is_admin: true }, isResponsive: true },
        name: 'admin_tops',
        component: () => import('@app/pages/admin/Tops.vue'),
      },
      {
        path: 'operations',
        meta: { checkAuth: { is_admin: true }, isResponsive: true },
        name: 'admin_operations',
        component: () => import('@app/pages/admin/Operations.vue'),
      },
    ],
  },
  {
    path: '*',
    meta: { layout: 'Blank' },
    component: () => import('@app/pages/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.meta.noScroll === true || from.meta.noScroll === true) {
      return null;
    }

    return { x: 0, y: 0 };
  },
});

export default router;
