export default {
  props: {
    dueDate: {
      type: [String, Date],
      required: true,
    },
    isUTC: {
      type: Boolean,
      default: true,
    },
    granularity: {
      type: Array,
      default() {
        return ['hours', 'minutes'];
      },
    },
  },
  data: () => ({
    isPassed: false,
    months: null,
    weeks: null,
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  }),
  methods: {
    getDueDate() {
      if (this.isUTC) {
        return this.$moment.utc(this.dueDate).local().valueOf();
      }

      return this.$moment(this.dueDate).valueOf();
    },
    calculate() {
      const now = Date.now();
      const dueDate = this.getDueDate();
      let timeLeft = Math.round((dueDate - now) / 1000);

      if (timeLeft <= 0) {
        this.isPassed = true;
        this.months = null;
        this.weeks = null;
        this.days = null;
        this.hours = null;
        this.minutes = null;
        this.seconds = null;
        clearInterval(this.timer);
        return;
      }

      let months = null;
      let weeks = null;
      let days = null;
      let hours = null;
      let minutes = null;
      let seconds = null;

      if (this.granularity.includes('month')) {
        months = Math.floor(timeLeft / (60 * 60 * 24 * 30));
        months = months > 9 ? months : `0${months}`;
        months = `${months}M`;
        timeLeft %= (60 * 60 * 24 * 30);
      }

      if (this.granularity.includes('weeks')) {
        weeks = Math.floor(timeLeft / (60 * 60 * 24 * 7));
        weeks = weeks > 9 ? weeks : `0${weeks}`;
        weeks = `${weeks}S`;
        timeLeft %= (60 * 60 * 24 * 7);
      }

      if (this.granularity.includes('days')) {
        days = Math.floor(timeLeft / (60 * 60 * 24));
        days = days > 9 ? days : `0${days}`;
        days = `${days}j`;
        timeLeft %= (60 * 60 * 24);
      }

      if (this.granularity.includes('hours')) {
        hours = Math.floor(timeLeft / (60 * 60));
        hours = hours > 9 ? hours : `0${hours}`;
        hours = `${hours}h`;
        timeLeft %= (60 * 60);
      }

      if (this.granularity.includes('minutes')) {
        minutes = Math.floor(timeLeft / 60);
        minutes = minutes > 9 ? minutes : `0${minutes}`;
        minutes = `${minutes}m`;
        timeLeft %= 60;
      }

      seconds = timeLeft > 9 ? timeLeft : `0${timeLeft}`;
      seconds = `${seconds}s`;

      this.months = months;
      this.weeks = weeks;
      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
  },
  created() {
    this.calculate();
    this.timer = setInterval(() => this.calculate(), 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  render() {
    return this.$scopedSlots.default && this.$scopedSlots.default({
      isPassed: this.isPassed,
      months: this.months,
      weeks: this.weeks,
      days: this.days,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
    });
  },
};
