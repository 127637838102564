export default (maxSizes = {}, minSizes = {}) => ({
  data() {
    const reducer = (prev, curr) => {
      prev[curr] = false;
      return prev;
    };

    return {
      ...Object.keys(maxSizes).reduce(reducer, {}),
      ...Object.keys(minSizes).reduce(reducer, {}),
    };
  },
  methods: {
    setResponsiveSizes() {
      const onResize = () => {
        Object.keys(maxSizes)
          .map((size) => (this[size] = (window.innerWidth <= maxSizes[size])));

        Object.keys(minSizes)
          .map((size) => (this[size] = (window.innerWidth >= minSizes[size])));
      };
      onResize();
      window.addEventListener('resize', onResize);
      this.$on('hook:destroyed', () => {
        window.removeEventListener('resize', onResize);
      });
    },
  },
  created() {
    this.setResponsiveSizes();
  },
});
