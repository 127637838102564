<script>
export default {
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <b-dropdown
    aria-role="list"
    :value="value"
    expanded
    @input="$emit('input', $event)"
  >
    <template #trigger>
      <template v-for="option in options">
        <b-button
          v-if="option.value === value"
          :key="option.label"
          class="is-justify-content-space-between"
          :label="option.label"
          :icon-left="option.iconLeft"
          :icon-right="option.iconRight || 'chevron-down'"
          expanded
        />
      </template>
    </template>

    <template v-for="option in options">
      <b-dropdown-item
        :key="`${option.label}-item`"
        class="wspace-normal py-4"
        :value="option.value"
        aria-role="listitem"
      >
        <div class="media">
          <b-icon
            v-if="option.iconLeft"
            class="media-left"
            :icon="option.iconLeft"
          />
          <div class="media-content">
            <h3 class="title is-6 mb-2">
              {{ option.label }}
            </h3>
            <div
              v-if="option.detailsHTML"
              class="content is-size-7"
              v-html="option.detailsHTML"
            />
            <div v-else class="is-size-7">
              {{ option.details }}
            </div>
          </div>
          <b-icon
            v-if="option.iconRight"
            class="media-right"
            :icon="option.iconRight"
          />
        </div>
      </b-dropdown-item>
      <b-dropdown-item :key="`${option.label}-sep`" separator />
    </template>
  </b-dropdown>
</template>
