<script>
export default {
  props: {
    api: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    send() {
      this.isLoading = true;
      this.api.sendVerificationEmail(this.email)
        .then(() => this.$buefy.dialog.alert({
          title: 'Un nouveau lien vous a été envoyé',
          message: `
            Si vous ne trouvez pas l'email dans votre boîte de réception principale,
            vérifiez dans l'onglet <strong>Spams</strong> ou <strong>Promotions</strong>.<br><br>
            Ce nouveau lien invalide tous les précédents.
          `,
        }))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="box has-background-warning mb-6">
    <div class="columns is-multiline is-vcentered">
      <div class="column">
        <b-icon class="valign-middle mr-3" icon="info-circle" />
        Veuillez activer votre compte. Un lien vous a été envoyé par email.
      </div>
      <div class="column is-narrow">
        <b-button type="is-white" :loading="isLoading" @click="send">
          Renvoyer l'email d'activation
        </b-button>
      </div>
    </div>
  </div>
</template>
