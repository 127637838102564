<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    srcLow: {
      type: String,
      required: true,
    },
    srcWebp: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'image/png',
    },
  },
};
</script>

<template functional>
  <v-lazy-image
    :src="props.src"
    :src-placeholder="props.srcLow"
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    use-picture
    v-bind="data.attrs"
    v-on="data.nativeOn"
  >
    <source :srcSet="props.srcWebp" type="image/webp">
    <source :srcSet="props.src" :type="props.type">
  </v-lazy-image>
</template>
