<script>
import AppHeader from '@app/components/AppHeader.vue';
import AlertList from '@app/components/AlertList.vue';
import TheNavDrawer from '@app/components/TheNavDrawer.vue';
import MainNav from '@app/components/MainNav.vue';

export default {
  name: 'AppLayout',
  head: {
    htmlAttrs: { class: 'has-background-light' },
  },
  components: {
    AppHeader,
    AlertList,
    TheNavDrawer,
    MainNav,
  },
  data: () => ({
    hasNavHidden: true,
  }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    onNavigate() {
      if (!this.$refs.drawer || this.$refs.drawer.isWide) {
        return;
      }

      this.$refs.drawer.close();
    },
  },
};
</script>

<template>
  <div v-if="authUser" class="dcomp pb-32">
    <AppHeader :onNavigate="onNavigate">
      <template #leftBtn>
        <b-button
          type="is-text"
          icon-left="bars"
          :title="hasNavHidden ? 'Ouvrir le menu' : 'Fermer le menu'"
          @click="$refs.drawer.toggle()"
        />
      </template>
    </AppHeader>

    <TheNavDrawer ref="drawer" @change="hasNavHidden = $event">
      <template #nav>
        <MainNav :onNavigate="onNavigate" />
      </template>

      <template #default>
        <AlertList />
        <slot />
      </template>
    </TheNavDrawer>
  </div>
</template>
