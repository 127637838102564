require('intersection-observer');

window.Promise = require('bluebird');

window.console = window.console || {
  assert() {},
  clear() {},
  context() {},
  count() {},
  countReset() {},
  debug() {},
  dir() {},
  dirxml() {},
  error() {},
  group() {},
  groupCollapsed() {},
  groupEnd() {},
  info() {},
  log() {},
  profile() {},
  profileEnd() {},
  table() {},
  time() {},
  timeEnd() {},
  timeLog() {},
  timeStamp() {},
  trace() {},
  warn() {},
};

Promise.config({
  // Enable warnings
  warnings: false,
  // Enable long stack traces
  longStackTraces: false,
  // Enable cancellation
  cancellation: true,
  // Enable monitoring
  monitoring: true,
  // Enable async hooks
  asyncHooks: true,
});

// assumes an ES5 compliant browser for Array.prototype.forEach
// and Object.defineProperty
// and a sane browser for Element.prototype.addEventListener
(function() {
  // no need to shim if it's already there
  if ('hidden' in document && 'onvisibilitychange' in document) {
    return;
  }

  // no-op on legacy browsers.
  if (
    !document.addEventListener
    || !Array.prototype.forEach
    || !Object.defineProperty
    || !window.addEventListener
  ) {
    return;
  }

  // check to see if there's a native implementation
  const prefixes = ['webkit', 'moz', 'o', 'ms'];
  let native = '';
  let hiddenName = 'hidden';
  let hidden = false;

  prefixes.forEach((prefix) => {
    if ((`${prefix}Hidden`) in document) {
      native = prefix;
      hiddenName = `${prefix}Hidden`;
    }
  });

  // will be called whenever the visibility has been changed
  const visibilityChange = function() {
    document[hiddenName] = hidden;
    document.visibilityState = document.hidden ? 'hidden' : 'visible';

    // create the visibilitychange event
    const evt = document.createEvent('Event');
    evt.initEvent('visibilitychange', true, true);
    // you're FIRED!
    document.dispatchEvent(evt);

    // check for document.onvisibilitychange and call it if it exists
    const vischange = 'onvisibilitychange'; // it's really long to write...

    if (document[vischange] && document[vischange].call) {
      document[vischange].call(document, evt);
    }
  };

  // add the event listeners to tap into native functionality
  if (native) {
    document.addEventListener(`${native}visibilitychange`, visibilityChange);
  } else {
    // the window.focus and window.blur events are, well, close enough
    window.addEventListener('focus', () => {
      hidden = false;
      visibilityChange();
    });
    window.addEventListener('blur', () => {
      hidden = true;
      visibilityChange();
    });
  }

  // add document.hidden, document.visibilityState
  Object.defineProperty(document, 'hidden', {
    value: hidden,
    configurable: true,
  });
  Object.defineProperty(document, 'visibilityState', {
    value: document.hidden ? 'hidden' : 'visible',
    configurable: true,
  });

  // DOM : closest
  if (typeof Element !== 'undefined') {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        let el = this;

        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);

        return null;
      };
    }
  }
}());
