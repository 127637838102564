import { methods } from './store';
import Layout from './Layout.vue';

const defaults = {
  layouts: {},
  metaKey: 'layout',
  defaultLayout: 'default',
  componentName: 'MFLayout',
  router: null,
};

export default {
  install(Vue, options = {}) {
    options = { ...defaults, ...options };

    if (options.router == null) {
      throw new Error('[MFLayout] No router given');
    }

    if (Object.keys(options.layouts) === 0) {
      throw new Error('[MFLayout] You did not set any layout');
    }

    Vue.component(options.componentName, Layout);

    options.router.afterEach((to) => {
      const layout = to.meta[options.metaKey] || options.defaultLayout;
      methods.setLayout(options.layouts[layout]);
    });
  },
};
