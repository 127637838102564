import Vue from 'vue';
import VueScrollto from 'vue-scrollto';
import theme from '@shared/assets/styles/theme';

Vue.use(VueScrollto, {
  duration: 250,
  offset: -1 * (
    theme.component.navbar.height
    + (theme.component.navbar.py * 2)
    + (theme.component.navbar.px * 2)
    + theme.component.navbar.mb
  ),
});
