<script>
import { url } from 'vuelidate/lib/validators';

export default {
  props: {
    href: {
      type: String,
      default: null,
    },
  },
  i18n: {
    messages: {
      fr: {
        delete_link: 'Supprimer le lien',
      },
    },
  },
  data() {
    return {
      newHref: this.href,
    };
  },
  validations: {
    newHref: { url },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="dataIsValid() && $emit('save', newHref)">
    <b-field label="Entrez votre lien" v-bind="$getErrorProps($v.newHref, ['url'])">
      <b-input v-model="newHref" />
    </b-field>
    <div class="buttons is-right">
      <b-button
        v-if="href"
        type="is-danger"
        v-t="'delete_link'"
        inverted
        @click="$emit('save', null)"
      />
      <b-button
        native-type="submit"
        type="is-primary"
        v-t="'buttons.save'"
      />
    </div>
  </form>
</template>
