<script>
export default {
  render() {
    if (process.env.NODE_ENV === 'staging') {
      this.$buefy.dialog.alert(`
        Vous êtes sur un environnement de TEST !
      `);
    }

    return null;
  },
};
</script>
