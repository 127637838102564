import DOMPurify from 'dompurify';
import { mapActions } from 'vuex';

export default (context = null) => ({
  computed: {
    canManageCommunityEntity() {
      const group = this.$store.state.community.currentGroup;
      const user = this.$store.getters['community/communityUser'];
      const store_owner = this.$store.getters['auth/user'];

      return !!(
        (group && group.owner && group.owner.type_uuid === user.uuid)
        || (context && this[context]?.owner?.type_uuid === user.uuid)
        || (store_owner && store_owner.uuid === user.uuid)
      );
    },
  },
});

export const postsFeed = {
  props: {
    canPost: {
      type: Boolean,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
    posts: {
      type: Object,
      required: true,
    },
  },
};

export const postItem = {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    content() {
      return DOMPurify.sanitize(this.post.content);
    },
    excerpt() {
      return DOMPurify.sanitize(this.post.excerpt);
    },
    isScheduled() {
      return this.$moment
        .utc(this.post.published_at)
        .isAfter();
    },
    displayName() {
      return this.post.owner ? (
        this.post.owner.display_name
        || this.post.owner.firstname
      ) : 'Membre supprimé';
    },
    lastCommentAt() {
      const lastIndex = this.post.comments.data.length - 1;
      return this.post.comments.data[lastIndex]?.published_at;
    },
    lastCommenter() {
      const lastIndex = this.post.comments.data.length - 1;
      const comment = this.post.comments.data[lastIndex];

      if (!comment) {
        return null;
      }

      return comment.owner ? (
        comment.owner.display_name
        || comment.owner.firstname
      ) : 'Membre supprimé';
    },
  },
};

export const postSingle = {
  props: {
    post: {
      type: Object,
      required: true,
    },
    comment: {
      type: Object,
      default: null,
    },
  },
};

export const commentsList = {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    comments() {
      return this.post.comments;
    },
    postCommentsReadonly() {
      return this.post.settings?.readonly;
    },
  },
};

export const commentItem = {
  props: {
    post: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    comment: {
      type: Object,
      required: true,
    },
    isStandAlone: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    canComment: false,
    isEditing: false,
  }),
  computed: {
    isChild() {
      return this.parent != null;
    },
    content() {
      return DOMPurify.sanitize(this.comment.content);
    },
    displayName() {
      return this.comment.owner ? (
        this.comment.owner.display_name
        || this.comment.owner.firstname
      ) : 'Membre supprimé';
    },
    children() {
      return this.comment.children?.data;
    },
    canAnswer() {
      return !(
        this.post.settings?.readonly
        || this.parent?.settings?.readonly
        || this.comment.settings?.readonly
      );
    },
  },
};

export const commentForm = {
  props: {
    post: {
      type: Object,
      required: true,
    },
    parentUUID: {
      type: String,
      default: null,
    },
    comment: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    newComment: {
      content: '',
      settings: {
        readonly: false,
      },
    },
    isCommentPosting: false,
  }),
  methods: {
    ...mapActions('community', [
      'addComment',
      'updateComment',
    ]),
    handle() {
      if (this.isCommentPosting || !this.newComment.content.trim()) {
        return;
      }

      const data = { ...this.newComment };

      if (this.parentUUID) {
        data.parent = this.parentUUID;
      }

      this.isCommentPosting = true;
      let promise;

      if (this.comment) {
        promise = this.updateComment({
          postUUID: this.post.uuid,
          parentUUID: this.parentUUID,
          commentUUID: this.comment.uuid,
          data,
        });
      } else {
        promise = this.addComment({
          postUUID: this.post.uuid,
          data,
        });
      }

      promise.then(() => {
        this.newComment.content = '';

        if (this.$refs.newComment) {
          this.$refs.newComment.setValue('');
        }

        this.$showMessage.success();

        if (this.comment) {
          this.$emit('update');
        }
      })
        .finally(() => (this.isCommentPosting = false));
    },
  },
  created() {
    this.hydrateData('newComment', 'comment');
  },
};
