const events = {};

export default {
  on(eventName, callback = () => {}) {
    events[eventName] = events[eventName] || [];
    events[eventName].push(callback);

    return () => this.off(eventName, callback);
  },
  off(eventName, callback) {
    const handlers = events[eventName];

    if (!handlers) {
      return;
    }

    events[eventName] = handlers.filter((v) => v !== callback);
  },
  emit(eventName, value) {
    const handlers = events[eventName];

    if (process.env.NODE_ENV === 'development') {
      console.log('EventBus:emit ', eventName, value);
    }

    if (!handlers) {
      if (process.env.NODE_ENV === 'development') {
        console.log('EventBus: not handlers found');
      }
      return;
    }

    handlers.forEach((callback) => callback(value));
  },
};
