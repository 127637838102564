<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    parentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      file: null,
    };
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
  methods: {
    handleSubmit(e) {
      this.isLoading = true;
      const { uuid } = this.currentTraining;
      return this.$store
        .dispatch('trainings/addItem', {
          uuid,
          trainingItem: new FormData(e.target),
        })
        .then(() => this.$emit('close'))
        .catch((error) => this.$errorHandlers.axios(error))
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<template>
  <form class="modal-card" @submit.prevent="handleSubmit">
    <header class="modal-card-head">
      <h2 class="modal-card-title">
        <template v-if="item.id">
          Modifier le contenu SCORM
        </template>
        <template v-else>
          Ajouter un contenu SCORM (au format .zip)
        </template>
      </h2>
      <button
        type="button"
        class="modal-close is-large"
        aria-label="Fermer"
        @click="$emit('close')"
      />
    </header>
    <div class="modal-card-body">
      <input type="hidden" name="type" value="SCORM">
      <input type="hidden" name="parentId" :value="parentId">
      <span v-if="isLoading">
        Cette opération peut prendre plusieurs minutes.<br/>
        Merci de patienter, sans fermer votre navigateur...
      </span>
      <b-field class="file is-primary mt-4" :class="{'has-name': !!file}">
        <b-upload v-model="file" name="file" class="file-label">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">Sélectionner un contenu SCORM</span>
          </span>
          <span class="file-name" v-if="file">
            {{ file.name }}
          </span>
        </b-upload>
      </b-field>
    </div>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <div class="buttons">
        <b-button type="is-text" @click="$emit('close')">
          Fermer
        </b-button>
        <b-button
          :disabled="!file"
          type="is-primary"
          native-type="submit"
          :loading="isLoading">
          Enregistrer
        </b-button>
      </div>
    </footer>
  </form>
</template>
