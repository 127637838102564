<script>
export default {
  props: {
    value: {
      type: null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSelected() {
      return this.$parent.value && this.$parent.value.includes(this.value);
    },
  },
};
</script>

<template>
  <li>
    <button
      class="
        bmsocomp is-block p-2 mb-1 is-size-7 w-full has-text-left border-0
        cursor-pointer bradius-4
      "
      :class="{
        'has-background-primary has-text-white': isSelected
      }"
      type="button"
      :disabled="disabled"
      @click="$parent.select(value)">
      <b-icon
        :type="isSelected ? 'is-white' : 'is-primary'"
        :icon="isSelected ? 'check-square' : 'square'"
        pack="far"
        size="is-medium"
      />
      <slot />
    </button>
  </li>
</template>

<style lang="scss" scoped>
.bmsocomp {
  background: transparent;

  &:hover {
    background: $light;
  }
}
</style>
