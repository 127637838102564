const { isNaN } = Number;
const getActive = (active) => (active == null ? null : parseInt(active, 10));

export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    length: {
      type: [String, Number],
    },
    active: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isOpen: this.open,
      isActive: getActive(this.active),
    };
  },
  computed: {
    intLength() {
      return parseInt(this.length, 10);
    },
  },
  methods: {
    expand() {
      !this.isOpen && this.$emit('change', (this.isOpen = true));
    },
    collapse() {
      this.isOpen && this.$emit('change', (this.isOpen = false));
    },
    toggle() {
      this.$emit('change', (this.isOpen = !this.isOpen));
    },
    setActive(active) {
      active = getActive(active);

      if (
        active != null && (isNaN(active) || isNaN(this.intLength) || active < 0 || active >= this.intLength)
      ) {
        return;
      }

      this.$emit('change', (this.isActive = active));
    },
    toggleActive(active) {
      active = getActive(active);

      if (
        active != null && (isNaN(active) || isNaN(this.intLength) || active < 0 || active >= this.intLength)
      ) {
        return;
      }

      this.$emit('change', (this.isActive = (this.isActive === active) ? null : active));
    },
  },
  watch: {
    open(value) {
      this.isOpen = value;
    },
    active(value) {
      this.isActive = value;
    },
  },
  render() {
    const {
      isOpen, expand, collapse, toggle, isActive, setActive, toggleActive,
    } = this;
    return this.$scopedSlots.default({
      isOpen, expand, collapse, toggle, isActive, setActive, toggleActive,
    });
  },
};
