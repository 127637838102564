<script>
export default {
  provide() {
    return {
      toggleNavDrawer: this.toggle,
      closeNavDrawer: this.close,
    };
  },
  data: () => ({
    isWide: window.innerWidth > 1024,
    isHidden: window.innerWidth <= 1024,
  }),
  watch: {
    isHidden() {
      this.$emit('change', this.isHidden);
    },
  },
  methods: {
    toggle() {
      this.isHidden = !this.isHidden;
      this.$emit('change', this.isHidden);
    },
    close() {
      this.isHidden = true;
      this.$emit('change', this.isHidden);
    },
  },
  created() {
    this.$emit('change', this.isHidden);

    const onResize = () => {
      const isWideNow = window.innerWidth > 1024;

      if (isWideNow && !this.isWide) {
        this.isHidden = false;
      } else if (!isWideNow && this.isWide) {
        this.isHidden = true;
      }

      this.isWide = isWideNow;
      this.$emit('change', this.isHidden);
    };

    window.addEventListener('resize', onResize);
    this.$on('hook:destroyed', () => {
      window.removeEventListener('resize', onResize);
    });
  },
};
</script>

<template>
  <div class="tndcomp is-relative" :class="{'has-nav-hidden': isHidden}">
    <nav class="tndcomp_nav has-background-light px-3 py-5">
      <slot name="nav" />
    </nav>
    <main class="tndcomp_content">
      <slot />
    </main>

    <div class="tndcomp_overlay" @click="isHidden = true" />
  </div>
</template>

<style lang="scss" scoped>
.tndcomp {
  &_nav {
    overflow: auto;
    position: fixed;
    z-index: 19;
    top: 60px;
    left: 0;
    bottom: 0;
    width: $sidenav;
    border-right: 1px solid $theme_color_black_10;

    .has-nav-hidden & {
      display: none;
    }
  }

  &_content {
    padding-top: 60px + 40px;
    padding-left: $sidenav + 40px;
    padding-right: 40px;

    .has-nav-hidden & {
      padding-left: 40px;
    }
  }

  &_overlay {
    display: none;
  }

  @include touch {
    &_overlay {
      content: '';
      display: block;
      position: fixed;
      z-index: 18;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $theme_color_black_80;

      .has-nav-hidden & {
        display: none;
      }
    }

    &_content {
      padding-top: 60px + 20px;
      padding-left: 20px;
      padding-right: 20px;

      .has-nav-hidden & {
        padding-left: 20px;
      }
    }
  }
}
</style>
