import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

Vue.prototype.$copySuccess = function() {
  this.$buefy.toast
    .open({ type: 'is-success', message: 'Copié !' });
};

Vue.prototype.$copyError = function() {
  this.$buefy.toast
    .open({ type: 'is-danger', message: 'Oops, une erreur est survenue, veuillez réessayer à nouveau' });
};
