<script>
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  components: { TiptapEditor },
  props: {
    value: {
      type: null,
      required: true,
    },
    v: {
      type: Object,
      default: null,
    },
    field: {
      type: Object,
      required: true,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.labelFor = Math.random().toString(32).slice(2);
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
  computed: {
    errors() {
      return this.v ? this.$getErrorMessages(this.v, this.field.errors) : [];
    },
    tooltipAttrs() {
      const tooltipAttrs = this.field.tooltipAttrs || {};
      return { position: 'is-left', ...tooltipAttrs };
    },
    optionsTooltipAttrs() {
      if (!this.field.options) {
        return [];
      }

      return this.field
        .options
        .map((option) => {
          const tooltipAttrs = option.tooltipAttrs || {};
          return { position: 'is-left', ...tooltipAttrs };
        });
    },
    fieldAttrs() {
      const attrs = this.field.fieldAttrs || {};
      const hasError = (this.errors.length > 0);
      const type = hasError ? { 'is-danger': true } : null;
      return {
        type,
        labelFor: this.labelFor,
        ...attrs,
      };
    },
  },
};
</script>

<template>
  <b-field v-bind="fieldAttrs">
    <template v-if="field.label" #label>
      <span class="is-flex is-justify-content-space-between is-align-items-start">
        {{ field.label }}
        <b-tooltip v-if="field.tooltip || field.tooltipHTML" v-bind="tooltipAttrs">
          <template #content>
            <template v-if="field.tooltip">
              {{ field.tooltip }}
            </template>
            <span v-else v-html="field.tooltipHTML" />
          </template>
          <b-icon icon="info-circle" />
        </b-tooltip>
      </span>
    </template>
    <div>
      <div :class="field.hasAddonLeft || field.hasAddonRight ? 'field has-addons' : ''">
        <div v-if="field.hasAddonLeft" class="control">
          <span v-if="field.addonLeft" class="button is-static">
            {{ field.addonLeft }}
          </span>
          <slot v-else name="addonLeft" />
        </div>

        <b-switch
          v-if="field.type == 'switch'"
          :id="labelFor"
          :value="value"
          v-bind="field.inputAttrs"
          @input="$emit('input', $event)"
        >
          <span v-if="field.switchLabelHTML" v-html="field.switchLabelHTML" />
          <span v-else>
            {{ field.switchLabel }}
          </span>

          <b-tooltip v-if="field.tooltip || field.tooltipHTML" v-bind="tooltipAttrs">
            <template #content>
              <template v-if="field.tooltip">
                {{ field.tooltip }}
              </template>
              <span v-else v-html="field.tooltipHTML" />
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </b-switch>
        <template v-else-if="field.type == 'radio'">
          <b-radio
            v-for="(option, k) in field.options"
            :key="k"
            :class="{'mt-2': k !== 0}"
            :value="value"
            :native-value="option.value"
            v-bind="option.inputAttrs"
            @input="$emit('input', $event)"
          >
            <span v-if="option.radioLabelHTML" v-html="option.radioLabelHTML" />
            <span v-else>
              {{ option.radioLabel }}
            </span>

            <b-tooltip
              v-if="option.tooltip || option.tooltipHTML"
              v-bind="optionsTooltipAttrs[k]"
            >
              <template #content>
                <template v-if="option.tooltip">
                  {{ option.tooltip }}
                </template>
                <span v-else v-html="option.tooltipHTML" />
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </b-radio>
        </template>
        <b-select
          v-else-if="field.type == 'select'"
          :id="labelFor"
          :value="value"
          v-bind="field.inputAttrs"
          expanded
          @input="$emit('input', $event)"
        >
          <option v-for="(one, k) in field.options" :key="k" :value="field.optionLabelIsKey ? one : k">
            {{ field.optionLabelIsKey ? k : one }}
          </option>
        </b-select>
        <BaseMultipleSelect
          v-else-if="field.type == 'multiselect'"
          :value="value"
          v-bind="field.inputAttrs"
          :label="field.selectLabel"
          @input="$emit('input', $event)"
        >
          <BaseMultipleSelectOption
            v-for="(one, k) in field.options"
            :key="k"
            :value="field.optionLabelIsKey ? one : k">
            {{ field.optionLabelIsKey ? k : one }}
          </BaseMultipleSelectOption>
        </BaseMultipleSelect>
        <div v-else-if="field.type == 'datepicker'" class="field" :class="{'has-addons': value != null}">
          <b-datepicker
            :id="labelFor"
            :value="value"
            icon="calendar-alt"
            v-bind="field.inputAttrs"
            @input="$emit('input', $event)"
          />
          <p v-if="value" class="control">
            <b-button class="is-flat" title="effacer" icon-left="times" @click="$emit('input', null)" />
          </p>
        </div>
        <div v-else-if="field.type == 'datetimepicker'" class="field" :class="{'has-addons': value != null}">
          <b-datetimepicker
            :id="labelFor"
            :value="value"
            icon="calendar-alt"
            position="is-top-left"
            :datetime-formatter="(date) => $moment(date).format('DD/MM/YYYY, HH:mm')"
            :datepicker="{ nearbyMonthDays: false }"
            v-bind="field.inputAttrs"
            @input="$emit('input', $event)"
          />
          <p v-if="value" class="control">
            <b-button class="is-flat" title="effacer" icon-left="times" @click="$emit('input', null)" />
          </p>
        </div>
        <div v-else-if="field.type == 'upload'">
          <div v-if="value">
            <p
              v-if="field.isImg === false"
              class="p-2 bradius-4 has-background-light has-text-clipped border-dashed"
              :title="value.name"
            >
              {{ value.name }}
            </p>
            <figure v-else class="image bradius-8 has-background-light">
              <img :src="value" class="bradius-8">
            </figure>
            <b-button
              type="is-text has-text-danger"
              size="is-small"
              expanded
              @click.stop="$emit('input', null)"
            >
              supprimer
            </b-button>
          </div>
          <b-upload v-else expanded v-bind="field.inputAttrs" @input="$emit('input', $event)">
            <a class="button is-fullwidth" v-bind="field.uploadAttrs">
              <b-icon class="valign-middle mr-3" :icon="field.uploadIcon || 'file-upload'" /> Téléverser
            </a>
          </b-upload>
        </div>
        <TiptapEditor
          v-else-if="field.type == 'editor'"
          :value="value"
          v-bind="field.inputAttrs"
          @input="$emit('input', $event)"
        />
        <DetailedSelect
          v-else-if="field.type == 'detailedselect'"
          :value="value"
          :options="field.options"
          v-bind="field.inputAttrs"
          @input="$emit('input', $event)"
        />
        <b-input
          v-else-if="field.type == 'number'"
          ref="input"
          :id="labelFor"
          :value="value"
          :type="field.type"
          v-bind="field.inputAttrs"
          @input="$emit('input', $event ? Number($event) : $event)"
        />
        <b-taginput
          v-else-if="field.type == 'taginput'"
          ref="input"
          :id="labelFor"
          :value="value"
          v-bind="field.inputAttrs"
          @input="$emit('input', $event)"
        />
        <b-input
          v-else
          ref="input"
          :id="labelFor"
          :value="value"
          :type="field.type"
          v-bind="field.inputAttrs"
          @input="$emit('input', $event)"
        />

        <div v-if="field.hasAddonRight" class="control">
          <span v-if="field.addonRight" class="button is-static">
            {{ field.addonRight }}
          </span>
          <slot v-else name="addonRight" />
        </div>
      </div>
      <p v-if="field.help" class="help" :class="field.helpClass" v-html="field.help" />
      <p v-if="$slots.help">
        <slot name="help" />
      </p>
      <p v-for="(error, k) in errors" :key="k" class="help is-danger">
        {{ error }}
      </p>
      <div
        v-if="field.notification"
        class="notification mt-2"
        :class="field.notificationClass"
        v-html="field.notification"
      />
      <div
        v-if="$slots.notification"
        class="notification mt-2"
        :class="field.notificationClass"
      >
        <slot name="notification" />
      </div>
    </div>
  </b-field>
</template>
