import router from '@app/config/router';

router.beforeEach((to, from, next) => {
  const god_father = to.query.parrain || from.query.parrain;

  if (god_father) {
    localStorage.setItem('god_father', god_father);
  }

  next();
});
