export default {
  path: '/communaute',
  name: 'community',
  meta: {
    checkAuth: true,
    isResponsive: true,
    layout: 'Community',
  },
  component: () => import('@shared/pages/Community.vue'),
  children: [
    {
      path: '',
      meta: {
        checkAuth: true,
        isResponsive: true,
        layout: 'Community',
      },
      name: 'community',
      component: () => import('@shared/pages/community/Index.vue'),
    },
    {
      path: 'demarrage',
      meta: {
        checkAuth: true,
        isResponsive: true,
        layout: 'Community',
      },
      name: 'community_onboarding',
      component: () => import('@shared/pages/community/Onboarding.vue'),
    },
    {
      path: 'collection/:uuid',
      meta: {
        checkAuth: true,
        isResponsive: true,
        layout: 'Community',
      },
      name: 'community_collection',
      component: () => import('@shared/pages/community/Collection.vue'),
    },
    {
      path: 'espaces/:uuid',
      meta: {
        checkAuth: true,
        isResponsive: true,
        layout: 'Community',
      },
      component: () => import('@shared/pages/community/Group.vue'),
      name: 'community_group',
      children: [
        {
          path: 'discussions',
          meta: {
            checkAuth: true,
            isResponsive: true,
            layout: 'Community',
          },
          name: 'community_group_posts',
          component: () => import('@shared/pages/community/group/Posts.vue'),
        },
        {
          path: 'discussions/:postUUID',
          meta: {
            checkAuth: true,
            isResponsive: true,
            layout: 'Community',
          },
          name: 'community_group_post',
          component: () => import('@shared/pages/community/group/Post.vue'),
        },
        {
          path: 'membres',
          meta: {
            checkAuth: true,
            isResponsive: true,
            layout: 'Community',
          },
          name: 'community_group_members',
          component: () => import('@shared/pages/community/group/Members.vue'),
        },
        {
          path: 'informations',
          meta: {
            checkAuth: true,
            isResponsive: true,
            layout: 'Community',
          },
          name: 'community_group_information',
          component: () => import('@shared/pages/community/group/Information.vue'),
        },
        {
          path: 'signalements',
          meta: {
            checkAuth: true,
            isResponsive: true,
            layout: 'Community',
          },
          name: 'community_group_abuses',
          component: () => import('@shared/pages/community/group/Abuses.vue'),
        },
      ],
    },
  ],
};
