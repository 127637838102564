export const state = () => ({
  code: null,
  title: '',
  message: '',
});

export const mutations = {
  setError(state, { code, title, message }) {
    state.code = code;
    state.title = title;
    state.message = message;
  },
};
