import cloneDeep from 'lodash/cloneDeep';
import Draggable from 'vuedraggable';
import { setTimeoutPromise } from '@shared/services/Utils';
import TrainingForm from '@app/components/TrainingForm.vue';
import TrainingItemSectionForm from '@app/components/TrainingItemSectionForm.vue';
import ScormUploadForm from '@app/components/scorm/ScormUploadForm.vue';
import stripeMixin from '@app/mixins/stripe';

const AUTOSAVE_DURATION = 60000;

export default {
  mixins: [stripeMixin],
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
  methods: {
    addTraining() {
      this.$buefy.modal.open({
        parent: this,
        component: TrainingForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    addSection(parentId = null) {
      this.$buefy.modal.open({
        parent: this,
        component: TrainingItemSectionForm,
        props: { parentId },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    addScorm(parentId = null) {
      if (!this.$store.getters['auth/hasExpertPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({
            name: 'plan',
            query: { onglet: 'vous' },
          });
        }, null, { plan: 'EXPERT' });

        return;
      }

      this.$buefy.modal.open({
        parent: this,
        component: ScormUploadForm,
        props: { parentId },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    handleStripeError(error) {
      const code = error && error.response && error.response.data.code;
      const message = error && error.response && error.response.data.message;
      if (code === 'NO_STRIPE_TOKEN_DEFINED') {
        this.$buefy.dialog.confirm({
          message,
          confirmText: 'Configurer Stripe maintenant',
          focusOn: 'cancel',
          onConfirm: () => this.$router.push({ name: 'settings_payments' }),
        });
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};

export const trainingItemUtils = {
  currentTraining() {
    return this.$store.state.trainings.current;
  },
  methods: {
    addGeneric(parentId) {
      this.trainingItemDialog(
        'GENERIC',
        parentId,
        (uuid, id) => this.$router.push({
          name: 'training_uuid_editor',
          params: { uuid, id },
          query: { parentId },
        }),
      );
    },
    addQuiz(parentId) {
      this.trainingItemDialog(
        'QUIZ',
        parentId,
        (uuid, id) => this.$router.push({
          name: 'training_uuid_quiz',
          params: { uuid, id },
          query: { parentId },
        }),
      );
    },
    addTasks(parentId) {
      if (this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        });

        return;
      }

      this.trainingItemDialog(
        'TASKS',
        parentId,
        (uuid, id) => this.$router.push({
          name: 'training_uuid_tasks',
          params: { uuid, id },
          query: { parentId },
        }),
      );
    },
    addAssignment(parentId) {
      if (this.$store.getters['auth/isNotRecurringPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        });

        return;
      }

      this.trainingItemDialog(
        'ASSIGNMENT',
        parentId,
        (uuid, id) => this.$router.push({
          name: 'training_uuid_assignment',
          params: { uuid, id },
          query: { parentId },
        }),
      );
    },
    trainingItemDialog(type, parentId, redirect) {
      this.$buefy.dialog.prompt({
        title: this.$t(`training_items.name_type.${type}`),
        focusOn: 'cancel',
        closeOnConfirm: false,
        inputAttrs: { required: true, maxlength: 128 },
        confirmText: 'Créer',
        onConfirm: (value, { close }) => {
          const { uuid } = this.currentTraining;
          const trainingItem = {
            type,
            name: value,
            parent_id: parentId,
            draft: true,
          };

          const loader = this.$buefy.loading.open();
          this.$store.dispatch('trainings/addItem', { uuid, trainingItem })
            .then(({ id }) => {
              close();
              // FIX : for double scroll issue
              return setTimeoutPromise(() => redirect(uuid, id), 800);
            })
            .finally(() => loader.close());
        },
      });
    },
  },
};

export const editor = {
  components: { Draggable },
  data: () => ({
    isDirty: false,
    isLoading: false,
    initialLoading: false,
    activeTab: 0,
    trainingItem: {},
    showMinCompleteDuration: false,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
    hasExpertPlan() {
      return this.$store.getters['auth/hasExpertPlan'];
    },
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    pageURL() {
      const item = this.trainingItem;

      if (!item.id) {
        return null;
      }

      const url = this.$store.getters['auth/url'];
      const q = `?p=${this.$store.state.auth.previewToken}`;

      return `//${url}/mon-espace/formations/${this.currentTraining.slug}/elements/${item.id}${q}`;
    },
  },
  destroyed() {
    clearInterval(this.savetimer);
  },
  watch: {
    'trainingItem.id': {
      immediate: true,
      handler: 'setAutoUpdate',
    },
    'trainingItem.name': {
      handler() {
        this.isDirty = true;
      },
    },
    'trainingItem.draft': {
      handler() {
        this.isDirty = true;
      },
    },
    'trainingItem.description': {
      handler() {
        this.isDirty = true;
      },
    },
    'trainingItem.min_complete_duration': {
      handler() {
        this.isDirty = true;
      },
    },
    'trainingItem.specific_content': {
      deep: true,
      handler() {
        this.isDirty = true;
      },
    },
    isLoading(val) {
      if (val) {
        this.isDirty = false;
      }
    },
  },
  methods: {
    onViewDraft() {
      this.$buefy.dialog.confirm({
        message: this.$t('messages.alert_preview_training_item'),
        type: 'is-warning',
        confirmText: 'Ok ! Voir la page',
        focusOn: 'cancel',
        onConfirm: () => window.open(this.pageURL),
      });
    },
    setAutoUpdate(id) {
      clearInterval(this.savetimer);
      if (id && this.save) {
        this.savetimer = setInterval(() => {
          if (this.isDirty && (!this.$v || !this.$v.$invalid)) {
            this.save(true);
          }
        }, AUTOSAVE_DURATION);
      }
    },
    handleInitialLoading() {
      const { uuid } = this.$route.params;
      const { id: trainingItemId } = this.$route.params;
      const { parentId } = this.$route.query;

      if (!trainingItemId) {
        if (parentId) {
          this.trainingItem.parent_id = parentId;
        }

        return null;
      }

      this.initialLoading = true;
      return this.$store.dispatch('trainings/findItem', { uuid, trainingItemId })
        .catch(() => this.$errorHandlers.showPage(404, 'Cette page n\'existe pas'))
        .finally(() => (this.initialLoading = false));
    },
    handleBeforeRouteLeave(to, from, next) {
      if (this.isDirty) {
        clearInterval(this.savetimer);
        this.$buefy.dialog.confirm({
          message: 'Attention ! <br> Vous avez des changements qui ne sont pas enregistrés.',
          type: 'is-primary',
          confirmText: 'Rester ici !',
          cancelText: 'Quitter quand même',
          canCancel: ['button'],
          focusOn: 'cancel',
          onCancel: () => next(),
          onConfirm: () => {
            next(false);
            this.setAutoUpdate(this.trainingItem.id);
          },
        });

        return;
      }

      next();
    },
    onSetTrainingItem(data) {
      Object.assign(this, {
        trainingItem: data,
      });
    },
    setTrainingItem(data) {
      this.onSetTrainingItem(data);

      this.showMinCompleteDuration = this.trainingItem.min_complete_duration > 0;

      this.$nextTick(() => (this.isDirty = false));

      return data;
    },
  },
};

export const editorWithBlocks = {
  mixins: [editor],
  data: () => ({
    trainingItemContent: [],
  }),
  created() {
    const { uuid } = this.$route.params;
    const { id: trainingItemId } = this.$route.params;
    const { parentId } = this.$route.query;

    if (!trainingItemId) {
      if (parentId) {
        this.trainingItem.parent_id = parentId;
      }

      return;
    }

    this.initialLoading = true;
    this.$store.dispatch('trainings/findItem', { uuid, trainingItemId })
      .then(this.setTrainingItem)
      .catch(() => this.$errorHandlers.showPage(404, 'Cette page n\'existe pas'))
      .finally(() => (this.initialLoading = false))
      .then(() => this.$nextTick(() => this.focusName()));
  },
  mounted() {
    this.focusName();
  },
  computed: {
    contentBlocks: {
      get() {
        return this.trainingItemContent;
      },
      set(content) {
        this.trainingItemContent = content;
        this.isDirty = true;
      },
    },
  },
  methods: {
    focusName() {
      this.$refs.trainingItemName && this.$refs.trainingItemName.focus();
    },
    onResourceChange(resources) {
      const trainingItem = { ...this.trainingItem };
      trainingItem.resources = resources;
      trainingItem.resources_count = resources.length;
      this.$store.commit('trainings/updateItemInCurrent', trainingItem);
    },
    onResourceDelete(resource) {
      this.trainingItem.resources = this.trainingItem.resources || [];
      this.trainingItem.resources = this.trainingItem.resources.filter((v) => (
        v.id !== resource.id
      ));
      this.trainingItemContent = this.trainingItemContent.filter((v) => (
        v.type !== 'resource' || v.data.id !== resource.id
      ));
      this.trainingItem.resources_count = this.trainingItem.resources.length;
      const trainingItem = { ...this.trainingItem };
      this.$store.commit('trainings/updateItemInCurrent', trainingItem);
    },
    saveBlock(contentBlock, newContentData) {
      contentBlock = { ...contentBlock };
      contentBlock.data = newContentData;
      delete contentBlock.isNew;
      delete contentBlock.isEditing;

      this.trainingItemContent = this.trainingItemContent
        .map((v) => (v.id === contentBlock.id ? contentBlock : v));

      this.isDirty = true;
    },
    removeBlock(contentBlock) {
      this.trainingItemContent = this.trainingItemContent
        .filter((v) => v.id !== contentBlock.id);

      if (contentBlock.isNew) {
        return;
      }

      this.isDirty = true;
    },
    cancelBlock(contentBlock) {
      if (contentBlock.isNew) {
        this.trainingItemContent = this.trainingItemContent
          .filter((v) => v.id !== contentBlock.id);
      }

      contentBlock.isEditing = false;
    },
    editBlock(contentBlock) {
      this.isDirty = true;
      this.$set(contentBlock, 'isEditing', true);
      this.scrollToBlock(contentBlock);
    },
    moveBlockUp(contentBlock, index) {
      if (index > 0) {
        const finalIndex = index - 1;
        this.trainingItemContent = this.trainingItemContent.map((v, k) => {
          if (k === finalIndex) {
            return contentBlock;
          } if (k === index) {
            return this.trainingItemContent[finalIndex];
          }
          return v;
        });

        this.isDirty = true;
      }
    },
    moveBlockDown(contentBlock, index) {
      if (index < this.trainingItemContent.length - 1) {
        const finalIndex = index + 1;
        this.trainingItemContent = this.trainingItemContent.map((v, k) => {
          if (k === finalIndex) {
            return contentBlock;
          } if (k === index) {
            return this.trainingItemContent[finalIndex];
          }
          return v;
        });

        this.isDirty = true;
      }
    },
    addBlock(type, index) {
      const block = {
        type,
        id: this.getNewBlockId(),
        isNew: true,
        data: {},
      };

      if (index === undefined) {
        this.trainingItemContent.push(block);
      } else {
        this.trainingItemContent.splice(index, 0, block);
      }

      this.activeTab = 0;
      this.isDirty = true;
      this.$nextTick(() => this.scrollToBlock(block));
    },
    getNewBlockId() {
      let id;

      do {
        id = Math.random().toString().slice(2, 10);
      } while (this.trainingItemContent.some((v) => v.id === id));

      return id;
    },
    scrollToBlock(contentBlock) {
      const ref = this.$refs[`block${contentBlock.id}`];
      if (ref && ref[0] && ref[0].$el) {
        this.$scrollTo(ref[0].$el, {
          container: this.$refs.layout && this.$refs.layout.$refs.body,
        });
      }
    },
    updateTrainingItem(data, silent = false) {
      this.isLoading = true;

      return this.$store.dispatch('trainings/updateItem', {
        uuid: this.currentTraining.uuid,
        trainingItemId: this.trainingItem.id,
        trainingItem: data,
      })
        .then((data) => {
          this.trainingItem.resources = data.resources;
          this.trainingItem.resources_count = data.resources_count;
        })
        .then(() => (!silent && this.$showMessage.success()))
        .finally(() => {
          this.isLoading = false;
          this.isDirty = this.trainingItemContent
            .some((v) => v.isNew || v.isEditing);
        });
    },
    onSetTrainingItem(data) {
      Object.assign(this, {
        trainingItem: data,
        trainingItemContent: cloneDeep(data.content) || [],
      });
    },
    onSave(silent = false, process = () => {}) {
      const isEditing = this.trainingItemContent
        .some((v) => v.isNew || v.isEditing);

      if (!silent && isEditing) {
        this.$buefy.dialog.alert('Vous avez des modifications en cours. Veuillez les finaliser.');
        return;
      }

      if (!silent) {
        this.setAutoUpdate(this.trainingItem.id);
      }

      let trainingItem = { ...this.trainingItem };
      const content = JSON.stringify(
        this.trainingItemContent
          .filter((v) => !v.isNew)
          .map((v) => ({ ...v, isEditing: undefined })),
      );

      trainingItem.content = content;
      trainingItem = process(trainingItem);

      this.updateTrainingItem(trainingItem, silent);
    },
  },
};
