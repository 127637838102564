<script>
import { genericForms } from '@shared/mixins/forms';

export default {
  mixins: [genericForms],
  form: {
    name: {
      label: 'Titre',
      type: 'text',
      column: 'is-12 pb-0',
      inputAttrs: {
        required: true,
        maxlength: 128,
      },
    },
    draft: {
      switchLabel: 'Mettre en brouillon',
      type: 'switch',
      column: 'is-12 pt-0',
    },
    description: {
      label: 'Description',
      type: 'editor',
      column: 'is-12 pt-0',
      inputAttrs: {
        maxlength: 512,
      },
    },
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      trainingItem: {
        id: this.item.id,
        name: this.item.name,
        draft: this.item.draft,
        description: this.item.description,
        type: 'SECTION',
      },
    };
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
  methods: {
    action() {
      const { uuid } = this.currentTraining;
      const trainingItem = { ...this.trainingItem };
      const trainingItemId = this.trainingItem.id;

      this.isLoading = true;

      if (trainingItemId) {
        return this.$store
          .dispatch('trainings/updateItem', { uuid, trainingItemId, trainingItem })
          .then(() => this.$emit('close'));
      }

      return this.$store
        .dispatch('trainings/addItem', { uuid, trainingItem })
        .then(() => this.$emit('close'));
    },
  },
};
</script>

<template>
  <form class="modal-card" @submit.prevent="dataIsValid(isLoading) && handle()">
    <header class="modal-card-head">
      <h2 class="modal-card-title">
        <template v-if="trainingItem.id">
          Modifier le chapitre
        </template>
        <template v-else>
          Ajouter un chapitre
        </template>
      </h2>
      <button
        type="button"
        class="modal-close is-large"
        aria-label="Fermer"
        @click="$emit('close')"
      />
    </header>
    <div class="modal-card-body">
      <div class="columns is-multiline">
        <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
          <BaseField v-model="trainingItem[k]" :field="field" />
        </div>
      </div>
    </div>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <div class="buttons">
        <b-button type="is-text" @click="$emit('close')">
          Fermer
        </b-button>
        <b-button type="is-primary" native-type="submit" :loading="isLoading">
          Enregistrer
        </b-button>
      </div>
    </footer>
  </form>
</template>
