export default {
  methods: {
    handleStripeError(error) {
      const code = error && error.response && error.response.data.code;
      const message = error && error.response && error.response.data.message;
      if (code === 'NO_STRIPE_TOKEN_DEFINED') {
        this.$buefy.dialog.confirm({
          message,
          confirmText: 'Configurer Stripe maintenant',
          focusOn: 'cancel',
          onConfirm: () => this.$router.push({ name: 'settings_payments' }),
        });
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
