import DOMPurify from 'dompurify';
import { env } from '@shared/env.js';
import APICollection from '@shared/services/API/Community/Collection';
import APIGroup from '@shared/services/API/Community/Group';

export const state = () => ({
  isBooting: false,

  context: null,

  communityFormIsOpen: null,

  collectionFormEditedData: null,
  collectionFormIsOpen: false,
  collectionIsLoading: false,
  collections: [],

  groupFormEditedData: null,
  groupFormCollection: null,
  groupFormIsOpen: false,
  groupIsLoading: false,
  groups: [],

  currentGroup: null,
  currentGroupIsFetching: false,

  postFormEditedData: null,
  postFormIsOpen: false,
  currentGroupPost: null,
  currentGroupPosts: {
    data: [],
  },

  currentGroupComment: null,

  memberFormEditedData: null,
  memberFormIsOpen: false,
  currentGroupMembers: {
    data: [],
  },

  memberIsLoading: false,
});

export const mutations = {
  setState(state, payload) {
    Object.keys(payload)
      .forEach((key) => (state[key] = payload[key]));
  },
  resetGroup(state) {
    state.currentGroup = null;
    state.currentGroupPost = null;
    state.currentGroupPosts = {
      data: [],
    };
    state.currentGroupComment = null;
    state.currentGroupMembers = {
      data: [],
    };
  },
  showCommunityForm(state) {
    state.communityFormIsOpen = true;
  },
  closeCommunityForm(state) {
    state.communityFormIsOpen = false;
  },
  showCollectionForm(state, { collection } = {}) {
    state.collectionFormIsOpen = true;
    state.collectionFormEditedData = collection;
  },
  closeCollectionForm(state) {
    state.collectionFormIsOpen = false;
    state.collectionFormEditedData = null;
  },
  addCollection(state, payload) {
    state.collections.push(payload);
  },
  updateCollection(state, { uuid, data }) {
    state.collections = state.collections
      .map((v) => (v.uuid === uuid ? data : v))
      .sort((a, b) => (a.position - b.position));
  },
  deleteCollection(state, { uuid }) {
    state.collections = state.collections
      .filter((v) => v.uuid !== uuid)
      .sort((a, b) => (a.position - b.position));
    state.groups = state.groups
      .map((v) => {
        if (v.collection && v.collection.uuid === uuid) {
          delete v.collection;
          return v;
        }

        return v;
      });
  },
  showGroupForm(state, { collection, group } = {}) {
    state.groupFormIsOpen = true;
    state.groupFormEditedData = group;
    state.groupFormCollection = collection;
  },
  closeGroupForm(state) {
    state.groupFormIsOpen = false;
    state.groupFormEditedData = null;
    state.groupFormCollection = null;
  },
  addGroup(state, payload) {
    const group = {
      ...payload,
      is_accessible: true,
    };

    state.groups.push(group);
  },
  updateGroup(state, { uuid, data }) {
    data = {
      ...data,
      is_accessible: true,
    };

    state.groups = state.groups
      .map((v) => (v.uuid === uuid ? data : v));

    if (state.currentGroup?.uuid === uuid) {
      state.currentGroup = {
        ...state.currentGroup,
        ...data,
      };
    }
  },
  deleteGroup(state, { uuid }) {
    state.groups = state.groups
      .filter((v) => v.uuid !== uuid);
  },
  setGroupCounter(state, { key, count }) {
    if (!state.currentGroup) {
      return;
    }

    state.currentGroup[key] = count;
  },
  incrementGroupCounter(state, counterKey) {
    if (!state.currentGroup) {
      return;
    }

    state.currentGroup[counterKey] += 1;
  },
  decrementGroupCounter(state, counterKey) {
    if (!state.currentGroup) {
      return;
    }

    state.currentGroup[counterKey] -= 1;

    if (state.currentGroup[counterKey] < 0) {
      state.currentGroup[counterKey] = 0;
    }
  },
  showMemberForm(state, { member = null } = {}) {
    state.memberFormIsOpen = true;
    state.memberFormEditedData = member;
  },
  closeMemberForm(state) {
    state.memberFormIsOpen = false;
    state.memberFormEditedData = null;
  },

  showPostForm(state, { post = null } = {}) {
    state.postFormIsOpen = true;
    state.postFormEditedData = post;
  },
  closePostForm(state) {
    state.postFormIsOpen = false;
    state.postFormEditedData = null;
  },
  setPosts(state, { data, append = false }) {
    data.data = data.data
      .map((post) => {
        post.comments.data = post.comments.data.reverse();
        return post;
      });

    state.currentGroupPosts = append === false ? data : {
      ...data,
      data: [...state.currentGroupPosts.data, ...data.data],
    };
  },
  setPost(state, post) {
    if (state.currentGroup.type === 'CARD') {
      post.comments.data = post.comments.data.reverse();
    }

    state.currentGroupPost = post;
  },
  addPost(state, { data }) {
    state.currentGroupPosts.data.unshift(data);
    state.currentGroup.posts_count += 1;
  },
  updatePost(state, { data }) {
    if (state.currentGroupPost) {
      state.currentGroupPost = {
        ...state.currentGroupPost,
        ...data,
      };
    }

    state.currentGroupPosts.data = state.currentGroupPosts
      .data
      .map((v) => (v.uuid !== data.uuid ? v : {
        ...v,
        ...data,
      }));
  },
  deletePost(state, { uuid }) {
    state.currentGroupPosts.data = state.currentGroupPosts.data
      .filter((v) => v.uuid !== uuid);

    state.currentGroup.posts_count -= 1;
  },

  setComments(state, {
    postUUID, parentUUID, data, append = false,
  }) {
    const mapPost = (post) => {
      if (post.uuid === postUUID) {
        if (parentUUID) {
          post.comments.data = post.comments.data
            .map((comment) => {
              if (parentUUID !== comment.uuid) {
                return comment;
              }

              comment.children = append === false ? data : {
                ...data,
                data: [...comment.children.data, ...data.data],
              };

              console.log(comment, data);

              return comment;
            });

          return post;
        }

        post.comments = append === false ? data : {
          ...data,
          data: (
            state.currentGroup.type === 'CARD'
              ? [...data.data.reverse(), ...post.comments.data]
              : [...post.comments.data, ...data.data]
          ),
        };
      }

      return post;
    };

    if (state.currentGroupPost) {
      state.currentGroupPost = mapPost(state.currentGroupPost);
    }

    state.currentGroupPosts.data = state.currentGroupPosts
      .data
      .map(mapPost);
  },
  addComment(state, { postUUID, data }) {
    const mapPost = (post) => {
      if (post.uuid === postUUID) {
        if (data.parent) {
          post.comments.data = post.comments.data
            .map((comment) => {
              if (data.parent !== comment.uuid) {
                return comment;
              }

              comment.children.data.push(data);
              comment.children_count += 1;
              post.comments_count += 1;
              return comment;
            });

          return post;
        }

        post.comments.data.push(data);
        post.comments_count += 1;
      }

      return post;
    };

    if (state.currentGroupPost) {
      state.currentGroupPost = mapPost(state.currentGroupPost);
    }

    state.currentGroupPosts.data = state.currentGroupPosts
      .data
      .map(mapPost);
  },
  updateComment(state, {
    postUUID, parentUUID, commentUUID, data,
  }) {
    const mapPost = (post) => {
      if (post.uuid === postUUID) {
        post.comments.data = post.comments.data
          .map((comment) => {
            if (parentUUID && parentUUID === comment.uuid) {
              comment.children.data = comment.children.data
                .map((child) => {
                  if (child.uuid === commentUUID) {
                    return { ...child, ...data };
                  }

                  return child;
                });

              return comment;
            }

            if (comment.uuid === commentUUID) {
              return { ...comment, ...data };
            }

            return comment;
          });
      }

      return post;
    };

    if (state.currentGroupComment?.uuid === commentUUID) {
      state.currentGroupComment = {
        ...state.currentGroupComment,
        ...data,
      };
    }

    if (state.currentGroupPost) {
      state.currentGroupPost = mapPost(state.currentGroupPost);
    }

    state.currentGroupPosts.data = state.currentGroupPosts
      .data
      .map(mapPost);
  },
  deleteComment(state, { postUUID, parentUUID, commentUUID }) {
    const mapPost = (post) => {
      if (post.uuid === postUUID) {
        post.comments.data = post.comments.data
          .filter((comment) => {
            if (parentUUID && parentUUID === comment.uuid) {
              comment.children.data = comment.children.data
                .filter((child) => child.uuid !== commentUUID);

              comment.children_count = Math.max(
                0, (comment.children_count - 1),
              );

              post.comments_count = Math.max(
                0, (post.comments_count - 1),
              );

              return true;
            }

            const isKeept = comment.uuid !== commentUUID;

            if (!isKeept) {
              post.comments_count = Math.max(
                0, (post.comments_count - comment.children_count - 1),
              );
            }

            return isKeept;
          });
      }

      return post;
    };

    if (state.currentGroupPost) {
      state.currentGroupPost = mapPost(state.currentGroupPost);
    }

    state.currentGroupPosts.data = state.currentGroupPosts
      .data
      .map(mapPost);
  },

  setMembers(state, { data, append = false }) {
    if (append) {
      state.currentGroupMembers = {
        ...data,
        data: [...state.currentGroupMembers.data, ...data.data],
      };

      return;
    }

    state.currentGroupMembers = data;
  },
  updateMember(state, member) {
    if (state.currentGroup?.member) {
      state.currentGroup = {
        ...state.currentGroup,
        member,
      };
    }
  },
  removeMember(state, { uuid }) {
    state.currentGroupMembers.data = state.currentGroupMembers
      .data.filter((v) => v.uuid !== uuid);
  },
};

export const actions = {
  init({ dispatch, commit }) {
    commit('setState', { isBooting: true });

    return Promise.all([dispatch('getCollections'), dispatch('getGroups')])
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { isBooting: false }));
  },
  getCollections({ commit }) {
    commit('setState', { collectionIsLoading: true });

    return APICollection.getList()
      .then(({ data }) => {
        commit('setState', { collections: data });
        return data;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { collectionIsLoading: false }));
  },
  addCollection({ state, commit }, payload) {
    commit('setState', { collectionIsLoading: true });
    const collection = {
      ...payload,
      position: state.collections.length,
    };

    return APICollection.add(collection)
      .then(({ data }) => {
        commit('addCollection', data);
        return data;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { collectionIsLoading: false }));
  },
  reorderCollections({ state, commit }, payload) {
    const collectionsToUpdate = payload.map((v, i) => ({
      uuid: v.uuid,
      position: i,
    }));

    const oldCollections = [...state.collections];

    const collections = state.collections
      .map((collection) => {
        let position;

        const editedCollection = payload.find((v, i) => {
          position = i;
          return v.uuid === collection.uuid;
        });

        return editedCollection
          ? { ...editedCollection, position }
          : collection;
      })
      .sort((a, b) => (a.position - b.position));

    commit('setState', { collections });

    return APICollection.reorder(collectionsToUpdate)
      .catch((error) => {
        commit('setState', { collections: oldCollections });
        return Promise.reject(error);
      })
      .finally(() => commit('setState', { collectionIsLoading: false }));
  },
  updateCollection({ commit }, { uuid, data }) {
    commit('setState', { collectionIsLoading: true });

    return APICollection.update(uuid, data)
      .then(({ data: newData }) => {
        commit('updateCollection', { uuid, data: newData });
        return newData;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { collectionIsLoading: false }));
  },
  deleteCollectionDialog({ dispatch }, { vm, collection }) {
    vm.$buefy.dialog.confirm({
      type: 'is-danger',
      title: 'Action définitive',
      message: `
        Êtes-vous sûr(e) de vouloir supprimer la collection
        "${collection.name}" ? Les espaces qu'elle contient
        ne seront pas supprimés.
      `,
      focusOn: 'cancel',
      confirmText: 'Supprimer',
      closeOnConfirm: false,
      onConfirm: (_, { close }) => {
        const loader = vm.$buefy.loading.open();
        dispatch('deleteCollection', { uuid: collection.uuid })
          .then(() => {
            close();

            if (
              vm.$route.name === 'community_collection'
              && vm.$route.params.uuid === collection.uuid
            ) {
              vm.$router.push({ name: 'community' });
            }
          })
          .finally(() => loader.close());
      },
    });
  },
  deleteCollection({ commit }, { uuid }) {
    commit('setState', { collectionIsLoading: true });

    return APICollection.delete(uuid)
      .then(() => commit('deleteCollection', { uuid }))
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { collectionIsLoading: false }));
  },
  getGroups({ commit }) {
    commit('setState', { groupIsLoading: true });

    return APIGroup.getList()
      .then(({ data }) => {
        commit('setState', { groups: data });
        return data;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { groupIsLoading: false }));
  },
  addGroup({ state, commit }, payload) {
    commit('setState', { groupIsLoading: true });
    const collection = payload.collection?.uuid;
    const group = {
      ...payload,
      position: state.groups
        .filter((v) => (v.collection?.uuid === collection))
        .length,
    };

    return APIGroup.add(group)
      .then(({ data }) => {
        commit('addGroup', data);
        return data;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { groupIsLoading: false }));
  },
  reorderGroups({ state, commit }, payload) {
    const groupsToUpdate = payload.map((v, i) => ({
      uuid: v.uuid,
      position: i,
    }));

    const oldGroups = [...state.groups];

    const groups = state.groups
      .map((group) => {
        let position;

        const editedGroup = payload.find((v, i) => {
          position = i;
          return v.uuid === group.uuid;
        });

        return editedGroup
          ? { ...editedGroup, position }
          : group;
      });

    commit('setState', { groups });

    return APIGroup.reorder(groupsToUpdate)
      .catch((error) => {
        commit('setState', { groups: oldGroups });
        return Promise.reject(error);
      })
      .finally(() => commit('setState', { groupIsLoading: false }));
  },
  updateGroup({ commit }, { uuid, data }) {
    commit('setState', { groupIsLoading: true });

    return APIGroup.update(uuid, data)
      .then(({ data: newData }) => {
        commit('updateGroup', { uuid, data: newData });
        return newData;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { groupIsLoading: false }));
  },
  getGroup({ commit }, { uuid }) {
    commit('resetGroup');
    commit('setState', { currentGroupIsFetching: true });

    return APIGroup.get(uuid)
      .then(({ data }) => {
        commit('setState', { currentGroup: data });
        return data;
      })
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { currentGroupIsFetching: false }));
  },
  deleteGroup({ commit }, { uuid }) {
    commit('setState', { groupIsLoading: true });

    return APIGroup.delete(uuid)
      .then(() => commit('deleteGroup', { uuid }))
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { groupIsLoading: false }));
  },

  loadPosts({ state, commit }, { q, url = null, append = false }) {
    return APIGroup.getPosts(url || state.currentGroup.uuid, { q })
      .then((data) => {
        commit('setPosts', { data, append });

        if (data.meta?.total != null) {
          commit('setGroupCounter', {
            key: 'posts_count',
            count: data.meta.total,
          });
        }

        return data;
      });
  },
  getPost({ commit }, { uuid }) {
    return APIGroup.getPost(uuid)
      .then(({ data }) => {
        commit('setPost', data);
        return data;
      });
  },
  addPost({ commit }, { uuid, data }) {
    return APIGroup.addPost(uuid, data)
      .then(({ data }) => {
        commit('addPost', { data });
        return data;
      });
  },
  updatePost({ commit }, { uuid, data }) {
    return APIGroup.updatePost(uuid, data)
      .then(({ data }) => {
        commit('updatePost', { data });
        return data;
      });
  },
  deletePost({ commit }, { uuid }) {
    return APIGroup.deletePost(uuid)
      .then(() => commit('deletePost', { uuid }));
  },
  togglePinPost({ commit }, { post }) {
    return APIGroup.updatePost(post.uuid, { pinned: !post.pinned })
      .then(({ data }) => commit('updatePost', { data }));
  },
  toggleSubscriptionPost({ commit }, { post }) {
    const toggle = post.subscribed
      ? APIGroup.unsubscribePost.bind(APIGroup)
      : APIGroup.subscribePost.bind(APIGroup);

    const data = { ...post, subscribed: !post.subscribed };

    return toggle(post.uuid)
      .then(() => commit('updatePost', { data }));
  },
  toggleReadonlyPost({ commit }, { post }) {
    return APIGroup.updatePost(post.uuid, {
      settings: { readonly: !post.settings?.readonly },
    })
      .then(({ data }) => commit('updatePost', { data }));
  },

  getComment({ commit }, { uuid }) {
    return APIGroup.getComment(uuid)
      .then(({ data }) => {
        commit('setState', { currentGroupComment: data });
        return data;
      });
  },
  addComment({ commit }, { postUUID, data }) {
    const { parent } = data;
    return APIGroup.addComment(postUUID, data)
      .then(({ data }) => {
        data.parent = parent;
        commit('addComment', { postUUID, data });
        return data;
      });
  },
  updateComment({ commit }, {
    postUUID, parentUUID, commentUUID, data,
  }) {
    return APIGroup.updateComment(commentUUID, data)
      .then(({ data }) => {
        commit('updateComment', {
          postUUID,
          parentUUID,
          commentUUID,
          data,
        });
        return data;
      });
  },
  deleteComment({ commit }, { postUUID, parentUUID, commentUUID }) {
    return APIGroup.deleteComment(commentUUID)
      .then(() => commit('deleteComment', {
        postUUID, parentUUID, commentUUID,
      }));
  },
  loadComments({ commit }, {
    postUUID, parentUUID, url = null, append = false,
  }) {
    return APIGroup.getComments(url || postUUID)
      .then((data) => {
        commit('setComments', {
          postUUID, parentUUID, data, append,
        });

        return data;
      });
  },
  toggleSubscriptionComment({ commit }, { postUUID, parentUUID, comment }) {
    const toggle = comment.subscribed
      ? APIGroup.unsubscribeComment.bind(APIGroup)
      : APIGroup.subscribeComment.bind(APIGroup);

    const data = { ...comment, subscribed: !comment.subscribed };

    return toggle(comment.uuid)
      .then(() => commit('updateComment', {
        postUUID,
        parentUUID,
        commentUUID: comment.uuid,
        data,
      }));
  },
  toggleReadonlyComment({ commit }, { postUUID, parentUUID, comment }) {
    return APIGroup.updateComment(comment.uuid, {
      settings: { readonly: !comment.settings?.readonly },
    })
      .then(({ data }) => commit('updateComment', {
        postUUID,
        parentUUID,
        commentUUID: comment.uuid,
        data,
      }));
  },

  loadMembers({ state, commit }, { q, url = null, append = false }) {
    return APIGroup.getMembers(url || state.currentGroup.uuid, { q })
      .then((data) => {
        commit('setMembers', { data, append });

        if (data.meta?.total != null) {
          commit('setGroupCounter', {
            key: 'members_count',
            count: data.meta.total,
          });
        }

        return data;
      });
  },
  addMember({ state, commit }, { member }) {
    return APIGroup.addMember(state.currentGroup.uuid, {
      [member.type.toLowerCase()]: member.type_uuid,
    })
      .then(({ data: newMember }) => {
        commit('setMembers', {
          append: true,
          data: {
            data: [newMember],
          },
        });
        commit('incrementGroupCounter', 'members_count');
      });
  },
  removeMember({ commit }, { uuid }) {
    return APIGroup.deleteMember(uuid)
      .then(() => {
        commit('removeMember', { uuid });
        commit('decrementGroupCounter', 'members_count');
      });
  },
  removeMembers({ state, commit }) {
    return APIGroup.deleteMembers(state.currentGroup.uuid)
      .then(() => {
        const data = state.currentGroupMembers
          .data
          .filter((v) => v.role === 'OWNER');

        commit('setMembers', { data: { data } });
        commit('setGroupCounter', {
          key: 'members_count',
          count: 1,
        });
      });
  },
  updateMember({ state, commit }, member) {
    commit('setState', { memberIsLoading: true });
    return APIGroup.updateMember(
      state.currentGroup.member.uuid,
      member,
    )
      .then(({ data: member }) => commit('updateMember', member))
      .catch((error) => Promise.reject(error))
      .finally(() => commit('setState', { memberIsLoading: false }));
  },
};

export const getters = {
  isUser(state) {
    return state.context === 'USER';
  },

  isCustomer(state) {
    return state.context === 'CUSTOMER';
  },

  communityUser(state, getters, rootState, rootGetters) {
    if (state.context === 'USER') {
      return rootGetters['auth/user'];
    }

    if (state.context === 'CUSTOMER') {
      return rootGetters['auth/customer'];
    }

    return null;
  },

  communityStore(state, getters, rootState, rootGetters) {
    if (state.context === 'USER') {
      return rootGetters['auth/store'];
    }

    if (state.context === 'CUSTOMER') {
      return rootState.store.data;
    }

    return null;
  },

  userDisplayName(state, getters) {
    const user = getters.communityUser;
    return user && (`${user.display_name || user.firstname}`);
  },
  userAvatar(state, getters) {
    const user = getters.communityUser;

    if (user && user.picture) {
      return user.picture.startsWith('http')
        ? user.picture
        : `${env.apiResourcesURL}/${user.picture}`;
    }

    const index = getters.userDisplayName[0]
      .toLowerCase().charCodeAt(0) % 24;

    // eslint-disable-next-line
    return `${env.apiDomain}/img/avatars/${index + 1}.png`;
  },
  standaloneGroups(state) {
    return state.groups
      .filter((v) => !v.collection)
      .sort((a, b) => (a.position - b.position));
  },
  collectionsWithGroups(state) {
    return state.collections.reduce((prev, curr) => {
      prev[curr.uuid] = {
        ...curr,
        groups: (
          state.groups
            .filter((v) => (
              v.collection && v.collection.uuid === curr.uuid
            ))
            .sort((a, b) => (a.position - b.position))
        ),
      };

      return prev;
    }, {});
  },
  currentGroupDescription(state) {
    if (!state.currentGroup) {
      return null;
    }

    return DOMPurify.sanitize(state.currentGroup.description);
  },
};
