import { contextRoot } from '@shared/env';
import { NotificationProgrammatic } from 'buefy';

export default ({ router, store }) => {
  router.beforeEach((to, from, next) => {
    const storeData = contextRoot === '@app'
      ? store.getters['auth/store']
      : store.state.store.data;

    if (
      to.meta.layout === 'Community'
      && !storeData.community_options.enabled
    ) {
      if (contextRoot === '@app') {
        next({ name: 'home' });
      } else {
        next({ name: 'dashboard' });
      }

      NotificationProgrammatic.open({
        message: 'Vous n\'avez pas accès à cette page !',
        type: 'is-warning',
      });

      return;
    }

    if (to.name === 'community_group') {
      next({
        name: 'community_group_posts',
        params: { ...to.params },
        query: { ...to.query },
        hash: to.hash,
      });
      return;
    }

    next();
  });
};
