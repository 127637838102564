<script>
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    group: {
      type: Object,
      default: null,
    },
    collections: {
      type: Array,
      default: null,
    },
    collection: {
      type: Object,
      default: null,
    },
  },
  data() {
    const newGroup = {
      name: '',
      description: '',
      collection: this.collection?.uuid,
      type: 'CARD',
      state: 'DRAFT',
      global: this.getSharedStoreGetter('hasProPlan'),
      automaticAdd: this.getSharedStoreGetter('hasProPlan')
        ? this.$constants.GROUP_AUTO_ADD.ALL
        : this.$constants.GROUP_AUTO_ADD.MANUAL,
      trainings: [],
      settings: {
        readonly: false,
        show_members: true,
        default_post_sort: 'PUBLISHED_DESC',
      },
    };

    if (this.group) {
      let automaticAdd;

      if (this.group.global) {
        automaticAdd = this.$constants.GROUP_AUTO_ADD.ALL;
      } else if (this.group.trainings.length > 0) {
        automaticAdd = this.$constants.GROUP_AUTO_ADD.TRAININGS;
      } else {
        automaticAdd = this.$constants.GROUP_AUTO_ADD.MANUAL;
      }

      return {
        newGroup: {
          ...pick(cloneDeep(this.group), Object.keys(newGroup)),
          automaticAdd,
          collection: this.group.collection?.uuid,
        },
      };
    }

    return {
      newGroup,
    };
  },
  computed: {
    ...mapState('community', ['groupIsLoading']),
    form() {
      const collection = this.collections ? {
        collection: {
          label: 'Rattacher à une collection',
          type: 'select',
          column: 'is-6',
          optionLabelIsKey: true,
          options: {
            'Aucune collection': null,
            ...this.collections.reduce((prev, curr) => {
              prev[curr.name] = curr.uuid;
              return prev;
            }, {}),
          },
        },
      } : {};

      const type = this.group ? {} : {
        type: {
          label: 'Mode d\'affichage',
          tooltip: 'Attention, le mode d\'affichage n\'est pas modifiable une fois l\'espace créé',
          tooltipAttrs: { multilined: true, position: 'is-left' },
          type: 'detailedselect',
          column: 'is-12',
          inputAttrs: {
            required: true,
          },
          options: [
            {
              value: 'CARD',
              label: 'Fil d\'actualité',
              details: `
                Le fil d'actualité est un mode d'affichage
                par publications (texte ou multimédia) et réponses.
              `,
            },
            {
              value: 'LIST',
              label: 'Forum (pack EXPERT)',
              detailsHTML: `
                Le forum est un mode d'affichage par thématiques,
                seuls les titres des sujets seront affichés et
                les membres accèderont aux contenus et aux réponses en
                cliquant sur le titre du sujet qui les intéresse.
              `,
            },
            // {
            //   value: 'GRID',
            //   label: 'Galerie (pack EXPERT)',
            //   details: `
            //     La galerie est un mode d'affichage par carte visuelle :
            //     l'image et le titre de la publication seront affichés tandis
            //     que les autres contenus et réponses seront accessibles au clic sur l'image.
            //   `,
            // },
          ],
        },
      };

      const trainings = this.trainingsAsOptions ? {
        trainings: {
          label: 'Ajout automatique des nouveaux apprenants de la / des formation(s) suivante(s) :',
          selectLabel: 'Choisissez...',
          type: 'multiselect',
          column: 'is-12',
          options: this.trainingsAsOptions,
          inputAttrs: {
            floating: true,
          },
        },
      } : {};

      return {
        name: {
          label: 'Nom de l\'espace',
          type: 'text',
          column: 'is-6',
          inputAttrs: {
            required: true,
            maxlength: 128,
            hasCounter: false,
          },
        },
        ...collection,
        state: {
          label: 'Statut',
          type: 'detailedselect',
          column: 'is-12',
          inputAttrs: {
            required: true,
          },
          options: [
            {
              value: 'PRIVATE',
              label: 'Caché (pack EXPERT)',
              details: `
                Votre espace n'est pas visible dans le menu,
                sauf pour les apprenants que vous inviterez et qui auront donc accès à son contenu.
              `,
            },
            {
              value: 'VISIBLE',
              label: 'Publié',
              detailsHTML: `
                  Votre espace apparaît dans le menu pour tous vos apprenants.
                  Mais seuls les apprenants que vous ajouterez à cet espace auront accès à son contenu.
              `,
            },
            {
              value: 'DRAFT',
              label: 'Brouillon',
              details: `
                Votre espace n'est pas visible et personne ne peut encore y accéder.
                Seuls les administrateurs de l'équipe et vous peuvent le voir et le modifier.
              `,
            },
          ],
        },
        ...type,
        automaticAdd: {
          type: 'radio',
          column: 'is-12',
          options: [
            {
              value: this.$constants.GROUP_AUTO_ADD.ALL,
              radioLabel: 'Ajout automatique de tous les nouveaux apprenants',
              tooltip: `
                Chaque nouvel apprenant inscrit sur l'une de vos formations
                sera automatiquement ajouté à cet espace
              `,
              tooltipAttrs: { multilined: true, position: 'is-left' },
            },
            {
              value: this.$constants.GROUP_AUTO_ADD.TRAININGS,
              radioLabel: 'Ajout automatique de certains nouveaux apprenants (pack EXPERT)',
              tooltip: `
                Chaque nouvel apprenant inscrit dans la ou les formations
                sélectionnée(s) sera automatiquement ajouté à cet espace
              `,
              tooltipAttrs: { multilined: true, position: 'is-left' },
            },
            {
              value: this.$constants.GROUP_AUTO_ADD.MANUAL,
              radioLabel: 'Ajout manuel des apprenants (pack EXPERT)',
              tooltip: `
                Il n'y aura pas d'ajout automatique à cet espace,
                vous pourrez ajouter vos apprenants manuellement
              `,
              tooltipAttrs: { multilined: true, position: 'is-left' },
            },
          ],
        },
        ...trainings,
        'settings.default_post_sort': {
          label: 'Tri par défaut des publications',
          type: 'select',
          column: 'is-12',
          options: {
            PUBLISHED_ASC: 'Par date de publication (croissant)',
            PUBLISHED_DESC: 'Par date de publication (décroissant)',
            LAST_COMMENTED: 'Par date de dernière réponse',
          },
        },
        'settings.readonly': {
          type: 'switch',
          switchLabel: 'Espace en lecture seule',
          tooltipHTML: `
            (pack EXPERT)
            <br>
            Seuls le créateur de l'espace et les administrateurs
            de l'équipe peuvent publier. Tous les membres peuvent répondre
          `,
          tooltipAttrs: { multilined: true, position: 'is-right' },
          column: 'is-6',
        },
        'settings.show_members': {
          switchLabel: 'Afficher les membres',
          tooltip: `
            Seuls le créateur de l'espace et les administrateurs
            de l'équipe peuvent voir la liste des membres
          `,
          tooltipAttrs: { multilined: true, position: 'is-left' },
          type: 'switch',
          column: 'is-6',
        },
        description: {
          label: 'Description de l\'espace',
          type: 'editor',
          column: 'is-12',
          inputAttrs: {
            height: 160,
            placeholder: 'Rédigez ici les informations importantes de cet espace : objectif, règles d\'utilisation...',
          },
          errors: [
            { name: 'maxLength', args: [5000] },
          ],
        },
      };
    },
    baseFormFields() {
      return pick(this.form, [
        'name', 'collection', 'state', 'type',
      ]);
    },
    descriptionFormFields() {
      return pick(this.form, [
        'description',
      ]);
    },
    accessFormFields() {
      return pick(this.form, [
        'automaticAdd', 'trainings',
      ]);
    },
    advancedFormFields() {
      return pick(this.form, [
        'settings.default_post_sort',
        'settings.readonly',
        'settings.show_members',
      ]);
    },
    trainingsAsOptions() {
      const trainings = this.$store.state.trainings.list;

      if (!trainings) {
        return null;
      }

      return trainings.data.reduce((prev, curr) => {
        prev[curr.uuid] = curr.name;
        return prev;
      }, {});
    },
  },
  validations: {
    newGroup: {
      description: {
        maxLength: maxLength(5000),
      },
    },
  },
  methods: {
    handle() {
      let promise;
      const data = { ...this.newGroup };
      const { GROUP_AUTO_ADD } = this.$constants;

      if (data.automaticAdd === GROUP_AUTO_ADD.ALL) {
        data.global = true;
        data.trainings = [];
      } else if (data.automaticAdd === GROUP_AUTO_ADD.TRAININGS) {
        data.global = false;
      } else {
        data.global = false;
        data.trainings = [];
      }

      delete data.automaticAdd;

      if (this.group) {
        promise = this.$store.dispatch('community/updateGroup', {
          uuid: this.group.uuid,
          data,
        });
      } else {
        promise = this.$store.dispatch('community/addGroup', data);
      }

      promise.then((data) => {
        this.$emit('done');
        this.$showMessage.success();

        if (!this.group) {
          this.$router.push({
            name: 'community_group',
            params: { uuid: data.uuid },
          });
        }
      });
    },
  },
  mounted() {
    // only in user context
    const loader = this.$buefy.loading.open({ container: this.$el });
    this.$store.dispatch('trainings/fetch')
      .finally(() => loader.close());
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    class="is-relative"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(groupIsLoading) && handle()"
  >
    <template #title>
      <template v-if="group">
        Modifier l'espace
      </template>
      <template v-else>
        Créer un espace
      </template>
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in baseFormFields"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newGroup"
            :formValidation="$v.newGroup"
          />
        </div>
      </div>

      <AppToggleSection :disabledScroll="true">
        <template #title>
          À propos
        </template>
        <template #default>
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in descriptionFormFields"
              :key="k"
              class="column mb-0"
              :class="field.column"
            >
              <GeneratorField
                :fieldName="k"
                :fieldDefinition="field"
                :formData.sync="newGroup"
                :formValidation="$v.newGroup"
              />
            </div>
          </div>
        </template>
      </AppToggleSection>

      <AppToggleSection :disabledScroll="true">
        <template #title>
          Accès à l'espace
        </template>
        <template #default>
          <div class="columns is-multiline">
            <template v-for="(field, k) in accessFormFields">
              <div
                v-if="
                  k !== 'trainings'
                  || newGroup.automaticAdd === 'TRAININGS'
                "
                :key="k"
                class="column mb-0"
                :class="field.column"
              >
                <GeneratorField
                  :fieldName="k"
                  :fieldDefinition="field"
                  :formData.sync="newGroup"
                  :formValidation="$v.newGroup"
                />
              </div>
            </template>
          </div>
        </template>
      </AppToggleSection>

      <AppToggleSection :disabledScroll="true">
        <template #title>
          Paramètres avancés
        </template>
        <template #default>
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in advancedFormFields"
              :key="k"
              class="column mb-0"
              :class="field.column"
            >
              <GeneratorField
                :fieldName="k"
                :fieldDefinition="field"
                :formData.sync="newGroup"
                :formValidation="$v.newGroup"
              />
            </div>
          </div>
        </template>
      </AppToggleSection>

      <div class="buttons is-right mt-10">
        <b-button type="is-text" @click="$emit('cancel')">
          Annuler
        </b-button>
        <b-button
          type="is-primary is-custom"
          native-type="submit"
          :loading="groupIsLoading"
        >
          <template v-if="group">
            Modifier
          </template>
          <template v-else>
            Créer
          </template>
        </b-button>
      </div>
    </template>
  </AppCardModal>
</template>
