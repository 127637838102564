// import _get from 'lodash/get';
// import { env } from '@shared/env';
import APIStore from '@app/services/API/Store';

export const state = () => ({
  isLoading: false,
  current: null,
});

export const mutations = {
  setState(state, payload) {
    Object.keys(payload)
      .forEach((key) => (state[key] = payload[key]));
  },
  setCurrent(state, payload) {
    state.current = payload;
  },
  updateCustomerTrainingsOnCustomer(state, customer) {
    if (!state.current) {
      return;
    }

    customer.customer_trainings
      .forEach((newValue) => {
        state.current.customer_trainings = state.current
          .customer_trainings
          .map((oldValue) => {
            if (newValue.uuid === oldValue.uuid) {
              return {
                ...oldValue,
                customer_type: newValue.customer_type,
                blocked_reason: newValue.blocked_reason,
                bypass_drip: newValue.bypass_drip,
              };
            }

            return oldValue;
          });
      });
  },
  addTags(state, tags) {
    if (!state.current) {
      return;
    }

    tags.forEach((tag) => {
      if (!state.current.crm_tags.includes(tag)) {
        state.current.crm_tags.push(tag);
      }
    });
  },
  removeTags(state, tags) {
    if (!state.current) {
      return;
    }

    state.current.crm_tags = state
      .current
      .crm_tags
      .filter((tag) => !tags.includes(tag));
  },
  addHistoryNote(state, note) {
    if (!state.current) {
      return;
    }

    state.current.crm_notes.HISTORY.push(note);
  },
  removeHistoryNote(state, noteToRemove) {
    if (!state.current) {
      return;
    }

    state.current.crm_notes.HISTORY = state
      .current
      .crm_notes
      .HISTORY
      .filter((note) => note.id !== noteToRemove.id);
  },
  setGeneralNote(state, note) {
    if (!state.current) {
      return;
    }

    state.current.crm_notes.GENERAL = note;
  },
  setPrivateData(state, crmPrivateData) {
    if (!state.current) {
      return;
    }

    state.current.crm_private_data = crmPrivateData;
  },
};

export const actions = {
  find({ commit }, { storeUUID, customerUUID }) {
    return APIStore.getCustomer(storeUUID, customerUUID)
      .then(({ data }) => {
        commit('setCurrent', data);
        return data;
      });
  },
  update({ commit }, { storeUUID, customerUUID, data }) {
    return APIStore.updateCustomer(storeUUID, customerUUID, data)
      .then(({ data }) => {
        commit('setCurrent', data);
        return data;
      });
  },
};

export const getters = {};
