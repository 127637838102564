import Vue from 'vue';
import MFLayout from '@shared/plugins/MFLayout';
import router from '@app/config/router';
import Default from '@app/layouts/Default.vue';
import Community from '@app/layouts/Community.vue';
import Blank from '@app/layouts/Blank.vue';

Vue.use(MFLayout, {
  router,
  layouts: { Default, Community, Blank },
  defaultLayout: 'Default',
});
