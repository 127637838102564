import Vue from 'vue';
import get from 'lodash/get';
import { NotificationProgrammatic, ToastProgrammatic, DialogProgrammatic } from 'buefy';
import ErrorMessages from '@shared/messages/errors.json';

export const success = () => {
  ToastProgrammatic.open({ message: 'Enregistré !', type: 'is-success' });
};

export const sent = () => {
  ToastProgrammatic.open({ message: 'Envoyé !', type: 'is-success' });
};

export default ({
  router, store, i18n, loginRoute,
}) => {
  const errorHandlers = {
    axios(error) {
      console.log('axios', { ...error });

      if (!error.isAxiosError) {
        errorHandlers.default(error);
        return Promise.resolve();
      }

      const status = get(error, 'response.status', null);

      if (status === 422) {
        errorHandlers.apiValidationErrors(error);
      } else if (status === 401) {
        NotificationProgrammatic.open({
          message: get(error, 'response.data.message', ErrorMessages[401]),
          type: 'is-danger',
          position: 'is-top',
        });
        return router.push(loginRoute);
      } else if ([307, 503].includes(status)) {
        NotificationProgrammatic.open({
          message: get(error, 'response.data.message', ErrorMessages[307]),
          type: 'is-danger',
          position: 'is-top',
        });
      } else if ([409, 500].includes(status)) {
        NotificationProgrammatic.open({
          message: get(error, 'response.data.message', ErrorMessages[status]),
          type: 'is-danger',
          position: 'is-top',
        });
      } else if (![null, undefined].includes(status)) {
        NotificationProgrammatic.open({
          message: get(error, 'response.data.message', ErrorMessages[500]),
          type: 'is-danger',
          position: 'is-top',
        });
      } else {
        errorHandlers.stack(error);
      }

      return Promise.resolve();
    },
    apiValidationErrors(error) {
      const { errors } = error.response.data;
      errorHandlers.default({
        message: Object.keys(errors).map((field) => errors[field].join(', ')).join(', '),
      });
    },
    default(error) {
      if (error._isRouter || error.name === 'NavigationDuplicated') {
        return;
      }

      if (error.name === 'PrivacyError') {
        return;
      }

      const message = (error && error.message) || error;

      NotificationProgrammatic.open({
        message: typeof message !== 'string' ? message : (ErrorMessages[message] || message),
        type: 'is-danger',
        position: 'is-top',
      });
    },
    stack(error) {
      console.log('stack');
      console.error(error);
    },
    showPage(code, message) {
      console.log(code, message);
      store.commit('errors/setError', { code, message });
    },
    showNetworkErrorDialog() {
      DialogProgrammatic.alert({
        type: 'is-danger',
        message: `
          Une coupure de votre connexion internet a eu lieu,
          merci de rafraîchir la page.
        `,
        confirmText: 'Rafraîchir',
        onConfirm() {
          window.location.reload();
        },
      });
    },
  };

  router.afterEach(() => {
    store.state.errors.code && store.commit('errors/setError', {
      code: null,
      message: '',
    });
  });

  window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault();
    const detail = event.detail || event;
    console.log('unhandledrejection', { ...detail });
    const error = detail.reason || detail;
    errorHandlers.axios(error);
  });

  Vue.config.errorHandler = (error) => {
    console.log('errorHandler');
    errorHandlers.stack(error);
  };

  Vue.prototype.$showMessage = {
    sent,
    success,
    error(message) {
      NotificationProgrammatic.open({
        message,
        type: 'is-danger',
        position: 'is-top',
      });
    },
    goPro(onConfirm, onCancel, options = { plan: 'PRO' }) {
      const storePlan = i18n.t(`packs.${store.getters['auth/store'].plan}`);
      DialogProgrammatic.confirm({
        message: `
          <div class="content">
            <p>
              Votre offre actuelle (${storePlan})
              ne vous permet pas d'avoir accès à cette fonctionnalité.
            </p>

            <p>
              Activez-la en souscrivant au ${i18n.t(`packs.${options.plan}`)} ou supérieur.
            </p>
          </div>
        `,
        type: 'is-warning',
        hasIcon: true,
        icon: 'gem',
        confirmText: 'Activer cette fonctionnalité',
        onConfirm: onConfirm || (() => {
          router.push({ name: 'plan', query: { onglet: 'vous' } });
        }),
        onCancel: onCancel || undefined,
      });
    },
  };

  Vue.prototype.$errorHandlers = errorHandlers;

  return errorHandlers;
};
