<script>
import AlertMessageEmailValidation from '@shared/components/AlertMessageEmailValidation.vue';

import APIUser from '@app/services/API/User';
import AlertMessageGoPro from '@app/components/AlertMessageGoPro.vue';
import AlertStoreDiscount from '@app/components/AlertStoreDiscount.vue';

export default {
  components: {
    AlertMessageGoPro,
    AlertStoreDiscount,
    AlertMessageEmailValidation,
  },
  data: () => ({ APIUser }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    canShowGoPro() {
      return (
        !this.canShowStoreDiscount
        && !this.$store.getters['auth/hasToActivate']
        && this.$store.getters['auth/hasPercentPlan']
        && this.$store.getters['auth/permissions'].canManagePlans
      );
    },
    canShowStoreDiscount() {
      const discountStartDate = this.$moment.utc(this.authUser.created_at)
        .local()
        .add(4, 'days');
      const discountEndDate = this.$moment.utc(this.authUser.created_at)
        .local()
        .add(7, 'days');

      return (
        this.$store.getters['auth/permissions'].canManagePlans
        && this.$store.getters['auth/hasPercentPlan']
        && this.$moment().isBetween(discountStartDate, discountEndDate)
      );
    },
  },
};
</script>

<template>
  <div>
    <AlertMessageEmailValidation
      v-if="$store.getters['auth/hasToActivate']"
      :api="APIUser"
      :email="$store.getters['auth/user'].email"
    />
    <AlertMessageGoPro
      v-if="canShowGoPro"
      class="mb-5"
    />
    <AlertStoreDiscount
      v-if="canShowStoreDiscount"
      class="mb-5"
    />
  </div>
</template>
