import APIAffiliate from '@app/services/API/Affiliate';

export const state = () => ({
  list: {
    data: [],
  },
});

export const mutations = {
  setState(state, payload) {
    Object.keys(payload)
      .forEach((key) => (state[key] = payload[key]));
  },
  addToList(state, payload) {
    state.list.data.unshift(payload);
  },
};

export const actions = {
  getList({ commit }, { url, storeUUID, params }) {
    return APIAffiliate.getAffiliates({ url, storeUUID }, params)
      .then((data) => {
        commit('setState', { list: data });
        return data;
      });
  },
};

export const getters = {};
