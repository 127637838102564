<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    disabledScroll: {
      type: Boolean,
      default: false,
    },
    hasSep: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    scroll() {
      if (this.disabledScroll) {
        return;
      }

      this.$scrollTo(this.$refs.section, { duration: 200 });
    },
  },
};
</script>

<template>
  <section ref="section" class="mb-5">
    <b-collapse
      :open="isOpen"
      @open="$emit('open')"
      @close="$emit('close')"
    >
      <template #trigger="{ open }">
        <h2 class="title is-4 is-custom is-size-5-mobile mb-5">
          <a
            class="color-inherit is-flex is-align-items-center"
            href="#"
            @click.prevent="!open && scroll()"
          >
            <b-icon
              class="mr-3"
              :icon="open ? 'chevron-down' : 'chevron-right'"
              :title="open ? 'Replier' : 'Déplier'"
            />
            <slot name="title" />
          </a>
        </h2>
      </template>

      <template #default>
        <slot />
      </template>
    </b-collapse>
    <hr v-if="hasSep" class="has-background-grey">
  </section>
</template>
