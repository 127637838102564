<script>
export default {
  props: {
    notif: {
      type: Object,
      required: true,
    },
    notifIcon: {
      type: Object,
      required: true,
    },
  },
  computed: {
    to() {
      const notifRoute = this.notifIcon.to && this.notifIcon.to(this.notif, {
        store: this.$store,
      });
      return notifRoute || this.$route;
    },
  },
};
</script>

<template>
  <router-link class="box p-3" :to="to" @click.native="$emit('chose', notif)">
    <article class="media">
      <figure class="media-left">
        <p class="image is-32x32 is-icon bradius-full has-background-info has-text-white">
          <b-icon
            v-if="notifIcon"
            :pack="notifIcon.pack"
            :icon="notifIcon.icon"
          />
          <b-icon v-else icon="dot-circle" />
        </p>
      </figure>
      <div class="media-content content is-small">
        <p>
          <span v-html="notif.text"></span><br>
          <small class="has-text-info">
            {{ notif.created_at | momentFromUTC | moment('from') }}
          </small>
        </p>
      </div>
      <figure v-if="!notif.read_at" class="media-right">
        <b-icon type="is-danger" icon="circle" />
      </figure>
    </article>
  </router-link>
</template>
