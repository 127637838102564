import 'vue-plyr/dist/vue-plyr.css';
import Vue from 'vue';
import VuePlyr from './customPlyr/vue-plyr.ssr';

Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true },
    vimeo: { background: 1 },
  },
  emit: ['ended'],
});
