import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Vue from 'vue';

const app = require.context('..', true, /\w+\.global\.(vue|js|jsx|ts|tsx)$/);
const shared = require.context('../../shared', true, /\w+\.global\.(vue|js|jsx|ts|tsx)$/);

const load = (context) => context.keys().forEach((fileName) => {
  const componentConfig = context(fileName);
  let componentName = camelCase(fileName.split('/').pop().replace(/\.global\.\w+$/, ''));
  componentName = upperFirst(componentName);

  Vue.component(componentName, componentConfig.default || componentConfig);
});

load(app);
load(shared);
