import _get from 'lodash/get';
import { env } from '@shared/env';
import APITrainings from '@app/services/API/Trainings';

const setResourceURL = (training) => {
  if (training && training.picture && !training.picture.startsWith('http')) {
    training.picture = `${env.apiResourcesURL}/${training.picture}`;
  }

  if (_get(training, 'seo_data.image') && !training.seo_data.image.startsWith('http')) {
    training.seo_data.image = `${env.apiResourcesURL}/${training.seo_data.image}`;
  }

  return training;
};

export const state = () => ({
  list: null,
  current: null,
  count: null,
  searchParams: {
    //
  },
});

export const mutations = {
  setList(state, trainings) {
    trainings && trainings.data.forEach(setResourceURL);
    state.list = trainings;
  },
  setListData(state, data) {
    state.list && (state.list.data = data.map(setResourceURL));
  },
  setCurrent(state, training) {
    state.current = setResourceURL(training);
  },
  add(state, training) {
    if (!state.list) {
      return;
    }

    setResourceURL(training);
    state.list.data.push(training);
  },
  updateSingle(state, training) {
    if (!state.list) {
      return;
    }

    state.list.data = state.list.data.map(
      (v) => setResourceURL(v.uuid === training.uuid ? training : v),
    );
  },
  remove(state, training) {
    if (!state.list) {
      return;
    }

    state.list.data = state.list.data.filter((v) => v.uuid !== training.uuid);
  },
  setItemsInCurrent(state, { trainingItems, trainingItemParent }) {
    if (!state.current) {
      return;
    }

    if (!trainingItemParent) {
      state.current.items = trainingItems;
    } else {
      const parent = state.current.items.find((v) => Number(v.id) === Number(trainingItemParent.id));
      parent.children = trainingItems;
    }
  },
  addItemToCurrent(state, trainingItem) {
    if (!state.current) {
      return;
    }

    const { items } = state.current;
    const parentId = trainingItem.parent_id;
    const parentItems = !parentId ? items : items.find((v) => Number(v.id) === Number(parentId)).children;

    parentItems.push(trainingItem);
  },
  updateItemInCurrent(state, trainingItem) {
    if (!state.current) {
      return;
    }

    if (trainingItem.parent_id) {
      state.current.items = state.current.items.map((item) => {
        if (item.id === trainingItem.parent_id) {
          if (item.children) {
            item.children = item.children.map(
              (v) => (v.id === trainingItem.id ? trainingItem : v),
            );
          }
        }

        return item;
      });
    } else {
      state.current.items = state.current.items.map(
        (v) => (v.id === trainingItem.id ? trainingItem : v),
      );
    }
  },
  addPaymentPlanInCurrent(state, paymentPlan) {
    state.current.payment_schedules.push(paymentPlan);
  },
  deletePaymentPlanInCurrent(state, paymentPlan) {
    state.current.payment_schedules = state.current
      .payment_schedules
      .filter((v) => v.id !== paymentPlan.id);
  },
  decrementItemAssignmentSubmissionCounter(state, { id }) {
    if (!state.current) {
      return;
    }

    const getNewItem = (item) => ({
      ...item,
      assignments_to_validate_count: Math.max(
        0,
        item.assignments_to_validate_count - 1,
      ),
    });

    state.current.items = state.current.items.map((item) => {
      if (item.id === id) {
        return getNewItem(item);
      }

      if (item.children) {
        item.children = item.children.map((child) => {
          if (child.id === id) {
            return getNewItem(child);
          }

          return item;
        });
      }

      return item;
    });
  },
  setSearchParams(state, params) {
    state.searchParams = params;
  },
  setCount(state, count) {
    state.count = count;
  },
  resetSearchParams(state) {
    state.searchParams = {}; // Resetting searchParams to an empty object
  },
};

export const actions = {
  fetch({ state, commit }) {
    return APITrainings.getTrainings(state.searchParams)
      .then((data) => {
        commit('setList', data);
        return data;
      });
  },
  count({ commit }) {
    return APITrainings.getTrainingCount()
      .then((data) => {
        commit('setCount', data);
        return data;
      });
  },
  find({ commit }, uuid) {
    return APITrainings.getTraining(uuid)
      .then((data) => {
        commit('setCurrent', data.data);
        return data.data;
      });
  },
  add({ commit }, training) {
    return APITrainings.addTraining(training)
      .then((data) => {
        commit('add', data.data);
        return data.data;
      });
  },
  update({ commit }, { uuid, isCurrent, training }) {
    return APITrainings.updateTraining(uuid, training)
      .then((data) => {
        if (isCurrent) {
          commit('setCurrent', data.data);
        } else {
          commit('updateSingle', data.data);
        }

        return data.data;
      });
  },
  updateFeedbackOptions({ commit }, { uuid, isCurrent, feedbackOptions }) {
    return APITrainings.updateTrainingFeedbackOptions(uuid, feedbackOptions)
      .then((data) => {
        if (isCurrent) {
          commit('setCurrent', data.data);
        } else {
          commit('updateSingle', data.data);
        }

        return data.data;
      });
  },
  updateSEO({ dispatch }, {
    uuid, isCurrent, training, seoData,
  }) {
    return APITrainings.updateTrainingSEO(uuid, seoData)
      .then(() => dispatch('update', { uuid, isCurrent, training }));
  },
  remove({ commit }, training) {
    return APITrainings.deleteTraining(training.uuid)
      .then(() => commit('remove', training));
  },
  duplicate({ commit }, training) {
    return APITrainings.duplicateTraining(training.uuid)
      .then((data) => {
        commit('add', data.data);
        return data.data;
      });
  },
  reorder({ commit }, trainingsData) {
    commit('setListData', trainingsData);

    trainingsData = trainingsData.map((v, i) => ({
      uuid: v.uuid,
      order: ++i,
    }));

    return APITrainings.reorderTrainings(trainingsData)
      .then((data) => data.data);
  },
  addItem({ commit }, { uuid, trainingItem }) {
    return APITrainings.addItem(uuid, trainingItem)
      .then((data) => {
        commit('addItemToCurrent', data.data);
        return data.data;
      });
  },
  updateItem({ commit }, { uuid, trainingItemId, trainingItem }) {
    return APITrainings.updateItem(uuid, trainingItemId, trainingItem)
      .then((data) => {
        commit('updateItemInCurrent', data.data);
        return data.data;
      });
  },
  updateItemQuiz(_, { uuid, trainingItemId, trainingItemQuiz }) {
    return APITrainings.updateItemQuiz(uuid, trainingItemId, trainingItemQuiz)
      .then((data) => data.data);
  },
  removeItem({ dispatch }, { uuid, trainingItem }) {
    return APITrainings.deleteTrainingItem(uuid, trainingItem.id)
      .then(() => dispatch('find', uuid));
  },
  duplicateItem({ state, commit }, { uuid, targetUuid, trainingItem }) {
    return APITrainings.duplicateTrainingItem(uuid, trainingItem.id, targetUuid)
      .then((data) => {
        if (targetUuid === (state.current && state.current.uuid)) {
          commit('addItemToCurrent', data.data);
        }

        return data.data;
      });
  },
  reorderItems({ commit }, { uuid, trainingItems, trainingItemParent }) {
    commit('setItemsInCurrent', { trainingItems, trainingItemParent });

    trainingItems = trainingItems.map((v, i) => ({
      id: v.id,
      order: ++i,
    }));

    return APITrainings.reorderTrainingItems(uuid, trainingItems)
      .then((data) => data.data);
  },
  findItem(_, { uuid, trainingItemId }) {
    return APITrainings.getTrainingItem(uuid, trainingItemId)
      .then((data) => {
        data.data.content = data.data.content || [];
        return data.data;
      });
  },
  addPaymentPlan({ commit }, { uuid, isCurrent = true, paymentPlan }) {
    return APITrainings.addPaymentPlan(uuid, paymentPlan)
      .then(({ data }) => {
        if (isCurrent) {
          commit('addPaymentPlanInCurrent', data);
        }
      });
  },
  deletePaymentPlan({ commit }, { uuid, isCurrent = true, paymentPlan }) {
    return APITrainings.deletePaymentPlan(uuid, paymentPlan)
      .then(() => {
        if (isCurrent) {
          commit('deletePaymentPlanInCurrent', paymentPlan);
        }
      });
  },
  validateItemAssignmentSubmission(
    { commit },
    {
      uuid, id, submissionId, validation,
    },
  ) {
    return APITrainings.validateItemAssignmentSubmission(
      uuid, id, submissionId, validation,
    )
      .then(({ data }) => {
        commit('decrementItemAssignmentSubmissionCounter', { uuid, id });

        return data;
      });
  },
  resetSearchParams({ commit }) {
    commit('resetSearchParams');
  },
};

export const getters = {
  count(state) {
    return state.count;
  },
  currentSectionItems(state) {
    return state.current && state.current.items.filter((v) => v.type === 'SECTION');
  },
  currentItemsFlat(state) {
    return state.current && state.current.items.reduce((prev, curr) => {
      if (curr.type === 'SECTION') {
        return [...prev, ...curr.children];
      }

      prev.push(curr);

      return prev;
    }, []);
  },
};
