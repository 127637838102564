<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    hasCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template functional>
  <component
    :is="props.tag"
    class="modal-card w-full"
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <header v-if="slots().title" class="modal-card-head">
      <h2 class="modal-card-title is-flex-shrink-1">
        <slot name="title" />
      </h2>
      <b-button
        v-if="props.hasCloseBtn"
        icon-left="times"
        aria-label="fermer"
        v-on="listeners.close && {click: listeners.close}"
      />
    </header>
    <div
      v-if="slots().body"
      class="modal-card-body"
      :class="{'is-titleless': !slots().title}"
    >
      <slot name="body" />
    </div>
    <footer
      v-if="slots().foot"
      class="modal-card-foot is-justify-content-flex-end btop-grey"
    >
      <slot name="foot" />
    </footer>
  </component>
</template>
