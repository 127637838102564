<script>
export default {
  static: {
    messages: {
      WHO_ARE_YOU: 'Quel profil vous décrit le mieux ?',
      WHAT_CERTIFICATIONS: 'Quelle(s) "certification(s)" avez-vous ou comptez-vous obtenir dans les 3 prochains mois ?',
      WHAT_MAIN_ACTIVITY: 'Quel est votre secteur d\'activité principal ?',
      WHAT_TYPE_OF_PRODUCT: 'Vous allez faire des...',
      WHAT_MAIN_CONTENT: 'Quel type de contenu comptez-vous utiliser principalement ?',
      HOW_FOUND_US: 'Comment nous avez-vous connus ?',
      DO_YOU_SELL: 'Animez-vous déjà des formations ?',
      WHAT_GOAL: 'Vous allez utiliser Teachizy pour...',
    },
    questions: [
      {
        type: 'radio',
        name: 'HOW_FOUND_US',
        options: {
          REFERRAL: 'Bouche à oreille (dites-nous en plus ci-dessous)',
          SEO: 'Moteur de recherche (Google, Bing... Précisez ci-dessous)',
          FACEBOOK_GROUP: 'Groupe Facebook (précisez lequel ci-dessous)',
          ARTICLE: 'Via un article (blog, site web... Précisez ci-dessous)',
          INSTAGRAM: 'Instagram',
          FACEBOOK_PAGE: 'Page Facebook',
          LINKEDIN: 'LinkedIn',
          OTHER_SNS: 'Autre réseau social (précisez lequel ci-dessous)',
          BLOG: "Via le blog Teachizy (précisez l'article ci-dessous)",
          OTHER: 'Autre, précisez ci-dessous',
        },
      }, {
        type: 'radio',
        name: 'WHO_ARE_YOU',
        options: {
          TEACHER_PRO: 'Formatrice / Formateur professionnel inscrit à la DIRECCTE / DREETS',
          TEACHER_NOT_PRO: 'Formatrice / Formateur indépendant(e) non-référencé(e)',
          TEACHER_IN_COMPANY: "Formatrice / Formateur salarié(e) d'une entreprise",
          TEACHER_IN_SCHOOLL: 'Enseignant(e) / Professeur(e) en école',
          TEACHER_HOME: 'Professeur(e) particulier',
          INFOPRENEUR: 'Infopreneur (création de contenus, business en ligne...)',
          OTHER: 'Autre, précisez ci-dessous',
        },
      }, {
        type: 'checkbox',
        name: 'WHAT_CERTIFICATIONS',
        options: {
          DATADOCK: 'DataDock',
          QUALIOPI: 'Qualiopi',
          CPF: 'CPF',
          POLE_EMPLOI: 'Pôle emploi',
          RS: 'RS (titre au répertoire spécifique)',
          RNCP: 'RNCP (titre professionnel diplomant)',
          NONE: "Aucun, et ce n'est pas prévu",
          OTHER: 'Autre, précisez ci-dessous',
        },
      }, {
        type: 'select',
        name: 'WHAT_MAIN_ACTIVITY',
        options: {
          BEAUTY: 'Beauté',
          BUSINESS: 'Business',
          COACHING: 'Coaching',
          CONSULTANT: 'Conseils / Stratégies',
          MANAGEMENT: 'Management',
          EDUCATION_PRO: 'Formation professionnelle',
          EDUCATION_OTHER: 'Enseignement et autres formations',
          LANGUAGE: 'Langues',
          FOOD: 'Cuisine',
          DANCE: 'Danse',
          MUSIC: 'Musique',
          DESIGN: 'Design / Graphisme',
          PERSONAL_DEVELOPMENT: 'Développement personnel',
          FINANCE: 'Finances',
          HUMOUR: 'Humour',
          IMMO: 'Immobilier',
          IT: 'Informatique et logiciels',
          COMMUNICATION: 'Communication',
          MARKETING: 'Marketing',
          VIDEO_EDITING: 'Montage vidéo / Motion',
          PHOTOGRAPHY: 'Photographie',
          PRODUCTIVITY: 'Productivité',
          PROGRAMMING: 'Programmation / Développement',
          HEALTH: 'Santé / Bien-être',
          SPORT: 'Sport / GYM',
          TRAVELING: 'Voyage',
          '3D': '3D',
          OTHER: 'Autre, précisez plus bas',
        },
      }, {
        type: 'checkbox',
        name: 'WHAT_TYPE_OF_PRODUCT',
        options: {
          COURSE_LONG: 'Formations longues (7h ou plus)',
          COURSE_SHORT: 'Formations courtes (moins de 7h)',
          WORKSHOP: 'Ateliers',
          COACHING: 'Coaching',
          MASTERCLASS: 'Masterclass',
          MOOC: 'MOOC',
          OTHER: 'Autre, précisez plus bas',
        },
      }, {
        type: 'radio',
        name: 'WHAT_MAIN_CONTENT',
        options: {
          TEXT: 'Du contenu écrit / rédigé',
          VIDEO: 'De la vidéo',
          AUDIO: 'De l\'audio',
          UPLOADS: 'Des fichiers à télécharger',
          IDK: 'Je ne sais pas encore',
        },
      }, {
        type: 'radio',
        name: 'DO_YOU_SELL',
        options: {
          OFFLINE: 'Oui, en présentiel',
          ONLINE: 'Oui, en ligne',
          BOTH: 'Oui, en présentiel et en ligne',
          PLANNING_TO: 'Pas encore, mais c\'est prévu',
          JUST_TESTING: 'Non, je ne fais que tester',
          OTHER: 'Autre, précisez plus bas',
        },
      }, {
        type: 'checkbox',
        name: 'WHAT_GOAL',
        options: {
          SELL: 'Vendre en ligne',
          BLENDED: 'Créer un espace en ligne pour mes apprenants en présentiel',
          OTHER: 'Autre, précisez plus bas',
        },
      },
    ],
  },
  props: {
    isMobileScreen: {
      type: Boolean,
      defalt: false,
    },
  },
  data: () => ({
    isLoading: false,
    hasError: false,
    currentQuestion: 0,
    questions: [],
  }),
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    questionHasError() {
      const current = this.questions[this.currentQuestion];

      if ((current.type === 'checkbox'
        ? (!current.value.length || (current.value.includes('OTHER') && !current.other))
        : (!current.value || (current.value === 'OTHER' && !current.other))
      )) {
        this.hasError = true;
        return true;
      }

      return false;
    },
    handle() {
      if (this.currentQuestion < this.questions.length - 1) {
        this.nextQuestion();
        return;
      }

      if (this.questionHasError()) {
        return;
      }

      let onboarding_questions = this.questions.reduce((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {});

      onboarding_questions = JSON.stringify({ ...onboarding_questions });

      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', { onboarding_questions })
        .then(() => this.$router.replace({ query: {} }))
        .finally(() => (this.isLoading = false));
    },
    nextQuestion() {
      if (this.questionHasError()) {
        return;
      }

      this.currentQuestion++;
      this.hasError = false;
    },
    prevQuestion() {
      this.currentQuestion--;
      this.hasError = false;
    },
  },
  created() {
    this.questions = this.$options.static.questions
      .map((question) => ({
        name: question.name,
        type: question.type,
        value: question.type === 'checkbox' ? [] : '',
        other: '',
      }));
  },
};
</script>

<template>
  <div v-if="authStore.onboarding_questions" class="modal-card has-text-centered">
    <header class="modal-card-head">
      <h1 class="modal-card-title mw-full break-words">
        Bonjour {{ authUser.firstname }}
      </h1>
    </header>
    <div class="modal-card-body">
      <p>Vous êtes sur notre questionnaire, et vous y avez déjà répondu !</p>
      <p v-show="isMobileScreen" class="mt-5">
        <b-button type="is-primary" tag="router-link" :to="{name: 'home'}" replace>
          Retourner à l'accueil
        </b-button>
      </p>
    </div>
    <footer v-show="!isMobileScreen" class="modal-card-foot is-justify-content-center">
      <b-button type="is-primary" tag="router-link" :to="{name: 'home'}" replace>
        Retourner à l'accueil
      </b-button>
    </footer>
  </div>
  <form v-else class="modal-card" @submit.prevent="handle">
    <header class="modal-card-head">
      <h1 class="modal-card-title mw-full break-words">
        Bienvenue {{ authUser.firstname }}
      </h1>
    </header>
    <div class="modal-card-body">
      <h2 class="title is-4">
        Parlez-nous un peu de vous ({{ currentQuestion + 1 }} / {{ questions.length }})
      </h2>
      <div
        v-for="(one, id) in $options.static.questions"
        :key="one.name"
        v-show="currentQuestion == id">
        <b-field :label-for="`field-${id}`">
          <template #label>
            <span class="is-size-5 is-size-7-mobile has-text-weight-bold">
              {{ $options.static.messages[one.name] }}
            </span>
          </template>
          <b-select
            v-if="one.type === 'select'"
            v-model.trim="questions[id].value"
            :id="`field-${id}`"
            expanded>
            <option value="">Choisissez...</option>
            <option v-for="(option, k) in one.options" :key="k" :value="k">
              {{ option }}
            </option>
          </b-select>
          <div v-else-if="one.type === 'radio'" class="is-size-8-mobile">
            <p v-for="(option, k) in one.options" :key="k" class="py-2">
              <b-radio v-model.trim="questions[id].value" :name="one.name" :native-value="k" expanded>
                {{ option }}
              </b-radio>
            </p>
          </div>
          <div v-else-if="one.type === 'checkbox'" class="is-size-8-mobile">
            <p v-for="(option, k) in one.options" :key="k" class="py-2">
              <b-checkbox v-model.trim="questions[id].value" :native-value="k" expanded>
                {{ option }}
              </b-checkbox>
            </p>
          </div>
        </b-field>
        <b-field>
          <b-input
            v-model="questions[id].other"
            :placeholder="`Autre chose à nous dire ? ${
              questions[id].value.indexOf('OTHER') < 0 ? '(facultatif)' : ''
            }`"
          />
        </b-field>
        <p v-show="hasError" class="help is-danger">
          Cela nous aiderait vraiment d'avoir cette information !
        </p>
      </div>
    </div>
    <footer
      class="modal-card-foot has-background-light"
      :class="{'is-justify-content-flex-end': !isMobileScreen}">
      <b-button v-show="currentQuestion > 0" type="is-text" @click="prevQuestion">
        Précédent
      </b-button>
      <b-button
        v-show="currentQuestion < questions.length - 1"
        type="is-primary"
        @click="nextQuestion">
        Suivant
      </b-button>
      <b-button
        v-show="currentQuestion == questions.length - 1"
        native-type="submit"
        type="is-primary"
        :loading="isLoading">
        Envoyer
      </b-button>
    </footer>
  </form>
</template>
