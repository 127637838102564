export const genericForms = {
  data: () => ({
    isLoading: false,
  }),
  methods: {
    handle() {
      this.isLoading = true;
      this.action().then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};

export default null;
