<script>
export default {
  props: {
    tag: {
      type: [String, Object],
      default: 'span',
    },
    path: {
      type: String,
      required: true,
    },
    count: {
      type: [String, Number],
      default: null,
    },
    args: {
      type: Object,
      default: null,
    },
  },
  computed: {
    text() {
      if (this.count != null) {
        return this.$tc(this.path, this.count, this.args);
      }

      return this.$t(this.path, this.args);
    },
  },
};
</script>

<template>
  <component :is="tag">
    {{ text }}
  </component>
</template>
