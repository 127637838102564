export const state = {
  layout: 'div',
};

export const methods = {
  setLayout(layout) {
    state.layout = layout;
  },
};

export default { state, methods };
