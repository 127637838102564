<script>
import APIUser from '@app/services/API/User';
import trainingsMixin from '@app/mixins/trainings';

import CollegeGraduation from '@app/assets/img/advantages/college-graduate.png';
import DataStructure from '@app/assets/img/advantages/data-structure.png';
import Mailing from '@app/assets/img/advantages/mailing.png';
import OnlineUniversities from '@app/assets/img/advantages/online-universities.png';
import InformationArchive from '@app/assets/img/advantages/information-archive.png';
import BusinessStartup from '@app/assets/img/advantages/business-startup.png';
import PeopleManagement from '@app/assets/img/advantages/people-management.png';
import SupportQuestion from '@app/assets/img/advantages/support-question.png';
import StatisticsPresentation from '@app/assets/img/advantages/statistics-presentation.png';

export default {
  mixins: [trainingsMixin],
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
    advantages() {
      return [
        {
          condition: true,
          img: CollegeGraduation,
          text: 'Créer <b>autant de formations</b> que vous le voulez, avec <b>autant d’apprenants</b> que vous le voulez.',
          action: {
            label: 'Ajouter',
            handler: this.addTraining,
          },
        },
        {
          condition: true,
          img: DataStructure,
          text: 'Connecter un nom de <b>domaine ou sous-domaine personnalisé</b>.',
          action: {
            label: 'Connecter',
            handler: () => {
              this.$router.push(this.$router.resolve({ name: 'settings' }).href);
            },
          },
        },
        {
          condition: true,
          img: Mailing,
          text: 'Configurer vos outils marketing parmi <b>Brevo, ActiveCampaign et GetResponse</b>.',
          action: {
            label: 'Configurer',
            handler: () => {
              this.$router.push(
                this.$router.resolve({ name: 'marketing_automations' }).href,
              );
            },
          },
        },
        {
          condition: true,
          img: OnlineUniversities,
          text: '<b>Personnaliser les emails</b> automatiques envoyés à vos apprenants.',
          action: {
            label: 'Personnaliser',
            handler: () => {
              this.$router.push(
                this.$router.resolve({
                  name: 'marketing_emails',
                  query: { onglet: 'WELCOME' },
                }).href,
              );
            },
          },
        },
        {
          condition: true,
          img: InformationArchive,
          text: 'Evaluez vos apprenants en <b>leur proposant des devoirs</b> qu’ils devront vous soumettre.',
          action: {
            label: 'Personnaliser',
            handler: () => {
              this.$router.push(
                this.$router.resolve({ name: 'trainings' }).href,
              );
            },
          },
        },
        {
          condition: true,
          img: BusinessStartup,
          text: 'Activez notre <b>système anti-décrochage</b> pour booster le taux de complétion de vos formations.',
          action: {
            label: 'Personnaliser',
            handler: () => {
              this.$router.push(
                this.$router.resolve({
                  name: 'marketing_emails',
                  query: { onglet: 'NO_RECENT_CONNECTION_1' },
                }).href,
              );
            },
          },
        },
        {
          condition: true,
          img: PeopleManagement,
          text: 'Invitez <b>jusqu\'à 3 collaborateurs</b> à gérer vos contenus et vos apprenants. <b>Passez à la vitesse supérieure !</b>',
          action: {
            label: 'Configurer',
            handler: () => {
              this.$router.push(
                this.$router.resolve({ name: 'settings_team' }).href,
              );
            },
          },
        },
        {
          condition: true,
          img: SupportQuestion,
          text: 'Utilisez notre <b>CRM</b> en ajoutant des notes et des informations personnelles sur vos apprenants.',
          action: {
            label: 'Personnaliser',
            handler: () => {
              this.$router.push(
                this.$router.resolve({
                  name: 'customers',
                  query: {
                    sort: 'created_at',
                    order: 'desc',
                    type: 'STUDENT',
                    page: 1,
                  },
                }).href,
              );
            },
          },
        },
        {
          condition: this.isNotPercentPlan,
          img: StatisticsPresentation,
          text: 'Récoltez <b>des avis à chaud</b> sur chaque leçon pour savoir si vos formations plaisent.',
          action: {
            label: 'Connecter',
            handler: () => {
              this.$router.push(
                this.$router.resolve({ name: 'trainings' }).href,
              );
            },
          },
        },
      ];
    },
  },
  methods: {
    getSupportInfos() {
      if (this.supportData) {
        this.showSupportInfos();
        return;
      }

      const loader = this.$buefy.loading.open();
      APIUser.getSupport()
        .then(({ data }) => this.showSupportInfos(data))
        .finally(() => loader.close());
    },
    showSupportInfos(data) {
      data = this.supportData || data;
      this.supportData = data;
      this.$buefy.dialog.alert(`
        <span class="is-size-4 is-size-5-mobile">
          <strong>Email</strong> : <a href="mailto:${data.email}">${data.email}</a>
        </span>
      `);
    },
    handleClick(advantage) {
      if (advantage.condition !== undefined && !advantage.condition) {
        return this.$showMessage.goPro();
      }

      return advantage.action.handler();
    },
  },
};
</script>

<template>
  <div class="ppacomp">
    <div
      v-for="(advantage, index) in advantages"
      :key="index"
      class="item box p-0"
      >
      <div class="illustration has-background-light">
        <img :src="advantage.img" />
      </div>

      <p class="text" v-html="advantage.text"/>

      <div class="actions">
        <b-button type="is-flat" @click="handleClick(advantage)" v-bind="advantage.action.attrs" class="is-primary">
          {{ advantage.action.label }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ppacomp {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @include mobile {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .item {
    display: flex;
    flex-direction: column;

    .illustration {
      margin: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 209px;
      }
    }
    .text {
      margin-right: 30px;
      margin-left: 30px;
    }
    .actions {
      margin-top: auto;
      padding-top: 16px;
      padding-left: 21px;
      padding-right: 21px;
      padding-bottom: 21px;
      text-align: right;
    }
  }
}
</style>
