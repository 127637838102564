export const storeForms = {
  data: () => ({
    isLoading: false,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    handle(onSuccess, onError) {
      const store = this.processHandleData({ ...this.store });
      this.isLoading = true;
      let promise = this.$store.dispatch('auth/updateStore', store);

      if (onSuccess) {
        promise = promise.then(onSuccess);
      }

      promise = promise.then(() => this.$showMessage.success());

      if (onError) {
        promise = promise.catch(onError);
      }

      return promise.finally(() => (this.isLoading = false));
    },
    processHandleData: (store) => store,
  },
  created() {
    if (!this.store) {
      return;
    }

    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));
  },
};

export const refreshCurrentTrainingOnUpdate = {
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
  watch: {
    currentTraining() {
      this.hydrateTraining();
    },
  },
  methods: {
    hydrateTraining() {
      Object.keys(this.training)
        .forEach((k) => (this.training[k] = this.currentTraining[k]));
    },
  },
};

export const refreshCurrentBundleOnUpdate = {
  computed: {
    currentBundle() {
      return this.$store.state.bundles.current;
    },
  },
  watch: {
    currentBundle() {
      this.hydrateBundle();
    },
  },
  methods: {
    hydrateBundle() {
      Object.keys(this.bundle)
        .forEach((key) => (this.bundle[key] = this.currentBundle[key]));
    },
  },
};

export const currentTrainingForms = {
  mixins: [refreshCurrentTrainingOnUpdate],
  data: () => ({
    isLoading: false,
    training: {},
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    this.hydrateTraining();
  },
  methods: {
    handle() {
      const training = { ...this.training };

      this.isLoading = true;
      return this.$store.dispatch('trainings/update', {
        uuid: this.currentTraining.uuid,
        training,
        isCurrent: true,
      })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};

export const currentBundleForms = {
  mixins: [refreshCurrentBundleOnUpdate],
  data: () => ({
    isLoading: false,
    bundle: {},
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    this.hydrateBundle();
  },
  methods: {
    handle() {
      const bundle = { ...this.bundle };

      this.isLoading = true;
      return this.$store.dispatch('bundles/update', {
        uuid: this.currentBundle.uuid,
        bundle,
        isCurrent: true,
      })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};

export default null;
