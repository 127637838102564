import APIStore from '@app/services/API/Store';

export const state = () => ({
  isLoading: false,
  contents: null,
});

export const mutations = {
  set(state, contents) {
    state.contents = contents;
  },
};

export const actions = {
  fetch({ state, commit }, { storeUUID, params }) {
    if (state.isLoading) {
      return null;
    }

    return APIStore.getAIContents(storeUUID, params)
      .then((data) => commit('set', data));
  },
};
