<script>
export default {
  data: () => ({ isOffline: false }),
  methods: {
    reload: () => window.location.reload(),
  },
  created() {
    window.addEventListener('online', () => (this.isOffline = false));
    window.addEventListener('offline', () => (this.isOffline = true));
  },
};
</script>

<template>
  <div
    v-if="isOffline"
    class="
      ocomp notification is-danger is-size-8-mobile m-2 p-3
      is-flex is-align-items-center is-justify-content-center
    ">
    Vous êtes actuellement hors ligne
    <b-button class="valign-middle ml-3 is-hidden-mobile" type="is-white" @click.prevent="reload">
      Recharger la page
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.ocomp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
}
</style>
