<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    ctaText: {
      type: String,
      default: null,
    },
    ctaFunc: {
      type: Function,
      default: null,
    },
  },
};
</script>
<template functional>
  <div class="box p-20 has-text-centered">
    <p class="image is-96x96 mb-3 mx-auto">
      <img src="@app/assets/img/icons/empty_state.svg" :alt="props.title || props.text" svg-inline>
    </p>
    <h2 v-if="props.title" class="is-size-5 has-text-weight-bold mb-3">
      {{ props.title }}
    </h2>
    <p v-if="props.text" class="mb-3">
      {{ props.text }}
    </p>
    <p v-if="props.ctaFunc">
      <b-button type="is-primary" @click="props.ctaFunc">
        {{ props.ctaText }}
      </b-button>
    </p>
  </div>
</template>
