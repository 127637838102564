<script>
export default {
  head: {
    title: 'Pas non trouvé',
  },
};
</script>

<template>
  <article class="mt-10 mx-auto w-640 content is-large has-text-centered">
    <h1>Il semblerait que vous vous soyez perdu</h1>
    <p v-if="$store.state.errors.message">
      {{ $store.state.errors.message }}
    </p>
    <p v-else>
      Cette page n'existe pas !
    </p>
    <p>
      <router-link class="flex-none button is-primary" :to="{name: 'home'}">
        Retourner à mon espace formateur
      </router-link>
    </p>
  </article>
</template>
