<script>
import APIUser from '@app/services/API/User';
import AppNotificationListWrapper from '@shared/components/AppNotificationListWrapper.vue';

export default {
  components: { AppNotificationListWrapper },
  static: {
    APIUser,
    icons: {
      CustomerAssignmentSubmissionNotification: {
        pack: 'fas',
        icon: 'file-archive',
        to: (notif) => ({
          name: 'training_uuid_assignment_submission',
          params: {
            uuid: notif.data.training_uuid,
            id: notif.data.training_item_id,
            submissionId: notif.data.assignment_id,
          },
        }),
      },
      NewCustomerNotification: {
        pack: 'fas',
        icon: 'user-plus',
        to: (notif) => ({ name: 'customer', params: { uuid: notif.data.customer_uuid } }),
      },
      NewCustomerAffiliateNotification: {
        pack: 'fas',
        icon: 'user-tie',
        to: (notif) => ({ name: 'affiliate', params: { uuid: notif.data.affiliate_uuid } }),
      },
      ExistingCustomerJoinAffiliationNotification: {
        pack: 'fas',
        icon: 'user-tie',
        to: (notif) => ({ name: 'affiliate', params: { uuid: notif.data.affiliate_uuid } }),
      },
      CustomerCompletedTrainingNotification: {
        pack: 'fas',
        icon: 'user-graduate',
        to: (notif) => ({ name: 'customers', query: { q: notif.data.email } }),
      },
      CustomerCertificateNotification: {
        pack: 'fas',
        icon: 'award',
        to: (notif) => ({ name: 'customers', query: { q: notif.data.email } }),
      },
      PasswordChangedNotification: {
        pack: 'fas',
        icon: 'key',
        to: () => ({ name: 'account_security' }),
      },
      PaymentFailedNotification: {
        pack: 'fab',
        icon: 'creative-commons-nc-eu',
        to: () => ({ name: 'settings_billing' }),
      },
      CustomerPaymentFailedNotification: {
        pack: 'fab',
        icon: 'creative-commons-nc-eu',
        to: (notif) => ({ name: 'customers', query: { q: notif.data.email } }),
      },
      CustomerPaymentSuccessfulNotification: {
        pack: 'fab',
        icon: 'cc-visa',
        to: (notif) => ({ name: 'customers', query: { q: notif.data.email } }),
      },
      NewUserNotification: {
        pack: 'fas',
        icon: 'user-plus',
        to: (notif) => ({ name: 'customers', query: { q: notif.data.email } }),
      },
      NewCommentNotification: {
        pack: 'fas',
        icon: 'comment-dots',
        to: (notif) => ({
          name: notif.data.entity_uuid ? 'training_comments' : 'trainings',
          params: {
            uuid: notif.data.entity_uuid,
          },
        }),
      },
      NewAssignmentCommentNotification: {
        pack: 'fas',
        icon: 'comments',
        to: (notif) => ({
          name: 'training_uuid_assignment_submission',
          params: {
            uuid: notif.data.training_uuid,
            id: notif.data.training_item_id,
            submissionId: notif.data.assignment_id,
          },
        }),
      },
      NewAssignmentSubmissionNotification: {
        pack: 'fas',
        icon: 'paper-plane',
        to: () => ({ name: 'trainings' }),
      },
      PaymentCardExpirationNotification: {
        pack: 'fas',
        icon: 'credit-card',
        to: () => ({ name: 'plan', query: { onglet: 'vous' } }),
      },
      AbandonedCartNotification: {
        pack: 'fas',
        icon: 'shopping-basket',
        to: (notif) => ({ name: 'customers', query: { q: notif.data.email, type: 'ABANDONED_CART' } }),
      },
      NewTrainingsAsAuthorNotification: {
        pack: 'fas',
        icon: 'exchange-alt',
        to: () => ({ name: 'trainings' }),
      },
      CustomerAffiliatePayoutRequestNotification: {
        pack: 'fas',
        icon: 'receipt',
        to: () => ({ name: 'affiliates' }),
      },
      BundleSoldNotification: {
        pack: 'fas',
        icon: 'receipt',
        to: () => ({ name: 'sales' }),
      },
    },
  },
};
</script>

<template>
  <AppNotificationListWrapper
    :icons="$options.static.icons"
    :user="$store.getters['auth/user']"
    :getListAction="$options.static.APIUser.getNotifications"
    :updateAction="$options.static.APIUser.updateNotification"
    :clearAction="$options.static.APIUser.clearNotifications"
  />
</template>
