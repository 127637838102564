import Vue from 'vue';
import _get from 'lodash/get';
import { env } from '@shared/env';
import EventBus from '@shared/services/EventBus';
import APIUser from '@app/services/API/User';
import APIStore from '@app/services/API/Store';
import router from '@app/config/router';

const PREVIEW_TOKEN_DURATION = 1000 * 60 * 10;

const setApiResourcesURL = (user) => {
  const { picture } = user;
  const { logo } = user.store;
  const { favicon } = user.store;

  if (picture && !picture.startsWith('http')) {
    user.picture = `${env.apiResourcesURL}/${picture}`;
  }

  if (logo && !logo.startsWith('http')) {
    user.store.logo = `${env.apiResourcesURL}/${logo}`;
  }

  if (favicon && !favicon.startsWith('http')) {
    user.store.favicon = `${env.apiResourcesURL}/${favicon}`;
  }

  if (_get(user.store, 'seo_data.image') && !user.store.seo_data.image.startsWith('http')) {
    user.store.seo_data.image = `${env.apiResourcesURL}/${user.store.seo_data.image}`;
  }
};

let readyPromise;

export const state = () => ({
  ready: false,
  previewToken: null,
});

export const mutations = {
  setReady(state, ready) {
    state.ready = ready;
  },
  update(state, data) {
    setApiResourcesURL(data);
    Vue.auth.user(data);
  },
  updateStore(state, data) {
    const user = Vue.auth.user();
    Object.assign(user.store, data);
    setApiResourcesURL(user);
    Vue.auth.user(user);
  },
  updateAICredits(state, credits) {
    const user = Vue.auth.user();
    const { store } = user;

    store.ai_credits.pack_credits -= credits;

    if (store.ai_credits.pack_credits < 0) {
      store.ai_credits.paid_credits -= (-1 * store.ai_credits.pack_credits);
      store.ai_credits.pack_credits = 0;
    }

    user.store = store;

    Vue.auth.user({ ...user });
  },
  setPreviewToken(state, token) {
    state.previewToken = token;
  },
  setStoreFeatureOptions(state, data) {
    const user = Vue.auth.user();
    user.store.feature_options = data;
    Vue.auth.user(user);
  },
};

export const actions = {
  ready({ dispatch, commit, state }) {
    if (readyPromise) {
      return readyPromise;
    }

    if (state.ready) {
      return true;
    }

    readyPromise = Vue.auth.load()
      .then(() => {
        if (Vue.auth.check()) {
          return dispatch('fetch')
            .then(() => Promise.all([
              dispatch('updateLastLogin'),
              dispatch('generatePreviewToken'),
            ]));
        }

        return null;
      })
      .catch((error) => {
        router.app.$errorHandlers.axios(error);
      })
      .finally(() => {
        commit('setReady', true);
        readyPromise = null;
      });

    return readyPromise;
  },
  fetch({ commit }) {
    return Vue.auth.fetch()
      .then((res) => commit('update', res.data.data));
  },
  login({ commit, dispatch }, data) {
    return Vue.auth.login({ data })
      .then((res) => {
        commit('update', res.data.data);
        const { redirect } = router.currentRoute.query;
        router.push(redirect || { name: 'home' });
        dispatch('updateLastLogin');
        dispatch('generatePreviewToken');
        router.app.$gtag.nativeEvent('login');
        router.app.$gtag.nativeEvent('login', {
          send_to: router.app.$constants.WEBSITE_GA,
        });
        return res;
      });
  },
  logout() {
    return router.push({ name: 'login' })
      .then(() => Vue.auth.logout());
  },
  delete({ dispatch }, { password, cancel_reason, cancel_additional_text }) {
    return APIUser.delete({ password, cancel_reason, cancel_additional_text })
      .then(() => dispatch('logout'));
  },
  register({ commit, dispatch }, data) {
    const god_father = localStorage.getItem('god_father');
    if (god_father) {
      data.god_father = god_father;
    }

    return Vue.auth.register({ data })
      .then((res) => {
        Vue.auth.token(null, Vue.auth.token(), false);
        localStorage.removeItem('god_father');
        commit('update', res.data.data);
        router.push({ name: 'home', query: { questionnaire: 1 } });
        dispatch('generatePreviewToken');
        router.app.$gtag.nativeEvent('sign_up');
        router.app.$gtag.nativeEvent('sign_up', {
          send_to: router.app.$constants.WEBSITE_GA,
        });
      });
  },
  registerPerk({ commit, dispatch }, data) {
    return Vue.auth.register({ data, url: '/perks/register' })
      .then((res) => {
        Vue.auth.token(null, Vue.auth.token(), false);
        localStorage.removeItem('god_father');
        commit('update', res.data.data);
        router.push({ name: 'home', query: { questionnaire: 1 } });
        dispatch('generatePreviewToken');
        router.app.$gtag.nativeEvent('sign_up');
        router.app.$gtag.nativeEvent('sign_up', {
          send_to: router.app.$constants.WEBSITE_GA,
        });
      });
  },
  update({ commit }, data) {
    return APIUser.update(data)
      .then((data) => commit('update', data.data));
  },
  updateStore({ commit, getters }, data) {
    return APIStore.update(getters.store.uuid, data)
      .then((data) => commit('updateStore', data.data));
  },
  updateStoreSEO({ commit, getters }, data) {
    const store = { ...getters.store };
    store.seo_data = data;

    return APIStore.updateSEO(store.uuid, data)
      .then(() => commit('updateStore', store));
  },
  updateLastLogin() {
    return APIUser.updateLastLogin();
  },
  askNewPassword(context, email) {
    return APIUser.askNewPassword(email);
  },
  resetPassword(context, data) {
    return APIUser.resetPassword(data);
  },
  generateAPIKey({ commit }) {
    return APIUser.generateAPIKey()
      .then((data) => commit('update', data.data));
  },
  deleteAPIKey({ commit }) {
    return APIUser.deleteAPIKey()
      .then((data) => commit('update', data.data));
  },
  generateKey({ commit, getters }, data) {
    return APIStore.generateKey(getters.store.uuid, data)
      .then((data) => commit('updateStore', data.data));
  },
  deleteKey({ commit, getters }, data) {
    return APIStore.deleteKey(getters.store.uuid, data)
      .then((data) => commit('updateStore', data.data));
  },
  generatePreviewToken({ dispatch, commit, getters }) {
    if (!getters.user) {
      return Promise.resolve();
    }

    return APIStore.getPreviewToken(getters.store.uuid)
      .then((data) => {
        commit('setPreviewToken', data.data.token);
        setTimeout(() => dispatch('generatePreviewToken'), PREVIEW_TOKEN_DURATION);
      })
      .catch(() => {
        setTimeout(() => dispatch('generatePreviewToken'), PREVIEW_TOKEN_DURATION);
      });
  },
};

export const getters = {
  user() {
    const user = Vue.auth.user();
    EventBus.emit('authchange', user);
    return user;
  },
  store(state, getters) {
    return getters.user && getters.user.store;
  },
  url(state, getters) {
    return getters.hasCustomDomainActivated ? getters.customDomainUrl : getters.subdomainUrl;
  },
  domain(state, getters) {
    return getters.hasCustomDomainActivated ? getters.customDomain : getters.subdomain;
  },
  subdomain(state, getters) {
    return getters.store && `${getters.store.subdomain}.${env.rootHost}`;
  },
  customDomain(state, getters) {
    return getters.store && getters.store.custom_domain;
  },
  subdomainUrl(state, getters) {
    return `//${getters.subdomain}`;
  },
  customDomainUrl(state, getters) {
    return `//${getters.customDomain}`;
  },
  hasCustomDomainActivated(state, getters) {
    return (
      getters.isNotPercentPlan
      && getters.store.custom_domain
      && getters.store.custom_domain_enabled
    );
  },
  hasProPlan(state, getters) {
    return getters.store && getters.store.plan === 'PRO';
  },
  hasPerkPlan(state, getters) {
    return getters.store && getters.store.plan === 'PERK';
  },
  hasExpertPlan(state, getters) {
    return getters.store && getters.store.plan === 'EXPERT';
  },
  hasPercentPlan(state, getters) {
    return getters.store && getters.store.plan === 'PERCENT';
  },
  isNotPercentPlan(state, getters) {
    return getters.store && getters.store.plan !== 'PERCENT';
  },
  isNotRecurringPlan(state, getters) {
    return getters.hasPercentPlan || getters.hasPerkPlan;
  },
  hasToActivate(state, getters) {
    return getters.user && !getters.user.email_verified_at;
  },
  enterpriseCapabilities(state, getters) {
    return getters.store.enterprise_capabilities;
  },
  nbSeatsAvailable(state, getters) {
    const { capabilities } = getters.store;

    return capabilities.additional_user_accounts + capabilities.paid_user_accounts;
  },
  nbSeatsLeft(state, getters) {
    const { capabilities, user_account_count } = getters.store;

    return (capabilities.additional_user_accounts + capabilities.paid_user_accounts) - user_account_count;
  },
  isRoleOwner(state, getters) {
    return getters.user && getters.user.role === 'OWNER';
  },
  isRoleAdmin(state, getters) {
    return getters.user && getters.user.role === 'ADMIN';
  },
  isRoleTeacher(state, getters) {
    return getters.user && getters.user.role === 'TEACHER';
  },
  isRoleAnalyst(state, getters) {
    return getters.user && getters.user.role === 'ANALYST';
  },
  permissions(state, getters) {
    return {
      canManageUsers: (getters.isRoleOwner || getters.isRoleAdmin),
      canManagePlans: (getters.isRoleOwner || getters.isRoleAdmin),
    };
  },
  isAffiliationClosed(state, getters) {
    return getters.store?.affiliation_options.status === 'CLOSED';
  },
  isAffiliationPrivate(state, getters) {
    return getters.store?.affiliation_options.status === 'PRIVATE';
  },
  isAffiliationPublic(state, getters) {
    return getters.store?.affiliation_options.status === 'PUBLIC';
  },
  aiCreditsLeft(state, getters) {
    return (
      getters.store.ai_credits.pack_credits
      + getters.store.ai_credits.paid_credits
    );
  },
};
