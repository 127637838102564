import Vue from 'vue';
import Vuex from 'vuex';
import * as errors from '@shared/config/store/errors';
import * as community from '@shared/config/store/community';
import * as auth from './auth';
import * as aiContents from './aiContents';
import * as discounts from './discounts';
import * as trainings from './trainings';
import * as customers from './customers';
import * as affiliates from './affiliates';
import * as bundles from './bundles';

Vue.use(Vuex);

const modules = {
  errors,
  community,
  auth,
  aiContents,
  discounts,
  trainings,
  customers,
  affiliates,
  bundles,
};

Object.keys(modules)
  .forEach((key) => (modules[key].namespaced = true));

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
});
