import APIBundles from '@app/services/API/Bundles';

export const state = () => ({
  list: null,
  current: null,
  count: null,
  searchParams: {
    //
  },
});

export const mutations = {
  setList(state, bundles) {
    state.list = bundles;
  },
  setCurrent(state, bundle) {
    state.current = bundle;
  },
  add(state, bundle) {
    if (!state.list) {
      return;
    }

    state.list.data.push(bundle);
  },
  updateSingle(state, bundle) {
    if (!state.list) {
      return;
    }

    state.list.data = state.list.data.map((item) => (item.uuid === bundle.uuid ? bundle : item));
  },
  addPaymentPlanInCurrent(state, paymentPlan) {
    state.current.payment_schedules.push(paymentPlan);
  },
  deletePaymentPlanInCurrent(state, paymentPlan) {
    state.current.payment_schedules = state.current
      .payment_schedules
      .filter((v) => v.id !== paymentPlan.id);
  },
  setSearchParams(state, params) {
    state.searchParams = params;
  },
  setCount(state, count) {
    state.count = count;
  },
};

export const actions = {
  fetch({ state, commit }) {
    return APIBundles.getBundles(state.searchParams)
      .then((data) => {
        commit('setList', data);
        return data;
      });
  },
  count({ commit }) {
    return APIBundles.getBundlesCount()
      .then((data) => {
        commit('setCount', data);
        return data;
      });
  },
  find({ commit }, uuid) {
    return APIBundles.getBundle(uuid)
      .then((data) => {
        commit('setCurrent', data.data);
        return data.data;
      });
  },
  add({ commit }, bundle) {
    return APIBundles.createBundle(bundle)
      .then((data) => {
        commit('add', data.data);
        return data.data;
      });
  },
  update({ commit }, { uuid, isCurrent, bundle }) {
    return APIBundles.updateBundle(uuid, bundle)
      .then((data) => {
        if (isCurrent) {
          commit('setCurrent', data.data);
        } else {
          commit('updateSingle', data.data);
        }

        return data.data;
      });
  },
  updateSEO({ dispatch }, {
    uuid, seoData,
  }) {
    return APIBundles.update(uuid, {
      seo_data: seoData,
    })
      .then(() => dispatch('find', uuid));
  },
  delete({ dispatch }, bundle) {
    return APIBundles.deleteBundle(bundle)
      .then(() => dispatch('fetch'));
  },
  addPaymentPlan({ commit }, { uuid, isCurrent = true, paymentPlan }) {
    return APIBundles.addPaymentPlan(uuid, paymentPlan)
      .then(({ data }) => {
        if (isCurrent) {
          commit('addPaymentPlanInCurrent', data);
        }
      });
  },
  deletePaymentPlan({ commit }, { uuid, isCurrent = true, paymentPlan }) {
    return APIBundles.deletePaymentPlan(uuid, paymentPlan)
      .then(() => {
        if (isCurrent) {
          commit('deletePaymentPlanInCurrent', paymentPlan);
        }
      });
  },
  reorder({ commit }, bundles) {
    commit('setList', { data: bundles });

    return APIBundles.reorderBundles(bundles)
      .then((data) => data.data);
  },
};

export const getters = {
  count(state) {
    return state.count;
  },
};
