<script>
import APICMS from '@shared/services/API/CMS';

const cache = {};

export default {
  props: {
    tag: {
      type: String,
      default: 'a',
    },
    page: {
      type: String,
      required: true,
    },
    replaceContent: {
      type: Function,
      default: (val) => val,
    },
    hasClickDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    alert(page) {
      this.$buefy.dialog.alert({
        size: 'is-small',
        message: `<div class="content">${this.replaceContent(page.content.rendered)}</div>`,
        confirmText: 'Fermer',
      });
    },
    showPage() {
      this.$emit('open');

      const store = this.$store.state.store.data;

      if (store && store.terms_url && this.page === 'cgv-du-formateur') {
        window.open(store.terms_url, '_blank');
        return;
      }

      if (store && store.privacy_url && this.page === 'confidentialite-du-formateur') {
        window.open(store.privacy_url, '_blank');
        return;
      }

      if (cache[this.page]) {
        this.alert(cache[this.page]);
        return;
      }

      const loader = this.$buefy.loading.open();
      APICMS.getPageBySlug(this.page)
        .then(([page]) => {
          if (!page) {
            this.$buefy.dialog.alert(`
              Cette page n'existe plus sur notre site
            `);

            return;
          }

          cache[this.page] = page;
          this.alert(page);
        })
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <component :is="tag" @click.prevent="!hasClickDisabled && showPage()">
    <slot />
  </component>
</template>
