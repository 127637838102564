import Vue from 'vue';
import moment from 'moment';
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Datepicker,
  Datetimepicker,
  Dialog,
  Dropdown,
  Field,
  Icon,
  Input,
  Loading,
  Menu,
  Modal,
  Navbar,
  Notification,
  Numberinput,
  Pagination,
  Progress,
  Radio,
  Rate,
  Select,
  Skeleton,
  Switch,
  Table,
  Tag,
  Tabs,
  Taginput,
  Toast,
  Tooltip,
  Upload,

  ConfigProgrammatic,
} from 'buefy';

ConfigProgrammatic.setOptions({
  defaultDayNames: moment.weekdaysShort(),
  defaultMonthNames: moment.months(),
  defaultFirstDayOfWeek: 1,
  defaultIconPack: 'fas',
  defaultTooltipType: 'is-dark',
  defaultNoticeQueue: false,
  defaultToastDuration: 4000,
  defaultSnackbarDuration: 7500,
  defaultNotificationDuration: 7500,
  defaultNotificationPosition: 'is-top',
  defaultDialogConfirmText: 'Ok !',
  defaultDialogCancelText: 'Annuler',
});

Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(Datepicker);
Vue.use(Datetimepicker);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Navbar);
Vue.use(Notification);
Vue.use(Numberinput);
Vue.use(Pagination);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Rate);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Tabs);
Vue.use(Taginput);
Vue.use(Toast);
Vue.use(Tooltip);
Vue.use(Upload);
