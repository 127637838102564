<script>
import { state } from './store';

export default {
  data: () => ({
    state,
  }),
};
</script>

<template>
  <component :is="state.layout">
    <slot />
  </component>
</template>
