import { api } from '@shared/config/axios';

export default {
  getTrainings(params) {
    return api.$get('/trainings', { params });
  },
  getTrainingCount() {
    return api.$get('/trainings/count').then((data) => data.count);
  },
  getTraining(uuid) {
    return api.$get(`/trainings/${uuid}`);
  },
  addTraining(data) {
    return api.$post('/trainings', data);
  },
  updateTraining(uuid, data) {
    return api.$put(`/trainings/${uuid}`, data);
  },
  deleteTraining(uuid) {
    return api.$delete(`/trainings/${uuid}`);
  },
  getTrainingUploadUrl(uuid, { type, filename }) {
    return api.$get(`/trainings/${uuid}/urls/presigned`, {
      params: { type, filename },
    });
  },
  uploadFiletoTraining(uuid, { type, file }, onUploadProgress) {
    return this.getTrainingUploadUrl(uuid, { type, filename: file.name })
      .then(({ data }) => {
        const headers = { 'x-amz-acl': 'public-read' };
        return api.$put(data.url, file, { headers, onUploadProgress })
          .then(() => data.url.split('?')[0]);
      });
  },
  updateTrainingFeedbackOptions(uuid, data) {
    return api.$put(`/trainings/${uuid}/feedback_options`, data);
  },
  updateTrainingSEO(uuid, data) {
    return api.$put(`/trainings/${uuid}/seo`, data);
  },
  duplicateTraining(uuid) {
    return api.$post(`/trainings/${uuid}/duplications`);
  },
  reorderTrainings(data) {
    return api.$put('/trainings/reordering', data);
  },
  getGlobalStats(uuid) {
    return api.$get(`/trainings/${uuid}/stats/global`);
  },
  getStats(uuid, { begin, end }) {
    return api.$get(`/trainings/${uuid}/stats?begin=${begin}&end=${end}`);
  },
  getHistogramStats(uuid, { tz, begin, end }) {
    return api.$get(`/trainings/${uuid}/stats/histogram`, { params: { tz, begin, end } });
  },
  updateEmailMarketingLists(uuid, data) {
    return api.$put(`/trainings/${uuid}/emails/lists`, data);
  },
  addAuthors(uuid, authors) {
    return api.$post(`/trainings/${uuid}/authors`, { user_uuids: authors });
  },
  removeAuthors(uuid, authors) {
    return api.$post(`/trainings/${uuid}/authors`, {
      _method: 'DELETE',
      user_uuids: authors,
    });
  },
  getTrainingComments(uuidOrURL, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL);
    }
    return api.$get(`/trainings/${uuidOrURL}/comments`, { params });
  },
  addItem(uuid, data) {
    return api.$post(`/trainings/${uuid}/training_items`, data);
  },
  updateItem(uuid, itemId, data) {
    return api.$put(`/trainings/${uuid}/training_items/${itemId}`, data);
  },
  duplicateTrainingItem(uuid, itemId, targetUuid) {
    return api.$post(`/trainings/${uuid}/training_items/${itemId}/duplications`, {
      target_training_uuid: targetUuid,
    });
  },
  getTrainingItemComments(uuidOrURL, id, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL);
    }
    return api.$get(`/trainings/${uuidOrURL}/training_items/${id}/comments`, {
      params,
    });
  },
  addTrainingItemComment(slug, id, data) {
    return api.$post(`/trainings/${slug}/training_items/${id}/comments`, data);
  },
  deleteTrainingItemComment(slug, itemId, id) {
    return api.$delete(
      `/trainings/${slug}/training_items/${itemId}/comments/${id}`,
    );
  },
  updateItemQuiz(uuid, itemId, data) {
    return api.$put(`/trainings/${uuid}/training_items/${itemId}/quiz`, data);
  },
  getItemQuizAnswers(uuid, itemId) {
    return api.$get(`/trainings/${uuid}/training_items/${itemId}/quiz/answers`);
  },
  exportItemQuizAnswers(uuid, itemId, customerUuid) {
    const params = {};

    if (customerUuid) {
      params.customer_uuid = customerUuid;
    }

    return api.$get(`/trainings/${uuid}/training_items/${itemId}/quiz/answers/export`, { params });
  },
  deleteTrainingItem(uuid, itemId) {
    return api.$delete(`/trainings/${uuid}/training_items/${itemId}`);
  },
  reorderTrainingItems(uuid, data) {
    return api.$put(`/trainings/${uuid}/training_items/reordering`, data);
  },
  getTrainingItem(uuid, itemId) {
    return api.$get(`/trainings/${uuid}/training_items/${itemId}`);
  },
  getTrainingItemFeedbacks(uuidOrURL, itemId, params) {
    if (uuidOrURL.startsWith('http')) {
      return api.$get(uuidOrURL);
    }

    return api.$get(`/trainings/${uuidOrURL}/training_items/${itemId}/feedbacks`, {
      params,
    });
  },
  getItemAssignmentSubmissions(uuid, itemId, params) {
    return api.$get(`/trainings/${uuid}/training_items/${itemId}/assignments`, {
      params,
    });
  },
  getItemAssignmentSubmission(uuid, itemId, submissionId) {
    return api.$get(`/trainings/${uuid}/training_items/${itemId}/assignments/${submissionId}`);
  },
  validateItemAssignmentSubmission(uuid, itemId, submissionId, data) {
    return api.$put(`/trainings/${uuid}/training_items/${itemId}/assignments/${submissionId}`, data);
  },
  addItemAssignmentSubmissionComment(uuid, itemId, submissionId, data) {
    return api.$post(`/trainings/${uuid}/training_items/${itemId}/assignments/${submissionId}/comments`, data);
  },
  addTrainingResource(uuid, itemId, data) {
    return api.$post(`/trainings/${uuid}/training_items/${itemId}/resources`, data);
  },
  deleteTrainingResource(uuid, itemId, resourceId) {
    return api.$delete(`/trainings/${uuid}/training_items/${itemId}/resources/${resourceId}`);
  },
  reorderTrainingResource(uuid, itemId, data) {
    return api.$put(`/trainings/${uuid}/training_items/${itemId}/resources/reordering`, data);
  },
  addCustomer(uuid, data) {
    return api.$post(`/trainings/${uuid}/customers`, data);
  },
  importCustomers(uuid, data) {
    return api.$post(`/trainings/${uuid}/customers/import`, data);
  },
  updateCustomer(uuid, customerUUID, data) {
    return api.$put(`/trainings/${uuid}/customers/${customerUUID}`, data);
  },
  removeCustomer(uuid, customerUUID) {
    return api.$delete(`/trainings/${uuid}/customers/${customerUUID}`);
  },
  getCustomerAttendanceData(uuid, customerUUID) {
    return api.$get(`/trainings/${uuid}/customers/${customerUUID}/attendance`);
  },
  exportCustomerAttendance(uuid, customerUUID, data) {
    return api.$post(`/trainings/${uuid}/customers/${customerUUID}/attendance`, data);
  },
  exportCustomerTrainingDocument(uuid, customerUUID, data) {
    return api.$post(`/trainings/${uuid}/customers/${customerUUID}/certificate`, data);
  },
  exportQualiopi(uuid, customerUUID) {
    return api.$get(`/trainings/${uuid}/customers/${customerUUID}/qualiopi/export`);
  },
  getCustomerTransactions(uuid, customerUUID) {
    return api.$get(`/trainings/${uuid}/customers/${customerUUID}/transactions`);
  },
  cancelCustomerTransactions(uuid, customerUUID, transactionIds) {
    return api.$post(`/trainings/${uuid}/customers/${customerUUID}/transactions`, {
      _method: 'DELETE',
      ids: transactionIds,
    });
  },
  addPaymentPlan(uuid, data) {
    return api.$post(`/trainings/${uuid}/payment_schedules`, data);
  },
  deletePaymentPlan(uuid, data) {
    return api.$delete(`/trainings/${uuid}/payment_schedules/${data.id}`);
  },
};
