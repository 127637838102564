import moment from 'moment';
import 'moment/locale/fr';
import Vue from 'vue';
import VueMoment from 'vue-moment/vue-moment';

Vue.use(VueMoment, {
  moment,
});

Vue.filter('momentFromUTC', (value) => moment.utc(value).local());

export default moment;
