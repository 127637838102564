<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<template functional>
  <component
    :is="props.tag"
    class="columns"
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="column is-4 content pr-10 pr-3-mobile is-small">
      <h2 v-if="slots().title" class="is-size-5">
        <slot name="title" />
      </h2>
      <slot name="content" />
    </div>
    <div v-if="slots().form" class="column is-8">
      <slot name="form" />
    </div>
    <slot v-else />
  </component>
</template>
